import { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { Chip, Typography } from "@mui/material";
import {
    faCircle,
    faFolder,
    faLayerGroup,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import * as styles from "./WizenozeContentCard.module.scss";
import variables from "styles/_variables.module.scss";
import {
    SearchResultCollection,
    SearchResultCurriculumNode,
    SearchResultCurriculumQuery,
    SearchResultFolder,
} from "types";
import { capitaliseFirstLetter } from "utils/oldhelpers";

import ExternalImageWithFallback from "components/ExternalImageWithFallback/ExternalImageWithFallback";
import Translate from "components/Translate/Translate";

interface Props {
    result:
        | SearchResultCurriculumQuery
        | SearchResultCurriculumNode
        | SearchResultCollection
        | SearchResultFolder;
}

const WizenozeContentCard = ({ result }: Props) => {
    const navigate = useNavigate();
    const clickHandler = () => {
        if (isQueryType || isNodeType) {
            const combinedPath = result.path
                ?.map((el) => el.type.toLowerCase() + "/" + el.id)
                .join("/");
            navigate(`/${combinedPath}`);
        } else {
            const combinedPath = result.path?.map((el) => el.id).join("/");
            navigate(`/my-collections/${combinedPath}`);
        }
    };
    const isFolderType = result.resultType === "FOLDER";
    const isNodeType = result.resultType === "CURRICULUM_NODE";
    const isQueryType = result.resultType === "CURRICULUM_QUERY";
    return (
        <div className={styles.container}>
            <div className={styles.body}>
                <ExternalImageWithFallback
                    className={styles.bodyImage}
                    src={result.thumbnail}
                    alt={result?.title + " thumbnail"}
                    hideOnError
                />
                <div className={styles.bodyTexts}>
                    <div className={styles.bodyTitle}>
                        <FontAwesomeIcon
                            icon={
                                isFolderType || isNodeType
                                    ? faFolder
                                    : faLayerGroup
                            }
                            color={
                                variables["message-colors-color-message-info"]
                            }
                            fontSize={16}
                        />
                        <Typography
                            variant="text-size-Base-bold"
                            color="$text-colors-color-text-blue.main"
                            className={styles.title}
                            onClick={clickHandler}
                        >
                            {result.title}
                        </Typography>
                    </div>
                    <div className={styles.footer}>
                        <Chip
                            label={capitaliseFirstLetter(
                                result.resultType?.split("_")[0].toLowerCase(),
                            )}
                        />
                        {"workspaceType" in result && (
                            <Chip
                                label={capitaliseFirstLetter(
                                    result.workspaceType.toLowerCase(),
                                )}
                                sx={{
                                    backgroundColor:
                                        result.workspaceType === "PRIVATE"
                                            ? variables[
                                                  "text-colors-color-text-orange"
                                              ]
                                            : result.workspaceType ===
                                                "ORGANIZATION"
                                              ? variables[
                                                    "icon-colors-Blue-100"
                                                ]
                                              : variables[
                                                    "wizenoze-brand-color-wizenoze-green"
                                                ],
                                    borderColor:
                                        result.workspaceType === "PRIVATE"
                                            ? variables[
                                                  "text-colors-color-text-orange"
                                              ]
                                            : result.workspaceType ===
                                                "ORGANIZATION"
                                              ? variables[
                                                    "icon-colors-Blue-100"
                                                ]
                                              : variables[
                                                    "wizenoze-brand-color-wizenoze-green"
                                                ],
                                }}
                            />
                        )}
                        {"itemsCount" in result && (
                            <>
                                <FontAwesomeIcon
                                    icon={faCircle}
                                    className={styles.divider}
                                />
                                <Typography
                                    variant="text-size-xxSmall-medium"
                                    color="$icon-colors-green-60.main"
                                >
                                    {result.itemsCount}
                                    {result.itemsCount === 1 ? (
                                        <Translate text=" resource" />
                                    ) : (
                                        <Translate text=" resources" />
                                    )}
                                </Typography>
                            </>
                        )}
                        {"subfoldersCount" in result && (
                            <>
                                <FontAwesomeIcon
                                    icon={faCircle}
                                    className={styles.divider}
                                />
                                <Typography
                                    variant="text-size-xxSmall-medium"
                                    color="$icon-colors-green-60.main"
                                >
                                    {result.subfoldersCount}
                                    {result.subfoldersCount === 1 ? (
                                        <Translate text=" subfolder" />
                                    ) : (
                                        <Translate text=" subfolders" />
                                    )}
                                </Typography>
                                <FontAwesomeIcon
                                    icon={faCircle}
                                    className={styles.divider}
                                />
                                <Typography
                                    variant="text-size-xxSmall-medium"
                                    color="$icon-colors-green-60.main"
                                >
                                    {result.collectionsCount}
                                    {result.collectionsCount === 1 ? (
                                        <Translate text=" collection" />
                                    ) : (
                                        <Translate text=" collections" />
                                    )}
                                </Typography>
                            </>
                        )}
                        {(isNodeType || isQueryType) &&
                            "path" in result &&
                            result.path.slice(0, 3).map((node, index) => (
                                <Fragment key={node.id + index}>
                                    <FontAwesomeIcon
                                        icon={faCircle}
                                        className={styles.divider}
                                    />
                                    <Typography
                                        variant="text-size-xxSmall-medium"
                                        color="$icon-colors-green-60.main"
                                    >
                                        {node.title}
                                    </Typography>
                                </Fragment>
                            ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WizenozeContentCard;
