import { useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Popper, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";

import * as styles from "./FolderShareViewer.module.scss";
import { translate } from "components/Translate/Translate";
import { useAppContext } from "contexts/AppContext";
import { useViewItemsInCollectionQuery } from "hooks/api/collections";
import {
    useAllCachedShareFoldersQuery,
    useShareFolderQuery,
    useShareParams,
} from "hooks/api/useShareQuery";
import useScreenSizes from "hooks/useScreenSizes";
import { Collection, Folder, Workspace } from "types";
import { tracker } from "utils/analytics";

import CollectionViewer from "components/CollectionViewer/CollectionViewer";
import CollectionsExplorerPanel from "components/CollectionsExplorerPanel/CollectionsExplorerPanel";
import MobileCollectionsExplorer from "components/CollectionsExplorerPanel/MobileCollectionsExplorer";
import FolderViewer from "components/FolderOrWorkspaceViewer/FolderOrWorkspaceViewer";
import Loading from "components/Loading/Loading";
import FolderViewerNavBar from "components/NavBars/FolderViewerNavBar";
import TitleHeader from "components/TitleHeader/TitleHeader";
import Translate from "components/Translate/Translate";
import Header from "features/Header/Header";
import QuizAvailableFooter from "features/CollectionShareViewer/QuizAvailablePanel/QuizAvailableFooter";
import QuizTaker from "features/CollectionShareViewer/QuizTaker/QuizTaker";

const FolderShareViewer = () => {
    const { isDesktopScreen, isMobileScreen } = useScreenSizes();
    const navigate = useNavigate();
    const location = useLocation();
    const pathParams = location.pathname.split("/"); // First value is "" as string is "/embed/folder/etc"
    const shareParams = useShareParams();
    const selectedFolderParams = shareParams.at(-1);
    const {
        config: { language },
    } = useAppContext();
    const {
        data: selectedFolderData,
        isInitialLoading: isFetchingselectedFolderData,
    } = useShareFolderQuery(selectedFolderParams);
    const { data: collectionItems, isFetched: collectionItemsIsFetched } =
        useViewItemsInCollectionQuery(
            selectedFolderData?.type === "COLLECTION"
                ? selectedFolderParams
                : undefined,
        );

    const quizAvailableFooterRef = useRef<HTMLDivElement>(null);
    const quizAvailableFooterHeight = quizAvailableFooterRef.current
        ?.clientHeight
        ? quizAvailableFooterRef.current?.clientHeight
        : undefined;

    const [isQuizTakerOpen, setIsQuizTakerOpen] = useState(false);

    const embedMode = location.pathname?.includes("embed");
    const pageTitle = selectedFolderData
        ? selectedFolderData.title
        : translate(language, "My collections");

    const folderClickHandler = (folder: Folder, pathString?: string) => {
        if (!pathString) return;
        tracker("Folder clicked", folder);
        navigate(pathString);
    };
    const folderViewClickHandler = (folder: Folder | Workspace) => {
        // GridView or ListView can only go 'deeper' where as TreeExplorer can navigate to any folder. Hence 2 functions.
        folderClickHandler(
            folder,
            [location.pathname, folder.id, folder.hash].join("/"),
        );
    };
    const collectionClickHandler = (
        collection: Collection,
        pathString?: string,
    ) => {
        if (!pathString) return;
        tracker("Collection clicked", collection);
        navigate([pathString, collection.hash].join("/"));
    };
    const collectionViewClickHandler = (collection: Collection) => {
        collectionClickHandler(
            collection,
            [location.pathname, collection.id, collection.hash].join("/"),
        );
    };

    const { data: rootFolder, status: rootFolderStatus } =
        useAllCachedShareFoldersQuery();

    if (rootFolderStatus !== "success") return <Loading />;
    const homePath = [
        "/" + pathParams[1],
        pathParams[2],
        rootFolder.id,
        rootFolder.hash,
    ].join("/");

    const recursivelyJoinIDandHash = (
        elements: Array<Folder | Collection>,
    ): Array<Folder | Collection> => {
        return elements.map((el) => {
            if (el.type === "FOLDER") {
                return {
                    ...el,
                    pathStep: el.id + "/" + el.hash,
                    children: recursivelyJoinIDandHash(el.children),
                };
            }
            return el;
        });
    };
    const treeItems = recursivelyJoinIDandHash(rootFolder.children);
    const sortedSelectedFolderContents =
        selectedFolderData?.type === "FOLDER"
            ? selectedFolderData?.sortBy === "TITLE"
                ? selectedFolderData.children.sort((a, b) =>
                      a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1,
                  )
                : selectedFolderData?.children.sort((a, b) =>
                      a.lastModifiedDate < b.lastModifiedDate ? 1 : -1,
                  )
            : [];

    const quizData =
        selectedFolderData?.type === "COLLECTION"
            ? selectedFolderData?.quiz
            : undefined;

    const openQuiz = () => {
        tracker("Open Quiz Taker");
        setIsQuizTakerOpen(true);
    };
    const closeQuiz = () => {
        tracker("Close Quiz Taker");
        setIsQuizTakerOpen(false);
    };

    return (
        <>
            <Header collectionView={!embedMode} />
            <FolderViewerNavBar />
            {!isDesktopScreen && (
                <MobileCollectionsExplorer
                    items={treeItems}
                    panelTitle={rootFolder?.title}
                    folderClickHandler={folderClickHandler}
                    collectionClickHandler={collectionClickHandler}
                    pathRoot={homePath}
                    selectedIds={shareParams.map((el) => el.id)}
                    useOwnSubheaderContainer
                />
            )}
            <div
                style={{
                    padding: isMobileScreen ? "20px 10px" : "20px 40px",
                    marginBottom: 10,
                    // position: "relative",
                    height: "100%",
                    paddingBottom: quizAvailableFooterHeight,
                }}
            >
                <Grid container spacing={"20px"}>
                    {isDesktopScreen && (
                        <Grid lg={3} xs={0}>
                            <CollectionsExplorerPanel
                                items={treeItems}
                                panelTitle={rootFolder?.title}
                                folderClickHandler={folderClickHandler}
                                collectionClickHandler={collectionClickHandler}
                                pathRoot={homePath}
                                selectedIds={shareParams.map((el) => el.id)}
                            />
                        </Grid>
                    )}
                    <Grid lg={9} xs={12}>
                        {isFetchingselectedFolderData ? (
                            <Loading />
                        ) : selectedFolderData?.type === "FOLDER" ? (
                            <>
                                <div className={styles.title}>
                                    <Typography
                                        variant="text-size-Medium"
                                        color="$text-colors-color-text-dark.main"
                                    >
                                        {pageTitle}
                                    </Typography>
                                </div>
                                <FolderViewer
                                    items={sortedSelectedFolderContents}
                                    isFetched={!isFetchingselectedFolderData}
                                    viewAs={selectedFolderData.view ?? "GRID"}
                                    sortBy={
                                        selectedFolderData.sortBy ??
                                        "LAST_MODIFIED_DATE"
                                    }
                                    folderOrWorkspaceClickHandler={
                                        folderViewClickHandler
                                    }
                                    collectionClickHandler={
                                        collectionViewClickHandler
                                    }
                                />
                            </>
                        ) : selectedFolderData?.type === "COLLECTION" ? (
                            <>
                                <TitleHeader
                                    collectionName={selectedFolderData?.title}
                                />
                                {!collectionItemsIsFetched ? (
                                    <Loading />
                                ) : !collectionItems ||
                                  !collectionItems.length ? (
                                    <div
                                        className={
                                            styles.emptyCollectionMessage
                                        }
                                    >
                                        <Typography variant="text-size-Base">
                                            <Translate text="The collection is currently empty." />
                                        </Typography>
                                    </div>
                                ) : (
                                    <CollectionViewer
                                        items={collectionItems ?? []}
                                        isFetched={collectionItemsIsFetched}
                                        viewAs={
                                            selectedFolderData?.view ?? "GRID"
                                        }
                                        collectionResultsModalEnabled={
                                            !!selectedFolderData.resultModal
                                        }
                                    />
                                )}
                            </>
                        ) : (
                            <></>
                        )}
                    </Grid>
                </Grid>
                {quizData && (
                    <Popper
                        id="simple-popper"
                        open
                        placement="bottom"
                        anchorEl={document.body}
                        style={{ maxWidth: 1440, width: "100%" }}
                    >
                        <QuizTaker
                            isOpen={isQuizTakerOpen}
                            closeHandler={closeQuiz}
                            collectionId={selectedFolderData.id}
                            quizData={quizData}
                        />
                        <Grid container spacing={"20px"}>
                            <Grid lg={3} xs={0}></Grid>
                            <Grid lg={9} xs={12}>
                                <QuizAvailableFooter
                                    ref={quizAvailableFooterRef}
                                    quizName={quizData?.title}
                                    openHandler={openQuiz}
                                    styleOverrides={{
                                        position: "static",
                                        marginTop:
                                            -(quizAvailableFooterHeight ?? 20) -
                                            20,
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Popper>
                )}
            </div>
        </>
    );
};

export default FolderShareViewer;
