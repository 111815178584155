@use "../../../../../../styles/variables.module";

.buttonStyle {
    background-color: variables.$background-colors-color-background-blue;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 45px;
    display: flex;
    justify-content: space-between;
    place-items: center;
    width: 100%;
    height: 45px;
    cursor: pointer;
    align-self: center;
    justify-self: end;
}

.labelStyle {
    margin-right: 20px;
    cursor: pointer;
    font-size: 16px;
    line-height: 25px;
}
