import { useEffect } from "react";

import { suggestionsMenu } from "./SuggestionsList.module.scss";
import { AllSearchSuggestionTypes } from "types";

import SuggestionsListResultItem from "./components/SuggestionsListResultItem";
import SuggestionsListTermItem from "./components/SuggestionsListTermItem";

const SuggestionsList = ({
    suggestions,
    closeSearch,
}: {
    suggestions: Array<AllSearchSuggestionTypes>;
    closeSearch: () => void;
}) => {
    useEffect(() => {
        const handleArrowEvent = (
            e: KeyboardEvent,
            suggestions: Array<AllSearchSuggestionTypes>,
            // updateTermIndex,
            // suggestionSearch
        ) => {
            if (!suggestions.length) {
                return;
            }

            if (e.code === "ArrowDown" || e.code === "ArrowUp") {
                // updateTermIndex(e.code);
            }

            if (e.code === "Enter") {
                e.preventDefault();
                // suggestionSearch(e, searchSuggestionIndex);
            }
        };
        const handleArrowEventWrapper = (e: KeyboardEvent) =>
            handleArrowEvent(
                e,
                suggestions,
                // updateTermIndex,
                // suggestionSearch
            );
        window.addEventListener("keyup", handleArrowEventWrapper);

        return () => {
            window.removeEventListener("keyup", handleArrowEventWrapper);
        };
    }, [
        suggestions,
        // searchSuggestionIndex,
        // suggestionSearch,
        // updateTermIndex,
    ]);

    if (!suggestions?.length) return <></>;
    return (
        <div
            className={suggestionsMenu}
            style={{ maxHeight: window.innerHeight - 131 }}
            onClick={(e) => e.preventDefault()}
        >
            {suggestions.map((suggestion) =>
                suggestion.resultType === "SEARCH_TERM" ? (
                    <SuggestionsListTermItem
                        key={"term" + suggestion.title}
                        title={suggestion.title}
                        closeSearch={closeSearch}
                    />
                ) : (
                    <SuggestionsListResultItem
                        key={"result" + suggestion.id}
                        suggestion={suggestion}
                        closeSearch={closeSearch}
                    />
                ),
            )}
        </div>
    );
};

export default SuggestionsList;
