import { Chip, Typography } from "@mui/material";

import * as styles from "./LogoAndTitle.module.scss";
import useCurriculumQuery from "hooks/api/useCurriculumQuery";
import useCurriculumSelectedNodes from "hooks/useCurriculumSelectedNodes";
import wizenozePlaceholder from "images/wizenoze-placeholder.webp";
import { setBlankImage } from "utils/oldhelpers";

const LogoAndTitle = () => {
    const { data: curriculumData } = useCurriculumQuery();
    const selectedNodes = useCurriculumSelectedNodes();
    const currentNode = selectedNodes.pop() ?? curriculumData; // for 001 structure
    return (
        <div
            className={styles.header}
            style={{
                gap: currentNode?.logoUrl?.includes("topic") ? "0px" : "20px", // "Dirty" fix because wizenoze images already have margin in the .png
            }}
        >
            <img
                onError={setBlankImage}
                src={currentNode?.logoUrl || wizenozePlaceholder}
                className={styles.nodeLogo}
            />
            <div className={styles.titleAndMetadata}>
                <Typography variant="text-size-Medium">
                    {currentNode?.name}
                </Typography>
                <div className={styles.metadataTags}>
                    {selectedNodes.map((el) => (
                        <Chip key={el.name} label={el.name} />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default LogoAndTitle;
