import { Theme, useMediaQuery } from "@mui/material";

import { queryTitleStyle, topicTitleStyle } from "./HeaderTitle.module.scss";
import variables from "styles/_variables.module.scss";
import { QueryResults } from "types";
import { capitaliseFirstLetter } from "utils/oldhelpers";
interface Props {
    query: QueryResults;
    topicTitle: string;
}

const HeaderTitle = ({ query, topicTitle }: Props) => {
    const isMobileScreen = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down("md"),
    );

    if (!query) {
        return null;
    }

    return (
        <span
            style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                color: variables["text-colors-color-text-dark"],
                flexShrink: 1,
                width: "100%",
                marginLeft: isMobileScreen ? "0px" : "10px",
            }}
        >
            <div>
                <span className={topicTitleStyle}>{topicTitle}</span>
                <span className={queryTitleStyle}>
                    {capitaliseFirstLetter(query?.query?.name)}
                </span>
            </div>
        </span>
    );
};

export default HeaderTitle;
