import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, ClickAwayListener, Tooltip, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";

import * as styles from "./Subheader.module.scss";
import { useSelectedWorkspaceAndFoldersAndCollections } from "hooks/api/folders";
import useCollectionsParams from "hooks/useCollectionsParams";
import useScreenSizes from "hooks/useScreenSizes";
import variables from "styles/_variables.module.scss";

import MenuButton from "components/MenuButton/MenuButton";
import Translate from "components/Translate/Translate";
import DisabledClickhandler from "components/DisabledClickhandler/DisabledClickhandler";

interface Props {
    disabled: boolean;
}

const EditButton = ({ disabled }: Props) => {
    const { isMobileScreen } = useScreenSizes();
    const navigate = useNavigate();
    const collectionsParams = useCollectionsParams();
    const selectedWorkspaceAndFoldersAndCollections =
        useSelectedWorkspaceAndFoldersAndCollections();
    const selectedCollection =
        selectedWorkspaceAndFoldersAndCollections[
            selectedWorkspaceAndFoldersAndCollections.length - 1
        ];
    const [showTooltip, setShowTooltip] = useState(false);
    const clickHandler = () => {
        if (disabled) {
            setShowTooltip(true);
        } else {
            navigate(`/edit-collection/${selectedCollection.id}`, {
                state: {
                    collectionsParams: collectionsParams,
                },
            });
        }
    };
    return (
        <>
            {isMobileScreen ? (
                <Tooltip
                    title={
                        <ClickAwayListener
                            onClickAway={() => setShowTooltip(false)}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                }}
                            >
                                <Typography variant="text-size-xxSmall-regular">
                                    <Translate
                                        text={
                                            "You don’t have permission to edit this collection."
                                        }
                                    />
                                </Typography>
                            </div>
                        </ClickAwayListener>
                    }
                    placement="bottom"
                    arrow
                    open={showTooltip}
                >
                    <DisabledClickhandler
                        isDisabled={disabled}
                        clickHandler={clickHandler}
                    >
                        <MenuButton
                            faIcon={faPenToSquare}
                            iconColor={variables["icon-colors-blue-100"]}
                            primaryColor={variables["icon-colors-blue-40"]}
                            secondaryColor={variables["icon-colors-blue-60"]}
                            isOpen={false}
                            styleOverrides={{
                                width: "30px",
                                minWidth: "30px",
                                height: "30px",
                                fontSize: "12px",
                            }}
                            onClick={clickHandler}
                            isDisabled={disabled}
                        />
                    </DisabledClickhandler>
                </Tooltip>
            ) : (
                <Tooltip
                    title={
                        <ClickAwayListener
                            onClickAway={() => setShowTooltip(false)}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                }}
                            >
                                <Typography variant="text-size-xxSmall-regular">
                                    <Translate text="You don’t have permission to edit this collection." />
                                </Typography>
                            </div>
                        </ClickAwayListener>
                    }
                    placement="bottom"
                    arrow
                    open={showTooltip}
                >
                    <div>
                        <DisabledClickhandler
                            isDisabled={disabled}
                            clickHandler={clickHandler}
                        >
                            <Button
                                variant={
                                    disabled ? "disabled-button" : "contained"
                                }
                                className={styles.editButton}
                                onClick={clickHandler}
                                disabled={disabled}
                            >
                                <FontAwesomeIcon
                                    icon={faPenToSquare}
                                    fontSize={12}
                                />
                                <Typography variant="text-size-xSmall-bold">
                                    <Translate text="Edit" />
                                </Typography>
                            </Button>
                        </DisabledClickhandler>
                    </div>
                </Tooltip>
            )}
        </>
    );
};

export default EditButton;
