import { cardImageWrapper } from "../TopicCard.module.scss";
import wizenozePlaceholder from "images/wizenoze-placeholder.webp";
import { setBlankImage } from "utils/oldhelpers";

interface CardImageProps {
    cardImage?: string;
    cardHeading: string;
    subjectTitle?: string;
}

const CardImage = ({
    cardImage,
    cardHeading,
    subjectTitle,
}: CardImageProps) => (
    <div
        className={cardImageWrapper}
        data-cardbackground={subjectTitle?.toLowerCase()}
    >
        <img
            onError={setBlankImage}
            src={cardImage || wizenozePlaceholder}
            alt={cardHeading}
            aria-hidden="true"
            style={{
                objectFit: "contain",
                width: "100%",
                height: "100%",
            }}
        />
    </div>
);

export default CardImage;
