import { Button, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";

import * as styles from "./Subheader.module.scss";
import useScreenSizes from "hooks/useScreenSizes";
import { tracker } from "utils/analytics";

import ContextMenu from "components/ContextMenu/ContextMenu";
import Translate from "components/Translate/Translate";
import { Folder } from "types";

const SortByContextMenu = ({
    sortBy,
    updateSortBy,
    isVisible,
    setIsVisible,
    disabled,
}: {
    sortBy: Folder["sortBy"];
    updateSortBy: (sortBy: Folder["sortBy"]) => void;
    isVisible: boolean;
    setIsVisible: (open: boolean) => void;
    disabled?: boolean;
}) => {
    const { isMobileScreen } = useScreenSizes();
    const sortByLabel =
        sortBy === "LAST_MODIFIED_DATE" ? "date" : sortBy.toLowerCase();
    return (
        <ContextMenu
            options={[
                {
                    label: "Date",
                    clickHandler: () => {
                        tracker("Sort by date clicked");
                        updateSortBy("LAST_MODIFIED_DATE");
                    },
                },
                {
                    label: "Type",
                    clickHandler: () => {
                        tracker("Sort by type clicked");
                        updateSortBy("TYPE");
                    },
                },
                {
                    label: "Title",
                    clickHandler: () => {
                        tracker("Sort by title clicked");
                        updateSortBy("TITLE");
                    },
                },
            ]}
            onClose={() => setIsVisible(false)}
            disabled={disabled}
            styleOverrides={{ width: isMobileScreen ? "100%" : undefined }}
        >
            <Button
                variant={isMobileScreen ? "outlined" : "text"}
                className={styles.mobileButton}
                onClick={() => setIsVisible(true)}
            >
                <Typography variant="text-size-xSmall-bold">
                    <Translate text={`Sort by ${sortByLabel}`} />
                </Typography>
                <FontAwesomeIcon
                    icon={isVisible ? faChevronUp : faChevronDown}
                    fontSize={12}
                />
            </Button>
        </ContextMenu>
    );
};

export default SortByContextMenu;
