import {
    createContext,
    Dispatch,
    ReactNode,
    SetStateAction,
    useContext,
    useState,
} from "react";
import { useSearchParams } from "react-router-dom";

import { useAppContext } from "./AppContext";
import { useAllFoldersEnhancedQuery } from "hooks/api/folders";
import { ReadingLevel, ResultType } from "types";

type SearchContextType = {
    queryTerm: string;
    embeddable: boolean;
    setEmbeddable: Dispatch<SetStateAction<boolean>>;
    readingLevels: Set<ReadingLevel>;
    setReadingLevels: Dispatch<SetStateAction<Set<ReadingLevel>>>;
    resultTypes: Set<ResultType>;
    setResultTypes: Dispatch<SetStateAction<Set<ResultType>>>;
    workspaces: Set<number>;
    setWorkspaces: Dispatch<SetStateAction<Set<number>>>;
    subjects: Set<string>;
    setSubjects: Dispatch<SetStateAction<Set<string>>>;
    inDialogBox: boolean;
} | null;

const SearchContext = createContext<SearchContextType>(null);

export const useSearchContext = () => {
    const context = useContext(SearchContext);
    if (!context) {
        // throw new Error(
        //     "useSearchContext must be used within a SearchContextProvider",
        // );
        return { inDialogBox: false }; // This is to support SearchInput use in the header. A higher level implementation of SearchContext might be a better solution though.
    }
    return context;
};
export const SearchContextProvider = ({
    inDialogBox,
    children,
}: {
    inDialogBox?: boolean;
    children: ReactNode;
}) => {
    const [searchParams] = useSearchParams();
    const queryTerm = searchParams.get("searchTerm") ?? "educate";
    type NonNulledContextType = NonNullable<SearchContextType>; // Allows type lookups
    const { config } = useAppContext();
    const [embeddable, setEmbeddable] = useState<
        NonNulledContextType["embeddable"]
    >(config.embeddable);
    const [readingLevels, setReadingLevels] = useState<
        NonNulledContextType["readingLevels"]
    >(new Set([1, 2, 3, 4, 5]));
    const [resultTypes, setResultTypes] = useState<
        NonNulledContextType["resultTypes"]
    >(
        new Set(
            inDialogBox
                ? ["TEXT", "VIDEO"]
                : ["TEXT", "VIDEO", "COLLECTION", "FOLDER"],
        ),
    );
    const { data: allWorkspaces } = useAllFoldersEnhancedQuery();
    const [workspaces, setWorkspaces] = useState<Set<number>>(
        new Set(allWorkspaces.map((workspace) => workspace.id)).add(-1), // NOTE: This -1 is a hack for pre-migration Wizenoze workspace control, so curriculum queries and nodes can be filtered
    );
    const [subjects, setSubjects] = useState<NonNulledContextType["subjects"]>(
        new Set(),
    );
    const contextValue = {
        queryTerm,
        embeddable,
        setEmbeddable,
        readingLevels,
        setReadingLevels,
        resultTypes,
        setResultTypes,
        workspaces,
        setWorkspaces,
        subjects,
        setSubjects,
        inDialogBox: !!inDialogBox,
    };

    return (
        <SearchContext.Provider value={contextValue}>
            {children}
        </SearchContext.Provider>
    );
};

export default SearchContext;
