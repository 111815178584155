@use "../../../../../../styles/variables.module";

.resultsFooter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 40px;
    overflow: hidden;
    box-sizing: border-box;
}

.resultLogo {
    margin-right: 16px;
    padding: 2px;
    border-radius: 8px;
    background-color: variables.$background-colors-color-background-light;
    border: 1px solid #dee2e6;
    min-width: 28px;
    max-width: 100%;
    display: flex;
    align-items: center;
    overflow: hidden;
}

.resultLink {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: variables.$text-colors-color-text-blue;
    font-size: 14px;
    line-height: 20px;
    cursor: pointer;
}

.sourceLogoImg {
    height: 24px;
    width: auto;
    margin: 0;
    padding: 0;
}
