import { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import {
    faMagnifyingGlass,
    faCircleXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
    desktopContainer,
    searchForm,
    searchButton,
    baseContainer,
} from "./SearchInput.module.scss";
import variables from "styles/_variables.module.scss";
import { translate } from "components/Translate/Translate";
import { useAppContext } from "contexts/AppContext";
import { tracker } from "utils/analytics";

import Suggestions from "./Suggestions/Suggestions";

const SearchInput = ({
    setSearchTerm,
    closeSearch,
}: {
    setSearchTerm: (arg0: string) => void;
    closeSearch?: () => void;
}) => {
    const {
        config: { language },
    } = useAppContext();
    const [searchParams] = useSearchParams();
    const urlSearchTerm = searchParams.get("searchTerm");
    const [displayTerm, setDisplayTerm] = useState(urlSearchTerm ?? "");
    const [showSuggestions, setShowSuggestions] = useState(false);
    const inputRef = useRef<HTMLInputElement>(null);

    const handleChange = (e: React.KeyboardEvent) => {
        e.preventDefault();
        e.code !== "Enter"
            ? setShowSuggestions(true)
            : setShowSuggestions(false);
    };

    const changeHandler = (newDisplayTerm: string) => {
        setDisplayTerm(newDisplayTerm);
    };

    const submitHandler = (searchTerm: string) => {
        if (!searchTerm) return;
        setDisplayTerm(searchTerm);
        setShowSuggestions(false);
        setSearchTerm(searchTerm);
        setPrevSearchTerm(searchTerm);
        tracker("Collection Editor Search", {
            searchTerm: searchTerm,
        });
    };

    const resetTerm = (e?: React.FormEvent) => {
        e?.preventDefault();
        setDisplayTerm("");
    };
    const [prevSearchTerm, setPrevSearchTerm] = useState(urlSearchTerm);
    useEffect(() => {
        if (urlSearchTerm && urlSearchTerm !== prevSearchTerm) {
            setDisplayTerm(urlSearchTerm);
            setPrevSearchTerm(urlSearchTerm);
        }
    }, [displayTerm, prevSearchTerm, setSearchTerm, urlSearchTerm]);

    return (
        <>
            <div
                data-testid="baseContainer"
                className={baseContainer}
                style={{ width: "100%" }}
            >
                <div
                    data-testid="collection-search-input"
                    className={desktopContainer}
                >
                    <form
                        className={searchForm}
                        style={{ zIndex: "unset" }}
                        onSubmit={(e) => {
                            e.preventDefault();
                            submitHandler(displayTerm);
                        }}
                    >
                        <input
                            data-testid="search-input"
                            ref={inputRef}
                            name="search"
                            autoComplete="off"
                            onKeyUp={handleChange}
                            placeholder={translate(language, "Search")}
                            onChange={(e) => changeHandler(e.target.value)}
                            autoFocus
                            value={displayTerm}
                            // onBlur={closeSuggestions} // TODO because it was blocking suggestions being clicked
                            style={{ flexGrow: 1 }}
                        />
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row-reverse", // We row-reverse to make the enter key trigger the 'first' button, but display the search button last
                                alignItems: "center",
                            }}
                        >
                            <button
                                className={searchButton}
                                aria-label={translate(language, "Search icon")}
                                type="submit"
                                onClick={(e) => {
                                    e.preventDefault();
                                    submitHandler(displayTerm);
                                }}
                            >
                                <FontAwesomeIcon icon={faMagnifyingGlass} />
                            </button>
                            {displayTerm != null && displayTerm.length > 0 && (
                                <>
                                    <div
                                        style={{
                                            height: "20px",
                                            borderRight: `2px solid ${
                                                variables[
                                                    "border-colors-color-border-light"
                                                ]
                                            }`,
                                        }}
                                    ></div>
                                    <button
                                        className={searchButton}
                                        style={{
                                            color: variables[
                                                "text-colors-color-text-gray"
                                            ],
                                            width: 40,
                                        }}
                                        onMouseDown={(e) => e.preventDefault()}
                                        onClick={resetTerm}
                                    >
                                        <FontAwesomeIcon icon={faCircleXmark} />
                                    </button>
                                </>
                            )}
                        </div>
                    </form>
                    {showSuggestions && (
                        <Suggestions
                            searchTerm={displayTerm}
                            closeSearch={() => {
                                resetTerm();
                                setShowSuggestions(false);
                                closeSearch && closeSearch();
                            }}
                        />
                    )}
                </div>
            </div>
            {showSuggestions && (
                <div
                    style={{
                        position: "absolute",
                        top: 0,
                        bottom: 0,
                        left: 0,
                        right: 0,
                    }}
                    onClick={() => setShowSuggestions(false)}
                ></div>
            )}
        </>
    );
};

export default SearchInput;
