import { Collection, Curriculum, Folder, Workspace } from "types";

export const checkLocalStoragePermission = () => {
    let localStoragePermission = true;
    try {
        const testKey = "__testKey__";
        localStorage.setItem(testKey, testKey);
        localStorage.removeItem(testKey);
    } catch (e) {
        localStoragePermission = false;
    }
    return localStoragePermission;
};

export const isGradeOnlyStructure = (structure: Curriculum["structure"]) =>
    JSON.stringify(structure) === '["GRADE","TOPIC","QUERY"]';
export const isUnitOnlyStructure = (structure: Curriculum["structure"]) =>
    JSON.stringify(structure) === '["UNIT","TOPIC","QUERY"]';
export const isGradeAndUnitOnlyStructure = (
    structure: Curriculum["structure"],
) => JSON.stringify(structure) === '["GRADE","UNIT","TOPIC","QUERY"]';

export const isCurriculumOnlyStructure = (structure: Curriculum["structure"]) =>
    JSON.stringify(structure) === '["TOPIC","QUERY"]';

export type AppMode = "app" | "share" | "lti";

export const getAppMode = (): AppMode => {
    const appMode = process.env.WIZENOZE_ENV as AppMode;
    if (appMode !== "app" && appMode !== "share" && appMode !== "lti") {
        throw new Error("Invalid app mode");
    }
    return appMode;
};

export const isAppMode = getAppMode() === "app";
export const isShareMode = getAppMode() === "share";
export const isEmbedMode =
    getAppMode() === "share" && window.location.pathname.includes("/embed/");
export const isLTIMode = getAppMode() === "lti";

export const updateArrayItem = <T>(array: T[], value: T, index: number) => {
    return [...array.slice(0, index), value, ...array.slice(index + 1)];
};

export const findPathForID = (arr: Workspace[], targetId: number) => {
    // Helper function to perform the recursive search
    const search = (
        array: (Workspace | Folder | Collection)[],
        targetId: number,
        path: string,
    ): string | null => {
        for (const node of array) {
            // Add the current node's id to the path
            const currentPath = path ? `${path}/${node.id}` : `${node.id}`;
            // Check if the current node is the target
            if (node.id === targetId) {
                return currentPath;
            }
            // If the node has children, search recursively
            if (
                (node.type === "WORKSPACE" || node.type === "FOLDER") &&
                node.children &&
                node.children.length > 0
            ) {
                const result = search(node.children, targetId, currentPath);
                if (result) {
                    return result;
                }
            }
        }
        // If not found, return null
        return null;
    };
    // Start the search from the root array
    return search(arr, targetId, "");
};

export const objectsAreEqual = (
    obj1: Record<string, unknown>,
    obj2: Record<string, unknown>,
    keysToIgnore?: string[],
): boolean => {
    // Check if both objects are null or undefined
    if (
        obj1 === null ||
        obj1 === undefined ||
        obj2 === null ||
        obj2 === undefined
    ) {
        return obj1 === obj2;
    }

    // Check if both objects have the same keys, excluding 'quiz'
    const keys1 = Object.keys(obj1).filter(
        (key) => !keysToIgnore?.includes(key),
    );
    const keys2 = Object.keys(obj2).filter(
        (key) => !keysToIgnore?.includes(key),
    );
    if (keys1.length !== keys2.length) {
        return false;
    }

    // Compare the values of each key, excluding 'quiz'
    for (const key of keys1) {
        if (obj1[key] !== obj2[key]) {
            return false;
        }
    }

    // If all keys and values match, the objects are equal (ignoring 'quiz')
    return true;
};
