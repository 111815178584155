import { useContext } from "react";

import { CurriculumContext } from "features/CurriculumNavigator/state/CurriculumContext";
import { OldResult } from "types";

import ResultCard from "components/ResultCard/ResultCard";
import { convertOldResultToCollectionResourceResult } from "utils/typeConvertors";

interface Props {
    result: OldResult;
    results?: OldResult[];
}

const QueryViewResultCard = ({ result, results }: Props) => {
    const { selectedResultsArray, setSelectedResultsArray } =
        useContext(CurriculumContext);
    const isSelectedResult = selectedResultsArray.includes(result.id);
    const selectResultHandler = (resultId: string) => {
        if (selectedResultsArray.includes(resultId)) {
            setSelectedResultsArray(
                selectedResultsArray.filter((result) => result !== resultId),
            );
        } else {
            setSelectedResultsArray([...selectedResultsArray, resultId]);
        }
    };
    if (!results || !result) return <></>;
    const newResults = results.map((el) =>
        convertOldResultToCollectionResourceResult(el),
    );
    const newResult = newResults.find((el) => el.id === result.id);
    if (!newResult) return <></>;

    return (
        <ResultCard
            result={newResult}
            results={newResults}
            resultIndex={results?.indexOf(result)}
            isSelectedResult={isSelectedResult}
            selectResultHandler={selectResultHandler}
        />
    );
};

export default QueryViewResultCard;
