import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsis } from "@fortawesome/free-solid-svg-icons";

import useScreenSizes from "hooks/useScreenSizes";
import variables from "styles/_variables.module.scss";

import ContextMenu from "components/ContextMenu/ContextMenu";

const ThreeDotContextMenu = ({
    options,
    isHovered,
}: {
    options: React.ComponentProps<typeof ContextMenu>["options"];
    isHovered: boolean;
}) => {
    const { isDesktopScreen } = useScreenSizes();
    if (!options.some((option) => !option.hidden)) return null;
    return (
        <ContextMenu options={options}>
            <FontAwesomeIcon
                icon={faEllipsis}
                style={{
                    color:
                        !isDesktopScreen || isHovered
                            ? variables["text-colors-color-text-light-gray"]
                            : "transparent",
                    transition: "0.25s",
                }}
            />
        </ContextMenu>
    );
};

export default ThreeDotContextMenu;
