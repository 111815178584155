import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { faMortarBoard, faFolderOpen } from "@fortawesome/free-solid-svg-icons";
import {
    Autocomplete,
    FormControl,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    TextField,
    Theme,
    useMediaQuery,
} from "@mui/material";

import { useAppContext } from "contexts/AppContext";
import useCurriculumQuery, {
    useCurriculumLayersQuery,
    useCurriculumsQuery,
} from "hooks/api/useCurriculumQuery";
import { useUserCurrentLayerMutation } from "hooks/api/useUserSettingsQuery";
import useCurriculumParams from "hooks/useCurriculumParams";
import useCurriculumSelectedNodes from "hooks/useCurriculumSelectedNodes";
import { Subject } from "types";
import variables from "styles/_variables.module.scss";

import TreeExplorer from "./TreeExplorerQueries/TreeExplorerQueries";
import { PanelSection, PanelV2 } from "components/Panel/Panel";
import Translate from "components/Translate/Translate";

const CurriculumPanel = () => {
    const { setLibraryCurriculumId } = useAppContext();
    const navigate = useNavigate();
    const location = useLocation();
    const { curriculumId, gradeId, subjectId } = useCurriculumParams();
    const { data: curriculaData } = useCurriculumsQuery();
    const { data: curriculumData } = useCurriculumQuery();
    const { data: layerData } = useCurriculumLayersQuery(curriculumId, "ALL");
    const userCurrentLayerMutation = useUserCurrentLayerMutation();
    const selectedCurriculumNodes = useCurriculumSelectedNodes();
    const gradeLayerIndex = curriculumData?.structure.findIndex(
        (el) => el === "GRADE",
    );
    const gradeData = gradeLayerIndex !== -1 ? layerData : undefined;
    const subjectLayerIndex = curriculumData?.structure.findIndex(
        (el) => el === "SUBJECT",
    );

    const subjectData =
        subjectLayerIndex !== -1
            ? subjectLayerIndex === 0
                ? layerData
                : gradeId &&
                    curriculumData?.structure &&
                    curriculumData?.structure?.length > 3
                  ? selectedCurriculumNodes[0]?.children
                  : undefined
            : undefined;

    let treeData;
    if (subjectData) {
        if (subjectId)
            treeData = subjectData.find((el: Subject) => el.id === subjectId);
    } else if (gradeData) {
        if (gradeId) treeData = gradeData.find((el) => el.id === gradeId);
    } else if (layerData) {
        treeData = { name: curriculumData?.name, children: layerData };
    }

    const isDesktopScreen = useMediaQuery((theme: Theme) =>
        theme.breakpoints.up("lg"),
    );

    useEffect(() => {
        if (
            curriculumData?.structure.includes("GRADE") &&
            !gradeId &&
            layerData?.length
        ) {
            const newGradeId = layerData[0].id.toString();
            if (!newGradeId) return;
            userCurrentLayerMutation.mutate({
                curriculumId: curriculumId?.toString() as string,
                gradeId: newGradeId,
            });
            navigate(`./grade/${newGradeId}`, {
                relative: "path",
                replace: true,
            });
        }
    }, [
        curriculumData?.structure,
        curriculumId,
        gradeId,
        layerData,
        navigate,
        userCurrentLayerMutation,
    ]);
    return (
        <PanelV2
            styleOverrides={{
                // height: selectedSubject ? "100%" : "unset",
                flex: 1,
                borderRadius: isDesktopScreen ? undefined : "0px !important",
                overflowY: "auto",
            }}
        >
            <PanelSection
                title="Curriculum"
                icon={faMortarBoard}
                colorOverride={variables["text-colors-color-text-gray"]}
                triggerCollapse={!!treeData}
                isCollapsible={!!treeData}
            >
                <div
                    style={{
                        marginTop: 10,
                        display: "flex",
                        flexDirection: "column",
                        gap: 20,
                        position: "relative",
                    }}
                >
                    {curriculaData && (
                        <Autocomplete
                            options={curriculaData.sort((a, b) =>
                                a.name > b.name ? 1 : -1,
                            )}
                            getOptionLabel={(option) => option.name}
                            defaultValue={curriculumData}
                            disableClearable={true}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={<Translate text="Curriculum" />}
                                />
                            )}
                            onChange={(e, newValue) => {
                                if (newValue === null) return;
                                userCurrentLayerMutation.mutate({
                                    curriculumId: newValue.id.toString(),
                                });
                                if (
                                    window.location.pathname.includes("library")
                                ) {
                                    setLibraryCurriculumId(newValue.id);
                                }
                                navigate(
                                    `${
                                        location.pathname.split(
                                            "/curriculum/",
                                        )[0]
                                    }/curriculum/${newValue.id}`,
                                );
                            }}
                        />
                    )}
                    {gradeData && (
                        <FormControl>
                            <InputLabel id="select-subject-label">
                                <Translate text="Grade" />
                            </InputLabel>
                            <Select
                                value={gradeId ?? ""}
                                onChange={(e) => {
                                    userCurrentLayerMutation.mutate({
                                        curriculumId:
                                            curriculumId?.toString() as string,
                                        gradeId: e.target.value.toString(),
                                    });
                                    navigate(
                                        `${
                                            location.pathname.split(
                                                "/grade/",
                                            )[0]
                                        }/grade/${e.target.value}`,
                                    );
                                }}
                                input={
                                    <OutlinedInput
                                        label={<Translate text="Grade" />}
                                    />
                                }
                                MenuProps={{
                                    style: { zIndex: 35001 },
                                }}
                            >
                                {gradeData?.map((el) => (
                                    <MenuItem key={el.id} value={el.id}>
                                        {el.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    )}
                    {subjectData && (
                        <FormControl>
                            <InputLabel id="select-subject-label">
                                <Translate text="Subject" />
                            </InputLabel>
                            <Select
                                labelId="select-subject-label"
                                value={subjectId ?? ""}
                                onChange={(e) => {
                                    navigate(
                                        `${
                                            location.pathname.split(
                                                "/subject/",
                                            )[0]
                                        }/subject/${e.target.value}`,
                                    );
                                }}
                                input={
                                    <OutlinedInput
                                        label={<Translate text="Subject" />}
                                    />
                                }
                                MenuProps={{
                                    style: { zIndex: 35001 },
                                }}
                            >
                                {subjectData?.map((el: Subject) => (
                                    <MenuItem key={el.id} value={el.id}>
                                        {el.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    )}
                </div>
            </PanelSection>
            {treeData && (
                <PanelSection
                    title={treeData?.name}
                    icon={faFolderOpen}
                    colorOverride={variables["text-colors-color-text-gray"]}
                >
                    <TreeExplorer data={treeData} />
                </PanelSection>
            )}
        </PanelV2>
    );
};

export default CurriculumPanel;
