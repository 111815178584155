import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Typography } from "@mui/material";

import * as styles from "./QueryCard.module.scss";
import { CurriculumContext } from "features/CurriculumNavigator/state/CurriculumContext";
import wizenozePlaceholder from "images/wizenoze-placeholder.webp";
import { Query } from "types";
import { tracker } from "utils/analytics";
import { setBlankImage } from "utils/oldhelpers";

interface Props {
    query: Query;
    subjectName: string;
}

const QueryCard = ({ query, subjectName }: Props) => {
    const navigate = useNavigate();
    const { setEmbedUrl, setLinkUrl } = useContext(CurriculumContext);
    const clickHandler = () => {
        tracker("Query clicked", query);
        setEmbedUrl("");
        setLinkUrl("");
        navigate("./query/" + query.id, { relative: "path" });
    };
    return (
        <div className={styles.container} onClick={clickHandler}>
            <div
                className={styles.image}
                data-cardbackground={subjectName?.toLowerCase()}
            >
                <img
                    onError={setBlankImage}
                    src={query.logoUrl || wizenozePlaceholder}
                    alt={query.name}
                    aria-hidden="true"
                    style={{
                        objectFit: "contain",
                        width: "100%",
                        height: "100%",
                    }}
                />
            </div>
            <Typography
                variant="text-size-Base-bold"
                color="$text-colors-color-text-dark.main"
                className={styles.name}
            >
                {query.name}
            </Typography>
        </div>
    );
};

export default QueryCard;
