@use "../../../../styles/variables.module";

.baseContainer {
    color: variables.$text-colors-color-text-white;
    display: flex;
    justify-content: flex-end;
}

.openContainer {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    padding: 0px 10px;
    background: linear-gradient(#0000 0 71px, #343a4099 71px 100%);
    z-index: variables.$zindex-search-input-mobile;
}

.openList {
    position: relative;
    top: 81px;
    width: 100%;
    max-width: 971px;
    background-color: variables.$background-colors-color-background-white;
    // border: 1px solid variables.$border-colors-color-border-primary;
    border-radius: 20px;
    overflow: hidden;
}

.desktopContainer {
    width: 100%;
    box-sizing: border-box;
    position: relative;
    /* flex: 0 1 320px; */
    background-color: variables.$background-colors-color-background-white;
    border: 1px solid variables.$border-colors-color-border-primary;
    border-radius: 20px;
    overflow: hidden;
    z-index: variables.$zindex-search-input-desktop;
}

.desktopContainer[data-adjust-to="searching"] {
    margin-right: 0;
}

.searchForm {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 38px;
    z-index: variables.$zindex-search-input-form;
}

.searchForm input {
    flex: 1 1 100%;
    border: none;
    padding: 10px 20px;
    outline: none;
    border-radius: 20px;
    background-color: white;
}

.searchButton {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: -1px;
    width: 40px;
    min-width: 40px;
    height: 40px;
    color: variables.$text-colors-color-text-gray;
    font-size: 15px;
    border: none;
    background: none;
    cursor: pointer;
}
