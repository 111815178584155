@use "../../../../../../styles/variables.module";

.suggestionsMenu {
    color: #323335;
    overflow-y: auto;
    z-index: variables.$zindex-search-autocomplete;
    position: relative;
    transform: translateZ(0);
    border-top: 1px solid variables.$border-colors-color-border-primary;
}

.listItem {
    width: 100%;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    gap: 10px;
    border-top: 1px solid variables.$border-colors-color-border-primary;
}

.listItem:first-child {
    border-top: unset;
}

.listItem:hover {
    background: rgba(variables.$background-colors-color-background-blue, 0.1);
}

.listItemImage {
    max-width: 55px;
    max-height: 55px;
}

.listItemData {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.listItemLogoAndTitle {
    display: flex;
    align-items: center;
    gap: 10px;
}

.title {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    word-break: break-all;
    overflow: hidden;
}

.listItemPath {
    display: flex;
    align-items: center;
    gap: 5px;
    flex-wrap: wrap;
}
