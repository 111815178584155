import { CSSProperties } from "react";

import * as styles from "./ResultCardBody.module.scss";
import variables from "styles/_variables.module.scss";
import { ResourceResultBase } from "types";

import ResultCardTitle from "./ResultCardTitle/ResultCardTitle";

const snippetTextStyle: CSSProperties = {
    color: variables["text-colors-color-text-gray"],
    fontSize: "16px",
    lineHeight: "26px",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 5,
    overflow: "hidden",
    padding: 0,
    margin: 0,
};

interface Props {
    results: ResourceResultBase[];
    resultIndex: number;
    stripSummaryText?: boolean;
}

const ResultCardBody = ({ results, resultIndex }: Props) => {
    const result = results[resultIndex];
    if (!result) return <></>;
    const sanitisedText = result.description?.replace(/(<([^>]+)>)/gi, "");
    return (
        <div className={styles.queryCardBodyContent}>
            <ResultCardTitle results={results} resultIndex={resultIndex} />
            <div
                style={snippetTextStyle}
                dangerouslySetInnerHTML={{
                    __html: sanitisedText,
                }}
            ></div>
        </div>
    );
};
export default ResultCardBody;
