import { AxiosError } from "axios";
import { useQuery } from "@tanstack/react-query";

import { OldResult } from "types";

import { axiosAPI } from "./axiosAPI";

export const resourceURL = `/resource`;
const useResourcesQuery = (type?: string, ids?: string[], hash?: string) => {
    return useQuery<OldResult[], AxiosError>(
        ["resource", ids],
        async () => {
            const { data } = await axiosAPI
                .getInstance()
                .post(resourceURL, { type, ids });
            return data;
        },
        {
            enabled: !!(ids && ids.length > 0) && !hash,
        },
    );
};

export default useResourcesQuery;
