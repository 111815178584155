import { useNavigate } from "react-router-dom";
import { Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";

import * as styles from "./MyCollections.module.scss";
import { useAppContext } from "contexts/AppContext";
import { translate } from "components/Translate/Translate";
import {
    useCollectionQuery,
    useItemsInCollectionQuery,
} from "hooks/api/collections";
import {
    useAllFoldersEnhancedQuery,
    useEditFolderMutation,
    useSelectedWorkspaceAndFoldersAndCollections,
} from "hooks/api/folders";
import { useEditWorkspaceMutation } from "hooks/api/workspace";
import useCollectionsParams from "hooks/useCollectionsParams";
import useScreenSizes from "hooks/useScreenSizes";
import { Collection, Folder, Workspace } from "types";
import { tracker } from "utils/analytics";

import Subheader from "./components/Subheader/Subheader";
import CollectionsExplorerPanel from "components/CollectionsExplorerPanel/CollectionsExplorerPanel";
import CollectionViewer from "components/CollectionViewer/CollectionViewer";
import FolderOrWorkspaceViewer from "components/FolderOrWorkspaceViewer/FolderOrWorkspaceViewer";
import CollectionNavBar from "components/NavBars/CollectionNavBar";
import QuizPanel from "features/CollectionEditor/QuizPanel/QuizPanel";

const MyCollections = () => {
    const { isDesktopScreen, isMobileScreen } = useScreenSizes();
    const navigate = useNavigate();
    const {
        config: { language },
    } = useAppContext();
    const editWorkspaceMutation = useEditWorkspaceMutation();
    const editFolderMutation = useEditFolderMutation();
    const collectionsParams = useCollectionsParams();
    const { data: allRootFolders } = useAllFoldersEnhancedQuery();
    const selectedWorkspaceAndFoldersAndCollections =
        useSelectedWorkspaceAndFoldersAndCollections();
    const selectedFirstChild = selectedWorkspaceAndFoldersAndCollections[1];
    const selectedWorkspaceOrFolderOrCollection =
        selectedWorkspaceAndFoldersAndCollections.at(-1) as
            | Workspace
            | Folder
            | Collection
            | undefined;
    const { data: fullCollection } = useCollectionQuery(
        selectedWorkspaceOrFolderOrCollection?.id,
    );
    const pageTitle = selectedWorkspaceOrFolderOrCollection?.title
        ? selectedWorkspaceOrFolderOrCollection.type === "WORKSPACE"
            ? translate(language, selectedWorkspaceOrFolderOrCollection?.title)
            : selectedWorkspaceOrFolderOrCollection.title
        : translate(language, "My collections");

    // Folder items
    const folderItems = selectedWorkspaceOrFolderOrCollection?.children
        ? selectedWorkspaceOrFolderOrCollection?.children
        : allRootFolders;
    const sortedFolderItems = selectedWorkspaceAndFoldersAndCollections[0]
        ? selectedWorkspaceOrFolderOrCollection?.sortBy === "TITLE"
            ? [...folderItems].sort((a, b) =>
                  a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1,
              )
            : [...folderItems].sort((a, b) =>
                  a.lastModifiedDate < b.lastModifiedDate ? 1 : -1,
              )
        : [...folderItems];

    // Collection items
    const { data: collectionItems, isFetched: collectionItemsIsFetched } =
        useItemsInCollectionQuery(
            selectedWorkspaceOrFolderOrCollection &&
                selectedWorkspaceOrFolderOrCollection.type === "COLLECTION"
                ? selectedWorkspaceOrFolderOrCollection.id
                : undefined,
        );

    const folderOrWorkspaceClickHandler = (
        folderOrWorkspace: Folder | Workspace,
        pathString?: string,
    ) => {
        if (!pathString) return;
        tracker("Folder clicked", folderOrWorkspace);
        navigate(pathString);
    };
    const folderOrWorkspaceViewClickHandler = (
        folderOrWorkspace: Folder | Workspace,
    ) => {
        folderOrWorkspaceClickHandler(
            folderOrWorkspace,
            location.pathname + "/" + folderOrWorkspace.id,
        );
    };
    const collectionClickHandler = (
        collection: Collection,
        pathString?: string,
    ) => {
        if (!pathString) return;
        tracker("Collection clicked", collection);
        navigate(pathString);
    };
    const collectionViewClickHandler = (collection: Collection) => {
        collectionClickHandler(
            collection,
            location.pathname + "/" + collection.id,
        );
    };
    const openQuizEditorHandler = () => {
        navigate(
            `/edit-collection/${selectedWorkspaceOrFolderOrCollection?.id}`,
            {
                state: {
                    collectionsParams: collectionsParams,
                    openQuizEditor: true,
                },
            },
        );
    };
    const setViewAsHandler = (newValue: Folder["view"]) => {
        if (selectedWorkspaceOrFolderOrCollection) {
            if (selectedWorkspaceOrFolderOrCollection.type === "WORKSPACE") {
                editWorkspaceMutation.mutate({
                    ...selectedWorkspaceOrFolderOrCollection,
                    view: newValue,
                });
            } else if (
                selectedWorkspaceOrFolderOrCollection.type === "FOLDER"
            ) {
                editFolderMutation.mutate({
                    ...selectedWorkspaceOrFolderOrCollection,
                    view: newValue,
                });
            }
        }
    };
    const setSortByHandler = (newValue: Folder["sortBy"]) => {
        if (selectedWorkspaceOrFolderOrCollection) {
            if (selectedWorkspaceOrFolderOrCollection.type === "WORKSPACE") {
                editWorkspaceMutation.mutate({
                    ...selectedWorkspaceOrFolderOrCollection,
                    sortBy: newValue,
                });
            } else if (
                selectedWorkspaceOrFolderOrCollection.type === "FOLDER"
            ) {
                editFolderMutation.mutate({
                    ...selectedWorkspaceOrFolderOrCollection,
                    sortBy: newValue,
                });
            }
        }
    };
    return (
        <>
            <CollectionNavBar
                previousCrumbs={[
                    {
                        label: translate(language, "My collections"),
                        pathname: "/my-collections",
                    },
                ]}
            />
            <Subheader
                viewAs={selectedWorkspaceOrFolderOrCollection?.view ?? "GRID"}
                setViewAs={setViewAsHandler}
                sortBy={
                    selectedWorkspaceOrFolderOrCollection?.sortBy ??
                    "LAST_MODIFIED_DATE"
                }
                setSortBy={setSortByHandler}
                openQuizEditorHandler={openQuizEditorHandler}
            />
            <div
                style={{
                    padding: isMobileScreen ? "20px 10px" : "20px 40px",
                    marginBottom: 10,
                    width: "100%",
                }}
            >
                <Grid container spacing={"20px"}>
                    {isDesktopScreen && (
                        <Grid lg={3} xs={0}>
                            <CollectionsExplorerPanel
                                items={allRootFolders}
                                selectedIds={collectionsParams}
                                folderOrWorkspaceClickHandler={
                                    folderOrWorkspaceClickHandler
                                }
                                collectionClickHandler={collectionClickHandler}
                            />
                        </Grid>
                    )}
                    <Grid lg={9} xs={12}>
                        <div className={styles.title}>
                            <Typography
                                variant="text-size-Medium"
                                color="$text-colors-color-text-dark.main"
                            >
                                {pageTitle}
                            </Typography>
                        </div>
                        {selectedWorkspaceOrFolderOrCollection &&
                        selectedWorkspaceOrFolderOrCollection.type ===
                            "COLLECTION" ? (
                            <div>
                                <Grid container spacing={"20px"} columns={9}>
                                    {!isDesktopScreen && fullCollection && (
                                        <Grid xs={9}>
                                            <QuizPanel
                                                collection={fullCollection}
                                                editClickHandler={
                                                    openQuizEditorHandler
                                                }
                                            />
                                        </Grid>
                                    )}
                                    <Grid lg={6} xs={9}>
                                        <CollectionViewer
                                            items={collectionItems ?? []}
                                            isFetched={collectionItemsIsFetched}
                                            viewAs={
                                                fullCollection?.view ?? "LIST"
                                            }
                                            collectionResultsModalEnabled={
                                                !!fullCollection?.resultModal
                                            }
                                        />
                                    </Grid>
                                    {isDesktopScreen && (
                                        <Grid lg={3}>
                                            <QuizPanel
                                                collection={fullCollection}
                                                editClickHandler={
                                                    openQuizEditorHandler
                                                }
                                            />
                                        </Grid>
                                    )}
                                </Grid>
                            </div>
                        ) : (
                            <FolderOrWorkspaceViewer
                                items={sortedFolderItems}
                                isFetched={
                                    selectedFirstChild &&
                                    "hasBeenFetched" in selectedFirstChild
                                        ? !!selectedFirstChild.hasBeenFetched
                                        : true
                                }
                                viewAs={
                                    selectedWorkspaceOrFolderOrCollection?.view ??
                                    "GRID"
                                }
                                sortBy={
                                    selectedWorkspaceOrFolderOrCollection?.sortBy ??
                                    "LAST_MODIFIED_DATE"
                                }
                                folderOrWorkspaceClickHandler={
                                    folderOrWorkspaceViewClickHandler
                                }
                                collectionClickHandler={
                                    collectionViewClickHandler
                                }
                            />
                        )}
                    </Grid>
                </Grid>
            </div>
        </>
    );
};

export default MyCollections;
