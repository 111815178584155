import wizenozePlaceholder from "images/wizenoze-placeholder.webp";

export const setBlankImage = (
    e: { target: { src: string; onError: null } },
    src: string | undefined,
) => {
    e.target.src = src ?? wizenozePlaceholder;
    e.target.onError = null;
};

export const capitaliseFirstLetter = (str = "") =>
    str?.charAt(0)?.toUpperCase() + str?.slice(1);

/**
 * Determine if the given source is a youtube link or not.
 * @param {String | URL } url The url string or object to be checked
 * @returns Boolean
 */
export const isSrcYouTube = function (url: string | URL) {
    if (typeof url === "string" && url.length > 0) {
        url = new URL(url);
    }

    if (!(url instanceof URL)) {
        console.info({ url });
        throw new Error(
            "The url seems invalid, it should be an instance of URL",
        );
    }
    return Boolean(/youtube/i.test(url.hostname));
};

export const formatYoutubeEmbedUrl = (url: string) => {
    // Regular expression to match YouTube URLs
    const regExp =
        /^(https?:\/\/)?(www\.)?(youtube\.com\/watch\?v=|youtu\.be\/)([a-zA-Z0-9_-]{11})(\S*)$/;

    // Check if the provided URL matches the YouTube URL pattern
    const match = url.match(regExp);

    // If there's a match, generate the embed URL
    if (match) {
        const videoId = match[4];
        let params = match[5] || ""; // Additional parameters, if any

        // Replace the first character with "?" if params exist
        if (params.length > 0) {
            params = "?" + params.substr(1);
        }

        const embedUrl = "https://www.youtube.com/embed/" + videoId + params;
        return embedUrl;
    } else {
        // console.error("Invalid Youtube URL");
        return url;
    }
};
