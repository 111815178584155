import { faCode, faBookOpenReader } from "@fortawesome/free-solid-svg-icons";
import {
    Checkbox,
    FormControl,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
} from "@mui/material";

import { settings } from "./SearchFilters.module.scss";
import { useSearchContext } from "contexts/SearchContext";
import { ReadingLevel } from "types";
import { tracker } from "utils/analytics";

import ResultTypesSection from "./ResultTypesSection";
import WorkspacesSection from "./WorkspacesSection";
import LabeledSwitch from "components/InputFields/LabeledSwitch/LabeledSwitch";
import { PanelSection, PanelV2 } from "components/Panel/Panel";
import ReadingLevelFilter, {
    allReadingLevels,
} from "components/ReadingLevelFilter/ReadingLevelFilter";
import ReadingLevelTooltip from "components/ReadingLevelFilter/components/ReadingLevelTooltip/ReadingLevelTooltip";
import Translate, { TranslatableText } from "components/Translate/Translate";

const ReadingLevelsSection = () => {
    const { resultTypes, readingLevels, setReadingLevels } = useSearchContext();

    const updateReadingLevels = (level: ReadingLevel) => {
        if (allReadingLevels.length === readingLevels.size) {
            tracker("Change reading level", { readingLevel: [level] });
            setReadingLevels(new Set([level]));
        } else {
            const newReadingLevels = [...readingLevels];
            if (newReadingLevels.includes(level)) {
                newReadingLevels.splice(newReadingLevels.indexOf(level), 1);
            } else {
                newReadingLevels.push(level);
                newReadingLevels.sort();
            }
            if (newReadingLevels.length !== 0) {
                tracker("Changed reading level", {
                    readingLevel: newReadingLevels,
                });
                setReadingLevels(new Set(newReadingLevels));
            } else {
                tracker("Changed reading level", {
                    readingLevel: [1, 2, 3, 4, 5],
                });
                setReadingLevels(new Set(allReadingLevels));
            }
        }
    };

    if (!resultTypes.has("TEXT")) return <></>;

    return (
        <PanelSection
            title="Filter texts by reading level"
            icon={faBookOpenReader}
            tooltip={{
                component: <ReadingLevelTooltip />,
            }}
        >
            <div className={settings}>
                <ReadingLevelFilter
                    readingLevels={readingLevels}
                    updateReadingLevels={updateReadingLevels}
                />
            </div>
        </PanelSection>
    );
};

const EmbeddableSection = () => {
    const { embeddable, setEmbeddable, resultTypes } = useSearchContext();
    if (!resultTypes.has("TEXT") && !resultTypes.has("VIDEO")) return <></>;
    return (
        <PanelSection
            title="Wizenoze viewer"
            icon={faCode}
            tooltip={{
                title: "Wizenoze viewer",
                description:
                    "Enable this filter to see only resources that can be opened within the Wizenoze viewer.",
            }}
        >
            <div className={settings}>
                <LabeledSwitch
                    label={"Only resources available in the Wizenoze viewer"}
                    keyname={"embed"}
                    value={embeddable}
                    updateHandler={() => setEmbeddable(!embeddable)}
                />
            </div>
        </PanelSection>
    );
};

export const allSubjects: Array<{ id: string; name: TranslatableText }> = [
    { id: "wz.biology", name: "Biology" },
    { id: "wz.chemistry", name: "Chemistry" },
    { id: "wz.english", name: "English" },
    { id: "wz.mathematics", name: "Mathematics" },
    { id: "wz.physics", name: "Physics" },
    { id: "wz.science", name: "Science" },
];

const CurriculumAlignmentSection = () => {
    const { subjects, setSubjects, resultTypes } = useSearchContext();
    if (!resultTypes.has("TEXT") && !resultTypes.has("VIDEO")) return <></>;
    return (
        <PanelSection title="Curriculum alignment">
            <div className={settings}>
                <FormControl>
                    <InputLabel id="select-subject-label">
                        <Translate text="Select subject(s)" />
                    </InputLabel>
                    <Select
                        labelId="select-subject-label"
                        multiple
                        value={[...subjects]}
                        onChange={(e) => {
                            const newSubjects = e.target.value;
                            tracker("Changed subjects", {
                                subjects: newSubjects,
                            });
                            setSubjects(new Set(newSubjects));
                        }}
                        input={
                            <OutlinedInput
                                label={<Translate text="Select subject(s)" />}
                            />
                        }
                        renderValue={(selected) =>
                            allSubjects
                                .filter((subject) =>
                                    selected.includes(subject.id),
                                )
                                .map((subject) => subject.name)
                                .join(", ")
                        }
                        MenuProps={{
                            style: { zIndex: 35001 },
                        }}
                    >
                        {allSubjects.map((el) => (
                            <MenuItem key={el.id} value={el.id}>
                                <Checkbox checked={subjects.has(el.id)} />
                                <Translate text={el.name} />
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
        </PanelSection>
    );
};

const SearchFilters = () => {
    return (
        <PanelV2
            styleOverrides={{
                flex: 1,
                gap: "30px",
                overflowY: "auto",
            }}
        >
            <ResultTypesSection />
            <WorkspacesSection />
            <ReadingLevelsSection />
            <CurriculumAlignmentSection />
            <EmbeddableSection />
        </PanelV2>
    );
};

export default SearchFilters;
