.chatTitle {
    color: var(--text-colors-color-text-dark, #343a40);
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 25px; /* 138.889% */
}

.headerContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--border-colors-color-border-primary, #cbcbcc);
    background: var(--background-colors-color-background-light-off, #f3f3f6);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 10px;
    height: 68px;
}
