import { useContext } from "react";
import {
    SortableContext,
    verticalListSortingStrategy,
} from "@dnd-kit/sortable";

import { CollectionContext } from "features/CollectionEditor/state/CollectionContext";
import { CollectionResourceResult } from "types";
import { convertItemTypeToResultType } from "utils/typeConvertors";

import SortableListResult from "./components/SortableListResult";
import AddResourcePanel from "../AddResourcesPanel/AddResourcePanel";

const ResourcesContainerListView = () => {
    const { selectedItems, highlightedItems, removeItem } =
        useContext(CollectionContext);
    const results: CollectionResourceResult[] = selectedItems.map((item) =>
        convertItemTypeToResultType(item),
    );
    return (
        <>
            <AddResourcePanel />
            {selectedItems.length > 0 ? (
                <>
                    <SortableContext
                        items={selectedItems}
                        strategy={verticalListSortingStrategy}
                    >
                        <div
                            style={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "column",
                                gap: "20px",
                            }}
                        >
                            {selectedItems.map((item, index) => (
                                <SortableListResult
                                    key={item.url}
                                    id={item.id}
                                    result={convertItemTypeToResultType(item)}
                                    results={results}
                                    resultIndex={index}
                                    deleteItemHandler={() => removeItem(item)}
                                    isHighlighted={highlightedItems.includes(
                                        item.id,
                                    )}
                                />
                            ))}
                        </div>
                    </SortableContext>
                </>
            ) : (
                <></>
            )}
        </>
    );
};

export default ResourcesContainerListView;
