import { useContext } from "react";

import { CollectionContext } from "features/CollectionEditor/state/CollectionContext";
import { CollectionResourceResult } from "types";
import { tracker } from "utils/analytics";
import { convertResultTypeToItemType } from "utils/typeConvertors";

import ResultCard from "components/ResultCard/ResultCard";

interface Props {
    result: CollectionResourceResult;
}

const SearchResultCard = ({ result }: Props) => {
    const { selectedItems, addItem, removeItem } =
        useContext(CollectionContext);
    const addResourceHandler = () => {
        if (!addItem) return;
        tracker("Add search resource to collection", result);
        const convertedItem = convertResultTypeToItemType(
            result,
            selectedItems.length + 1,
        );
        addItem(convertedItem);
    };
    const removeResourceHandler = () => {
        if (!removeItem) return;
        tracker("Remove search resource from collection", result);
        const foundItem = selectedItems.find((el) => el.indexId === result.id);
        if (!foundItem) {
            console.error("Couldn't find item to remove");
            return;
        }
        removeItem(foundItem);
    };
    const isSelectedResult = selectedItems.some(
        (el) => el.indexId === result.id,
    );
    return (
        <ResultCard
            result={result}
            isSelectedResult={isSelectedResult}
            addResourceHandler={addItem && addResourceHandler}
            removeResourceHandler={removeItem && removeResourceHandler}
        />
    );
};

export default SearchResultCard;
