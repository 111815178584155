@use "../../../../../styles/variables.module";

.container {
    display: flex;
    flex-direction: column;
    padding: 20px 0px 10px 20px;
}

.title {
    color: variables.$text-colors-color-text-dark;
    font-size: 18px;
    font-weight: bold;
    line-height: 25px;
    padding-bottom: 20px;
}

.text {
    color: variables.$text-colors-color-text-dark;
    line-height: 25px;
    padding-bottom: 10px;
}

.supportTeamText {
    color: variables.$text-colors-color-text-blue;
    cursor: pointer;
}
