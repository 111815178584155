@use "../../../../../styles/variables.module";

.feedbackForm {
    position: absolute;
    z-index: 100;
    width: 100%;
    max-width: 400px;
    height: 275px;
    border-radius: 5px;
    box-shadow: 0px 5px 20px rgba(108, 117, 125, 0.5);
    background-color: white;
    right: 0px;
    bottom: 0px;
}

.feedbackMainFrom {
    padding: 0px 20px;
    overflow: auto;
    height: 215px;
}

.feedbackMainFrom > div {
    margin: 30px 0px;
}
