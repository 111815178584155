import * as styles from "./ControlsFooter.module.scss";
import { AnyResource } from "types";
import { tracker } from "utils/analytics";

import Feedback from "./Feedback/Feedback";
import Translate from "components/Translate/Translate";

const ControlsFooter = ({
    isSelectedResult,
    result,
    selectResultHandler,
}: {
    isSelectedResult: boolean | undefined;
    result: AnyResource;
    selectResultHandler: (resultId: string) => void;
}) => {
    if (!("feedbackProvided" in result) && !selectResultHandler) return <></>;
    return (
        <div className={styles.container}>
            <div className={styles.select}>
                <input
                    type="checkbox"
                    checked={isSelectedResult}
                    onChange={() => {
                        tracker("Query result selected", {
                            result,
                        });
                        selectResultHandler(result.id);
                    }}
                    className={styles.checkbox}
                />
                <Translate text="Select" />
            </div>
            {"feedbackProvided" in result && <Feedback result={result} />}
        </div>
    );
};

export default ControlsFooter;
