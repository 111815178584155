import { Typography } from "@mui/material";

import * as styles from "./ResultCardTitle.module.scss";
import { ResourceResultBase } from "types";

import ResultLink from "features/ResultLink/ResultLink";

interface Props {
    results: ResourceResultBase[];
    resultIndex: number;
}

const ResultCardTitle = ({ results, resultIndex }: Props) => {
    return (
        <ResultLink
            results={results}
            initialIndex={resultIndex}
            styles={styles.cardTitleLink}
        >
            <Typography variant="text-size-Medium">
                {results[resultIndex].title}
            </Typography>
        </ResultLink>
    );
};

export default ResultCardTitle;
