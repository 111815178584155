@use "../../../../../../styles/variables.module";

.queryCardBodyContent {
    overflow: hidden;
    height: 280px; /* container is 500 - (image: 150 + footer: 40) */
    // display: flex;
    flex-direction: column;
}

.cardTitle {
    margin-bottom: 0.75rem;
    font-weight: 500;
    color: variables.$text-colors-color-text-blue;
    margin-top: 16px;
    font-weight: 500;
}

.cardTitleLink {
    text-decoration: none;
    /* at this point it wraps with this font-size (not sure yet why) */
    max-width: 100%;
    overflow: hidden;
    display: inline-block;
    margin: 10px 0;
    line-height: 35px;
    cursor: pointer;
}
.cardText {
    color: variables.$text-colors-color-text-gray;
    font-size: 16px;
    line-height: 26px;
    /* This is disabled since we can't use media queries */
    /* max-width: 324px; (check later if this should be re-enabled for the design) */
    overflow: hidden;
    display: inline-block;
    min-height: 78px;
}

.cardText > p {
    margin: 0;
    padding: 0;
}

.cardText > p[data-limit="true"] {
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.missingQueryTerms {
    font-size: 80%;
    font-weight: 400;
    color: variables.$text-colors-color-text-gray;
}
.missingQueryTerms span {
    text-decoration: line-through;
    margin-right: 0.3em;
}
.sourceDecriptorWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 1.95rem;
    margin-bottom: 0.25rem;
    min-height: 40px;
}
