import { Fragment } from "react";
import { Divider, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";

import * as styles from "./FolderOrWorkspaceGridView.module.scss";
import variables from "styles/_variables.module.scss";
import { Collection, Folder, Workspace } from "types";

import FolderOrWorkspaceGridViewSkeleton from "./components/FolderOrWorkspaceGridViewSkeleton";
import CollectionCard from "components/CollectionCard/CollectionCard";
import EmptyFolderMessage from "components/EmptyFolderMessage/EmptyFolderMessage";
import FolderOrWorkspaceCard from "components/FolderOrWorkspaceCard/FolderOrWorkspaceCard";
import Translate from "components/Translate/Translate";

interface Props {
    items: (Collection | Folder | Workspace)[];
    foldersOrWorkspaces: (Folder | Workspace)[];
    collections: Collection[];
    isFetched: boolean;
    sortBy: Folder["sortBy"];
    folderOrWorkspaceClickHandler: (
        folderOrWorkspace: Folder | Workspace,
    ) => void;
    collectionClickHandler: (collection: Collection) => void;
    disableContextMenu?: boolean;
}

const FolderOrWorkspaceGridView = ({
    items,
    foldersOrWorkspaces,
    collections,
    isFetched,
    sortBy,
    folderOrWorkspaceClickHandler,
    collectionClickHandler,
    disableContextMenu,
}: Props) => {
    const isEmptyFolder = items && items.length === 0;
    return !isFetched ? (
        <FolderOrWorkspaceGridViewSkeleton />
    ) : isEmptyFolder ? (
        <EmptyFolderMessage />
    ) : (
        <Grid container spacing={"20px"}>
            {sortBy === "TYPE" ? (
                <>
                    {foldersOrWorkspaces.length > 0 && (
                        <Grid xs={12}>
                            <div className={styles.type}>
                                <Typography
                                    variant="text-size-Base-bold"
                                    color="$text-colors-color-text-dark.main"
                                >
                                    <Translate text="Folders" />
                                </Typography>
                            </div>
                            <Grid container spacing={"20px"} padding={"0px"}>
                                {foldersOrWorkspaces.map(
                                    (folderOrWorkspace) => (
                                        <Grid
                                            key={folderOrWorkspace.id}
                                            md={4}
                                            xs={12}
                                        >
                                            <FolderOrWorkspaceCard
                                                folderOrWorkspace={
                                                    folderOrWorkspace
                                                }
                                                clickHandler={() =>
                                                    folderOrWorkspaceClickHandler(
                                                        folderOrWorkspace,
                                                    )
                                                }
                                                disableContextMenu={
                                                    disableContextMenu
                                                }
                                            />
                                        </Grid>
                                    ),
                                )}
                            </Grid>
                        </Grid>
                    )}
                    {collections.length > 0 && (
                        <Grid xs={12}>
                            <div className={styles.type}>
                                <Typography
                                    variant="text-size-Base-bold"
                                    color="$text-colors-color-text-dark.main"
                                >
                                    <Translate text="Collections" />
                                </Typography>
                            </div>
                            <Grid container spacing={"20px"} padding={"0px"}>
                                {collections.map((collection) => (
                                    <Grid key={collection.id} md={4} xs={12}>
                                        <CollectionCard
                                            collection={collection}
                                            clickHandler={() =>
                                                collectionClickHandler(
                                                    collection,
                                                )
                                            }
                                            disableContextMenu={
                                                disableContextMenu
                                            }
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                    )}
                </>
            ) : (
                <>
                    {items.map((element, index) => (
                        <Fragment key={"fragment" + element.id}>
                            {index !== 0 && index % 3 === 0 && (
                                <Grid xs={12} key={"divider" + index}>
                                    <Divider
                                        orientation="horizontal"
                                        sx={{
                                            height: "0px",
                                            borderColor:
                                                variables[
                                                    "border-colors-color-border-light"
                                                ],
                                        }}
                                    />
                                </Grid>
                            )}
                            <Grid
                                key={element.type + element.id}
                                md={4}
                                xs={12}
                            >
                                {element.type === "WORKSPACE" ||
                                element.type === "FOLDER" ? (
                                    <FolderOrWorkspaceCard
                                        folderOrWorkspace={element}
                                        clickHandler={() =>
                                            folderOrWorkspaceClickHandler(
                                                element,
                                            )
                                        }
                                        disableContextMenu={disableContextMenu}
                                    />
                                ) : (
                                    <CollectionCard
                                        collection={element}
                                        clickHandler={() =>
                                            collectionClickHandler(element)
                                        }
                                        disableContextMenu={disableContextMenu}
                                    />
                                )}
                            </Grid>
                        </Fragment>
                    ))}
                </>
            )}
        </Grid>
    );
};

export default FolderOrWorkspaceGridView;
