@use "../../../../../../../styles/variables.module";

.cardTitle {
    color: variables.$text-colors-color-text-blue;
    margin-top: 16px;
    margin-bottom: 0.75rem;
    margin: 10px 0;
    text-align: left;
}

.cardTitleLink {
    text-align: left;
    text-decoration: none;
    max-width: 100%;
    overflow: hidden;
    display: inline-block;
    cursor: pointer;
}
