import { badgeIconContainer } from "../PremiumIcon/PremiumIcon.module.scss";
import teacherApprovedSVG from "./teacher_aproved_ribbon.svg";
import { OldResult } from "types";
import { collectionAccessTypes } from "utils/constants";

interface Props {
    isTeacherApproved: boolean;
    collectionAccessType: OldResult["collectionAccessType"];
}

const TeacherApproved = ({
    isTeacherApproved,
    collectionAccessType,
}: Props) => {
    const isBadgeVisible =
        collectionAccessType !== collectionAccessTypes?.paidCollection &&
        isTeacherApproved;

    if (!isBadgeVisible) return <></>;

    return (
        <div className={badgeIconContainer}>
            <img src={teacherApprovedSVG} alt="teacher approved badge" />
        </div>
    );
};

export default TeacherApproved;
