@use "../../../../../../../styles/variables.module";

.formFooter {
    display: flex;
    align-items: center;
    border-top: 1px solid variables.$border-colors-color-border-primary;
    height: 60px;
    bottom: 0px;
    position: absolute;
    width: 100%;
    overflow: hidden;
    justify-content: flex-end;
}

.formFooter > button {
    margin: 10px;
}

.submitButton {
    height: 40px;
    padding: 10px 20px;
    border-radius: 5px;
    color: white;
    border: none;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
    background-color: variables.$background-colors-color-background-blue;
}

.submitButton[disabled] {
    background-color: variables.$border-colors-color-border-primary;
}

.cancelButton {
    background: white;
    border: none;
    color: variables.$text-colors-color-text-gray;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
}
