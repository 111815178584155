import { badgeIconContainer } from "./PremiumIcon.module.scss";
import premiumRibbonSVG from "./premium_ribbon_256x256.svg";
import { OldResult } from "types";

const PremiumIcon = ({
    collectionAccessType,
}: {
    collectionAccessType: OldResult["collectionAccessType"];
}) => {
    if (collectionAccessType !== "paidCollection") return <></>;
    return (
        <div className={badgeIconContainer}>
            <img src={premiumRibbonSVG} alt="premium ribbon" />
        </div>
    );
};

export default PremiumIcon;
