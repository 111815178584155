@use "../../../../../styles/variables.module";

.card {
    box-shadow: 0 5px 20px rgba(108, 117, 125, 0.2);
    border-radius: 15px;
    background-color: white;
    min-height: 480px;
    max-width: 100%;
    /* min-width: 280px; */
    position: relative;
    overflow: hidden;
}

.container {
    position: relative;
    padding: 20px 20px 10px 20px;
}

.visualContainer {
    display: flex;
    justify-content: center;
    position: relative;
    height: 150px;
}

.selectCheckbox {
    border: 1px solid variables.$border-colors-color-border-primary;
    border-radius: 5px;
    background-color: white;
    top: 10px;
    left: 10px;
    position: absolute;
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 5px 10px;
    font-size: 12px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
}

.checkbox {
    width: 20px;
    height: 20px;
    margin: 0px;
    cursor: pointer;
}

.imageContainer {
    width: 100%;
    max-height: 150px;
    min-height: 150px;
    overflow: hidden;
    display: flex;
    place-items: center;
    justify-content: center;
    position: relative;
    border-radius: 5px;
    cursor: pointer;
}

/* The first child of the featured card is the image */
div[data-numberofresults="5"]
    .container:first-child[data-large-screen="true"][data-mobile-screen="false"]
    .imageContainer {
    grid-column-start: 2;
    grid-row: 1 / -1;
    max-height: 500px;
    min-width: 275px;
    max-width: 420px;
    justify-self: end;
}

.videoIconContainer {
    position: absolute;
    top: 80px;
    left: 16px;
}

.videoIcon {
    position: absolute;
    left: 16px;
    color: variables.$message-colors-color-message-danger;
    font-size: 3.8rem;
    background-color: white;
    border-radius: 50%;
    border: 2px solid variables.$icon-colors-green-100;
    width: 100%;
}

/* Override font awesome */
.videoIconContainer svg.videoIcon {
    width: 60px;
    height: 60px;
}

.textContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    height: 300px;
}

.feedbackFooter {
    background: variables.$background-colors-color-background-light-off;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 10px 20px;
    height: 50px;
}
