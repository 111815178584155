import { SearchContextProvider } from "contexts/SearchContext";
import useScreenSizes from "hooks/useScreenSizes";

import Search from "components/Search/Search";

const AddFromSearch = () => {
    const { isMobileScreen } = useScreenSizes();
    return (
        <div
            style={{
                padding: !isMobileScreen ? "20px" : "10px",
                width: "100%",
            }}
        >
            <SearchContextProvider inDialogBox>
                <Search styleOverrides={{ marginTop: "60px" }} />
            </SearchContextProvider>
        </div>
    );
};

export default AddFromSearch;
