import { CSSProperties, useState } from "react";
import Box from "@mui/material/Box";

import WizenozeLogo from "images/wizenoze-placeholder.webp";

type Props = React.ComponentPropsWithoutRef<typeof Box> & {
    src: string | null;
    alt?: string;
    hideOnError?: boolean;
    styleOverrides?: CSSProperties;
};

const ExternalImageWithFallback = ({
    src,
    alt,
    hideOnError,
    styleOverrides,
    ...props
}: Props) => {
    const [imgSrc, setImgSrc] = useState(src ?? WizenozeLogo);
    const [loaded, setLoaded] = useState(false);
    const [hasFailed, setHasFailed] = useState(!src);

    const handleError = () => {
        console.warn("image load error");
        setHasFailed(true);
        setImgSrc(WizenozeLogo);
    };

    const handleLoad = () => {
        console.warn("load success");
        setLoaded(true);
    };
    if (hideOnError && hasFailed) return null;
    return (
        <Box
            component="img"
            src={imgSrc}
            alt={alt}
            onLoad={handleLoad}
            onError={handleError}
            sx={{
                // maxWidth: "100%",
                width: "100%",
                maxHeight: "100%",
                display: loaded ? "inline" : "none",
                objectFit: hasFailed ? "contain" : "cover",
                ...styleOverrides,
            }}
            {...props}
        />
    );
};

export default ExternalImageWithFallback;
