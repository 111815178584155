import { subheaderWrapper } from "./NavBar.module.scss";
import { useAppContext } from "contexts/AppContext";
import { useSelectedWorkspaceAndFoldersAndCollections } from "hooks/api/folders";
import variables from "styles/_variables.module.scss";

import BackButton from "./BackButton/BackButton";
import Breadcrumbs, { Breadcrumb } from "./Breadcrumbs/Breadcrumbs";
import { translate } from "components/Translate/Translate";

const CollectionNavBar = ({
    pageTitle,
    previousCrumbs,
    customHomePath,
    customBackbuttonOnClick,
    customHomebuttonOnClick,
}: {
    pageTitle?: string;
    previousCrumbs?: Array<{ label: string; pathname: string }>;
    customHomePath?: string;
    customBackbuttonOnClick?: () => void;
    customHomebuttonOnClick?: () => void;
}) => {
    const {
        config: { language },
    } = useAppContext();
    const selectedWorkspaceAndFoldersAndCollections =
        useSelectedWorkspaceAndFoldersAndCollections();
    const newBreadcrumbs: Breadcrumb[] = [];
    newBreadcrumbs.push({
        label: "Home",
        pathname: customHomePath,
        customOnClick: customHomebuttonOnClick,
    });
    if (previousCrumbs) {
        previousCrumbs.forEach((pCrumb) =>
            newBreadcrumbs.push({
                label: pCrumb.label,
                pathname: pCrumb.pathname,
            }),
        );
    }
    if (pageTitle) {
        newBreadcrumbs.push({
            label: pageTitle,
        });
    }
    if (selectedWorkspaceAndFoldersAndCollections.length > 0) {
        let buildingPathname = "/my-collections";
        selectedWorkspaceAndFoldersAndCollections.forEach(
            (folderOrWorkspace) => {
                buildingPathname += "/" + folderOrWorkspace.id;
                newBreadcrumbs.push({
                    label:
                        folderOrWorkspace.type === "WORKSPACE"
                            ? translate(language, folderOrWorkspace.title)
                            : folderOrWorkspace.title,
                    pathname: buildingPathname,
                });
            },
        );
    }

    return (
        <div className={subheaderWrapper}>
            <BackButton
                styleOverrride={{
                    color: variables["text-colors-color-text-white"],
                    backgroundColor: variables["color-background-light-gray"],
                }}
                customOnClick={customBackbuttonOnClick}
            />
            <Breadcrumbs breadcrumbs={newBreadcrumbs} />
        </div>
    );
};

export default CollectionNavBar;
