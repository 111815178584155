@use "../../../styles/variables.module";

.container {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    flex: 1;
    background: variables.$background-colors-color-background-light;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    margin-left: auto;
}
