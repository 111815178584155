import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Divider, Typography } from "@mui/material";
import { faShare, faToolbox } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import * as styles from "./Subheader.module.scss";
import {
    useAllFoldersEnhancedQuery,
    useSelectedWorkspaceAndFoldersAndCollections,
} from "hooks/api/folders";
import useCollectionsParams from "hooks/useCollectionsParams";
import useScreenSizes from "hooks/useScreenSizes";
import variables from "styles/_variables.module.scss";
import { Collection, Folder, Workspace } from "types";
import { tracker } from "utils/analytics";

import EditButton from "./EditButton";
import NewElementContextMenu from "./NewElementContextMenu";
import NewFolderDialog from "./NewFolderDialog";
import SortByContextMenu from "./SortByContextMenu";
import ViewAsContextMenu from "./ViewAsContextMenu";
import MobileCollectionsExplorer from "components/CollectionsExplorerPanel/MobileCollectionsExplorer";
import ShareCollectionDialog from "components/ContextMenu/dialogs/ShareCollectionDialog";
import ShareFolderDialog from "components/ContextMenu/dialogs/ShareFolderDialog";
import MenuButton from "components/MenuButton/MenuButton";
import MenuContainer from "components/MenuContainer/MenuContainer";
import Translate from "components/Translate/Translate";
import { GenerateCollectionQuizButton } from "features/CollectionEditor/Subheader/GenerateQuizButton/GenerateQuizButton";

interface ShareButtonProps {
    clickHandler: () => void;
    showLabel: boolean;
    disabled: boolean;
}

const ShareButton = ({
    clickHandler,
    showLabel,
    disabled,
}: ShareButtonProps) => (
    <Button
        variant="outlined"
        className={styles.shareButton}
        disabled={disabled}
        onClick={clickHandler}
    >
        <FontAwesomeIcon icon={faShare} fontSize={12} />
        {showLabel && (
            <Typography variant="text-size-xSmall-bold">
                <Translate text="Share" />
            </Typography>
        )}
    </Button>
);

interface Props {
    viewAs: Folder["view"];
    setViewAs: (viewAs: Folder["view"]) => void;
    sortBy: Folder["sortBy"];
    setSortBy: (sortBy: Folder["sortBy"]) => void;
    openQuizEditorHandler: () => void;
}

const Subheader = ({
    viewAs,
    setViewAs,
    sortBy,
    setSortBy,
    openQuizEditorHandler,
}: Props) => {
    const { isDesktopScreen, isMobileScreen } = useScreenSizes();
    const navigate = useNavigate();
    const collectionsParams = useCollectionsParams();
    const selectedWorkspaceAndFoldersAndCollections =
        useSelectedWorkspaceAndFoldersAndCollections();
    const selectedWorkspaceOrFolderOrCollection =
        selectedWorkspaceAndFoldersAndCollections.at(-1) as
            | Workspace
            | Folder
            | Collection
            | undefined;
    const [isToolboxPanelOpen, setIsToolboxPanelOpen] = useState(false);
    const [isNewMenuOpen, setIsNewMenuOpen] = useState(false);
    const [isViewMenuOpen, setIsViewMenuOpen] = useState(false);
    const [isSortMenuOpen, setIsSortMenuOpen] = useState(false);
    const [isShareModalOpen, setIsShareModalOpen] = useState(false);
    const [isNewFolderModalVisible, setIsNewFolderModalVisible] =
        useState(false);

    const folderOrWorkspaceClickHandler = (
        folderOrWorkspace: Folder | Workspace,
        pathString?: string,
    ) => {
        if (!pathString) return;
        tracker("Folder clicked", folderOrWorkspace);
        navigate(pathString);
    };
    const collectionClickHandler = (
        collection: Collection,
        pathString?: string,
    ) => {
        if (!pathString) return;
        tracker("Collection clicked", collection);
        navigate(pathString);
    };
    const { data: allRootFolders } = useAllFoldersEnhancedQuery();
    const selectedIsWorkspace =
        selectedWorkspaceOrFolderOrCollection &&
        selectedWorkspaceOrFolderOrCollection.type === "WORKSPACE";
    const selectedIsFolder =
        selectedWorkspaceOrFolderOrCollection &&
        selectedWorkspaceOrFolderOrCollection.type === "FOLDER";
    const selectedIsCollection =
        selectedWorkspaceOrFolderOrCollection &&
        selectedWorkspaceOrFolderOrCollection.type === "COLLECTION";
    return (
        <div className={styles.subheader}>
            <div className={styles.subheaderGroup}>
                <div className={styles.editAndMobileExplorer}>
                    {selectedIsCollection && (
                        <EditButton
                            disabled={
                                !selectedWorkspaceOrFolderOrCollection.permissions?.includes(
                                    "UPDATE",
                                )
                            }
                        />
                    )}
                    {!isDesktopScreen && (
                        <MobileCollectionsExplorer
                            items={allRootFolders}
                            selectedIds={collectionsParams}
                            folderOrWorkspaceClickHandler={
                                folderOrWorkspaceClickHandler
                            }
                            collectionClickHandler={collectionClickHandler}
                        />
                    )}
                </div>
                {!isMobileScreen ? (
                    !selectedIsCollection && (
                        <>
                            <NewElementContextMenu
                                isVisible={isNewMenuOpen}
                                setIsVisible={setIsNewMenuOpen}
                                setIsNewFolderModalVisible={
                                    setIsNewFolderModalVisible
                                }
                                disabled={
                                    !selectedWorkspaceOrFolderOrCollection ||
                                    !selectedWorkspaceOrFolderOrCollection.permissions?.includes(
                                        "CREATE",
                                    )
                                }
                            />
                            <Divider orientation="vertical" />
                            <ViewAsContextMenu
                                viewAs={viewAs}
                                updateViewAs={(viewAs) => {
                                    setViewAs(viewAs);
                                }}
                                isVisible={isViewMenuOpen}
                                setIsVisible={setIsViewMenuOpen}
                                disabled={
                                    !selectedWorkspaceOrFolderOrCollection ||
                                    !selectedWorkspaceOrFolderOrCollection.permissions?.includes(
                                        "UPDATE",
                                    )
                                }
                            />
                            <SortByContextMenu
                                sortBy={sortBy}
                                updateSortBy={(sortBy) => {
                                    setSortBy(sortBy);
                                }}
                                isVisible={isSortMenuOpen}
                                setIsVisible={setIsSortMenuOpen}
                                disabled={
                                    !selectedWorkspaceOrFolderOrCollection ||
                                    !selectedWorkspaceOrFolderOrCollection.permissions?.includes(
                                        "UPDATE",
                                    )
                                }
                            />
                        </>
                    )
                ) : (
                    <>
                        {!selectedIsCollection && (
                            <MenuButton
                                faIcon={faToolbox}
                                iconColor={variables["icon-colors-blue-100"]}
                                primaryColor={variables["icon-colors-blue-40"]}
                                secondaryColor={
                                    variables["icon-colors-blue-60"]
                                }
                                isOpen={isToolboxPanelOpen}
                                styleOverrides={{
                                    width: "30px",
                                    minWidth: "30px",
                                    height: "30px",
                                    fontSize: "12px",
                                }}
                                onClick={() => {
                                    tracker("Toolbox panel menu open");
                                    setIsToolboxPanelOpen(true);
                                }}
                            />
                        )}
                        <div style={{ display: "flex", gap: 20 }}>
                            {selectedIsCollection && (
                                <GenerateCollectionQuizButton
                                    clickHandler={openQuizEditorHandler}
                                    isDisabled={
                                        !selectedWorkspaceOrFolderOrCollection.permissions?.includes(
                                            "UPDATE",
                                        )
                                    }
                                />
                            )}
                            <ShareButton
                                clickHandler={() => setIsShareModalOpen(true)}
                                showLabel={false}
                                disabled={
                                    !selectedWorkspaceOrFolderOrCollection ||
                                    selectedIsWorkspace ||
                                    !selectedWorkspaceOrFolderOrCollection.permissions?.includes(
                                        "SHARE",
                                    )
                                }
                            />
                        </div>
                        <MenuContainer
                            isOpen={isToolboxPanelOpen}
                            closeMenu={() => setIsToolboxPanelOpen(false)}
                            containerRelativePosition="bottom-right"
                            fullWidthBreakpoint="xl"
                            fullHeightBreakpoint="xl"
                        >
                            <div className={styles.mobileContainer}>
                                <NewElementContextMenu
                                    isVisible={isNewMenuOpen}
                                    setIsVisible={setIsNewMenuOpen}
                                    setIsNewFolderModalVisible={(
                                        open: boolean,
                                    ) => {
                                        setIsToolboxPanelOpen(false);
                                        setIsNewFolderModalVisible(open);
                                    }}
                                    disabled={
                                        !selectedWorkspaceOrFolderOrCollection ||
                                        !selectedWorkspaceOrFolderOrCollection.permissions?.includes(
                                            "CREATE",
                                        )
                                    }
                                />
                                <ViewAsContextMenu
                                    viewAs={viewAs}
                                    updateViewAs={(viewAs) => {
                                        setViewAs(viewAs);
                                    }}
                                    isVisible={isViewMenuOpen}
                                    setIsVisible={setIsViewMenuOpen}
                                    disabled={
                                        !selectedWorkspaceOrFolderOrCollection ||
                                        !selectedWorkspaceOrFolderOrCollection.permissions?.includes(
                                            "UPDATE",
                                        )
                                    }
                                />
                                <SortByContextMenu
                                    sortBy={sortBy}
                                    updateSortBy={(sortBy) => {
                                        setSortBy(sortBy);
                                    }}
                                    isVisible={isSortMenuOpen}
                                    setIsVisible={setIsSortMenuOpen}
                                    disabled={
                                        !selectedWorkspaceOrFolderOrCollection ||
                                        !selectedWorkspaceOrFolderOrCollection.permissions?.includes(
                                            "UPDATE",
                                        )
                                    }
                                />
                            </div>
                        </MenuContainer>
                    </>
                )}
            </div>
            {!isMobileScreen && (
                <div style={{ display: "flex", gap: 20 }}>
                    {selectedIsCollection && (
                        <GenerateCollectionQuizButton
                            clickHandler={openQuizEditorHandler}
                            isDisabled={
                                !selectedWorkspaceOrFolderOrCollection.permissions?.includes(
                                    "UPDATE",
                                )
                            }
                        />
                    )}
                    <ShareButton
                        clickHandler={() => setIsShareModalOpen(true)}
                        showLabel={isDesktopScreen}
                        disabled={
                            !selectedWorkspaceOrFolderOrCollection ||
                            selectedIsWorkspace ||
                            !selectedWorkspaceOrFolderOrCollection.permissions?.includes(
                                "SHARE",
                            )
                        }
                    />
                </div>
            )}
            <NewFolderDialog
                isVisible={isNewFolderModalVisible}
                setIsVisible={setIsNewFolderModalVisible}
            />
            {selectedWorkspaceOrFolderOrCollection &&
                !selectedIsWorkspace &&
                (selectedIsFolder ? (
                    <ShareFolderDialog
                        folder={selectedWorkspaceOrFolderOrCollection}
                        isVisible={isShareModalOpen}
                        setIsVisible={setIsShareModalOpen}
                    />
                ) : (
                    <ShareCollectionDialog
                        collection={selectedWorkspaceOrFolderOrCollection}
                        isVisible={isShareModalOpen}
                        setIsVisible={setIsShareModalOpen}
                    />
                ))}
        </div>
    );
};

export default Subheader;
