import { useSearchContext } from "contexts/SearchContext";
import { useAllFoldersEnhancedQuery } from "hooks/api/folders";
import { useSearchSuggestionsQuery } from "hooks/api/useSearchQuery";

import SuggestionsList from "./components/SuggestionsList";

const Suggestions = ({
    searchTerm,
    closeSearch,
}: {
    searchTerm: string;
    closeSearch: () => void;
}) => {
    const { inDialogBox } = useSearchContext();
    const { data: allWorkspaces } = useAllFoldersEnhancedQuery();
    const { data: suggestionsData } = useSearchSuggestionsQuery(searchTerm, {
        query: searchTerm,
        filters: {
            resultTypes: inDialogBox
                ? ["TEXT", "VIDEO"]
                : ["TEXT", "VIDEO", "COLLECTION", "FOLDER"],
            workspaces: allWorkspaces.map((el) => el.id),
            readingLevels: [1, 2, 3, 4, 5],
        },
    });

    if (
        !suggestionsData ||
        (suggestionsData?.length ? "" : Boolean(searchTerm))
    )
        return <></>;
    return (
        <SuggestionsList
            suggestions={suggestionsData}
            closeSearch={closeSearch}
        />
    );
};

export default Suggestions;
