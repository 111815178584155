import * as styles from "../ResultCardFooter.module.scss";
import wizenozePlaceholder from "images/wizenoze-placeholder.webp";
import { OldResult } from "types";
import { setBlankImage } from "utils/oldhelpers";

import PremiumIconFooter from "../../PremiumIcon/PremiumIconFooter";
import ResultLink from "features/ResultLink/ResultLink";

interface Props {
    results: OldResult[];
    resultIndex: number;
}

const SourceDescriptor = ({ results, resultIndex }: Props) => {
    const result = results[resultIndex];
    return (
        <>
            {result?.sourceLogoThumbnail && (
                <div className={styles.resultLogo}>
                    <PremiumIconFooter
                        collectionAccessType={result?.collectionAccessType}
                    />
                    <img
                        onError={setBlankImage}
                        src={result.sourceLogoThumbnail || wizenozePlaceholder}
                        className={styles.sourceLogoImg}
                        alt={result?.sourceUrl + " logo"}
                    />
                </div>
            )}

            {result?.sourceUrl && (
                <ResultLink
                    results={results}
                    initialIndex={resultIndex}
                    styles={styles.resultLink}
                >
                    <span>{result.sourceUrl}</span>
                </ResultLink>
            )}
        </>
    );
};

export default SourceDescriptor;
