import { useContext, useState } from "react";
import { Button, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { useMutation } from "@tanstack/react-query";

import { container, actionsContainer } from "./AddResourcesForm.module.scss";
import { axiosAPI } from "hooks/api/axiosAPI";
import { CollectionItem } from "types";
import { tracker } from "utils/analytics";

import URLInputField from "./URLInputField";
import Translate from "components/Translate/Translate";
import { CollectionContext } from "features/CollectionEditor/state/CollectionContext";

export const useCheckUrlExistsMutation = () => {
    return useMutation(async (url: string) => {
        const response = await axiosAPI
            .getInstance()
            .post("/extract/url", { url });
        return response;
    });
};

type FetchedItem = {
    status: "unfetched" | "pending" | "success" | "fail";
    url: string;
    data: Partial<CollectionItem> | undefined;
};

const AddResourcesForm = ({ hideForm }: { hideForm: () => void }) => {
    const { addItem, selectedItems } = useContext(CollectionContext);
    const [urls, setUrls] = useState<string[]>([""]);
    const [fetchedItems, setFetchedItems] = useState<FetchedItem[]>([
        { status: "unfetched", url: "", data: undefined },
    ]);

    const addNewURL = () => {
        tracker("Additional URL Field Added");
        setUrls([...urls, ""]);
        setFetchedItems([
            ...fetchedItems,
            { status: "unfetched", url: "", data: undefined },
        ]);
    };
    const updateURL = (index: number) => (newUrl: string) => {
        const newUrls = [...urls];
        newUrls[index] = newUrl;
        setUrls(newUrls);

        const newFetchedItems = [...fetchedItems];
        newFetchedItems[index] = {
            status: "unfetched",
            url: newUrl,
            data: undefined,
        };
        setFetchedItems(newFetchedItems);
    };
    const clearURL = (index: number) => () => {
        tracker("Add Resource Form - URL Field Cleared");
        if (urls.length > 1) {
            const newUrls = [...urls];
            newUrls.splice(index, 1);
            const newFetchedItems = [...fetchedItems];
            newFetchedItems.splice(index, 1);
            setFetchedItems(newFetchedItems);
            setUrls(newUrls);
        } else {
            setUrls([""]);
        }
    };

    const checkUrlExistsMutation = useCheckUrlExistsMutation();
    const extractURL = (index: number) => () => {
        let url = urls[index];
        if (!url || url === "https://" || url === "http://") return;
        if (!/^https?:\/\//.test(url)) {
            //lacks http:// or https://
            url = "https://" + url;
            updateURL(index)(url);
        }
        checkUrlExistsMutation.mutate(url, {
            onSuccess: (res) => {
                tracker("Add Resource Form - Extraction Succeeded");

                const newFetchedItems = [...fetchedItems];
                newFetchedItems[index] = {
                    status: "success",
                    url: url,
                    data: {
                        id: selectedItems.length + 1 + index,
                        audiences: [],
                        documentTypes: ["page"],
                        options: {
                            embeddable: false,
                            feedbackProvided: false,
                        },
                        isUserProvided: true,
                        ...res.data,
                    },
                };
                setFetchedItems(newFetchedItems);
            },
            onError: () => {
                tracker("Add Resource Form - Extraction Failed");
                const newFetchedItems = [...fetchedItems];
                newFetchedItems[index] = {
                    status: "fail",
                    url: url,
                    data: {
                        url: url,
                        title: url,
                        description: "",
                        sourceUrl: new URL(url).hostname,
                        audiences: [],
                        documentTypes: ["page"],
                        options: {
                            embeddable: false,
                            feedbackProvided: false,
                        },
                        isUserProvided: true,
                    },
                };
                setFetchedItems(newFetchedItems);
            },
        });
    };
    const addResourcesToCollection = () => {
        tracker("Add Resource Form - Resources Added", {
            count: fetchedItems.length,
            resources: fetchedItems,
        });
        const uniqueByItems = [
            ...new Map(
                fetchedItems.map((item) => [item["url"], item]),
            ).values(),
        ];
        const filteredItems = uniqueByItems.filter(
            (value) => !selectedItems.find((item) => item?.url === value?.url),
        );
        filteredItems.forEach((el) => el.data && addItem(el.data));
        hideForm();
    };
    const checkUrlExist = (url: string, index: number) =>
        Boolean(
            fetchedItems[index]?.url === url &&
                fetchedItems[index]?.status === "fail",
        );

    return (
        <div className={container}>
            <div>
                <Typography
                    variant="text-size-xSmall-regular"
                    color="$text-colors-color-text-dark.main"
                >
                    <Translate text="Insert url" />
                </Typography>
            </div>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 10,
                    marginBottom: 10,
                }}
            >
                {urls.map((url, index) => (
                    <URLInputField
                        key={index}
                        value={url}
                        error={checkUrlExist(url, index)}
                        onChange={(e) => updateURL(index)(e.target.value)}
                        onBlur={extractURL(index)}
                        clearHandler={
                            url || urls.length > 1 ? clearURL(index) : undefined
                        }
                    />
                ))}
            </div>
            <Button
                style={{
                    alignSelf: "flex-start",
                    textDecoration: "underline",
                    padding: 5,
                    fontSize: "12px",
                    fontStyle: "normal",
                    fontWeight: 500,
                    lineHeight: "1rem",
                }}
                onClick={addNewURL}
            >
                <FontAwesomeIcon
                    icon={faPlus}
                    fontSize={12}
                    style={{ marginRight: 5 }}
                />
                <Translate text="Add more" />
            </Button>
            <div className={actionsContainer}>
                <Button
                    variant="contained"
                    style={{
                        padding: 7,
                        paddingLeft: 20,
                        paddingRight: 20,
                        fontSize: "12px",
                        lineHeight: "1rem",
                    }}
                    onClick={addResourcesToCollection}
                    disabled={fetchedItems.some(
                        (el) =>
                            !(el.status === "success" || el.status === "fail"),
                    )}
                >
                    <Translate text="Add resources" />
                </Button>
            </div>
        </div>
    );
};

export default AddResourcesForm;
