import { useState } from "react";
import dayjs from "dayjs";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Chip, Skeleton, Typography } from "@mui/material";

import * as styles from "./CollectionCard.module.scss";
import { Collection, Tag } from "types";
import { isAppMode } from "utils/newtools";

import CollectionThreeDotContextMenu from "components/ContextMenu/CollectionThreeDotContextMenu";
import NotificationIcon from "components/NotificationIcon/NotificationIcon";
import Translate from "components/Translate/Translate";

export const CollectionCardSkeleton = () => {
    return (
        <Skeleton
            variant="rectangular"
            height={122}
            style={{ borderRadius: "15px" }}
        />
    );
};

interface Props {
    collection: Collection;
    clickHandler: () => void;
    disableContextMenu?: boolean;
}

const CollectionCard = ({
    collection,
    clickHandler,
    disableContextMenu,
}: Props) => {
    const hasAnyResultGone = collection.numberOfGoneItems > 0;
    const [isHovered, setIsHovered] = useState(false);
    const mouseOverHandler = () => {
        setIsHovered(true);
    };
    const mouseLeaveHandler = () => {
        setIsHovered(false);
    };
    return (
        <div
            className={styles.container}
            onMouseOver={
                isAppMode && !disableContextMenu ? mouseOverHandler : undefined
            }
            onMouseLeave={
                isAppMode && !disableContextMenu ? mouseLeaveHandler : undefined
            }
        >
            <div className={styles.header}>
                <div className={styles.notificationAndDate}>
                    {hasAnyResultGone && (
                        <NotificationIcon
                            tooltip={{
                                title: "Missing resource",
                                description:
                                    "One or more resources are missing from the collection. Please review the collection.",
                            }}
                        />
                    )}
                    <Typography
                        variant="text-size-xSmall-regular"
                        color="$text-colors-color-text-light-gray.main"
                    >
                        <Translate text="Date modified" />
                        {": "}
                        {dayjs(collection.lastModifiedDate).format("DD/MM/YY")}
                    </Typography>
                </div>
                {isAppMode && !disableContextMenu && (
                    <CollectionThreeDotContextMenu
                        collection={collection}
                        isHovered={isHovered}
                    />
                )}
            </div>
            <Typography
                variant="text-size-Base-bold"
                lineHeight={"20px"}
                color="$text-colors-color-text-blue.main"
                className={styles.title}
                onClick={clickHandler}
            >
                {collection.title}
            </Typography>
            <div className={styles.footer}>
                {collection.tags?.map((tag: Tag) => (
                    <Chip key={tag.id} label={tag.value} />
                ))}
                {collection.tags?.length > 0 && (
                    <FontAwesomeIcon
                        icon={faCircle}
                        className={styles.divider}
                    />
                )}
                <Typography
                    variant="text-size-xxSmall-medium"
                    color="$icon-colors-green-60.main"
                >
                    {collection.itemsCount}
                    {collection.itemsCount === 1 ? (
                        <Translate text=" resource" />
                    ) : (
                        <Translate text=" resources" />
                    )}
                </Typography>
            </div>
        </div>
    );
};

export default CollectionCard;
