import { useEffect, useMemo, useState } from "react";
import {
    faChevronLeft,
    faChevronRight,
    faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Theme, Typography, useMediaQuery } from "@mui/material";

import * as styles from "./ResultModal.module.scss";
import imageNewTab from "./image-new-tab.webp";
import { isVideoSource } from "./ResultModal.helpers";
import { useResultModalContext } from "contexts/ResultModalContext";
import { ResourceResultBase } from "types";
import { tracker } from "utils/analytics";
import { isSrcYouTube, formatYoutubeEmbedUrl } from "utils/oldhelpers";

import Loading from "components/Loading/Loading";
import Translate from "components/Translate/Translate";

/**
 * Check if the url is from youtube and format
 * or return the full url
 * @param {Object} activeUrlData
 * @returns String
 */
const determineSrcUrl = (activeUrlData: ResourceResultBase) => {
    const sourceURL = activeUrlData.embeddable
        ? activeUrlData.embedUrl || activeUrlData.clickUrl
        : activeUrlData.clickUrl || activeUrlData.embedUrl;
    if (!sourceURL) return;
    const urlObj = new URL(sourceURL);
    try {
        return isSrcYouTube(urlObj)
            ? formatYoutubeEmbedUrl(sourceURL)
            : sourceURL;
    } catch (e) {
        console.warn(e);
        return sourceURL;
    }
};

const OpenInNewTab = ({
    activeUrlData,
}: {
    activeUrlData: ResourceResultBase;
}) => {
    return (
        <div className={styles.activeUrlLink}>
            <div className={styles.wrapImageStyle}>
                <img src={imageNewTab} width={150} />
            </div>
            {(activeUrlData?.clickUrl || activeUrlData?.embedUrl) && (
                <a
                    href={
                        activeUrlData?.clickUrl ||
                        (activeUrlData?.embedUrl as string)
                    }
                    target="_blank"
                    rel="noreferrer noopener"
                    data-testid="link"
                    onClick={() =>
                        tracker("Opened link in new tab", {
                            clickUrl:
                                activeUrlData?.clickUrl ||
                                activeUrlData?.embedUrl,
                        })
                    }
                >
                    <Translate text="Open in new tab" />
                </a>
            )}
            <div className={styles.wrapTextStyle}>
                <p className={styles.textStyle}>
                    <Translate text="View this item on the page." />
                </p>
                <p className={styles.textStyle}>
                    <Translate text="You can " />
                    {(activeUrlData?.clickUrl || activeUrlData?.embedUrl) && (
                        <a
                            href={
                                activeUrlData?.clickUrl ||
                                (activeUrlData?.embedUrl as string)
                            }
                            target="_blank"
                            rel="noreferrer"
                        >
                            <Translate text="open the page in a new tab " />
                        </a>
                    )}
                    <Translate text="and return here after reading a text or watching a video." />
                </p>
            </div>
        </div>
    );
};
type Stats = {
    index: number;
    total: number;
};
const NextPreviousButtonGroup = ({
    nextResult,
    previousResult,
    stats,
}: {
    nextResult: () => void;
    previousResult: () => void;
    stats: Stats;
}) => (
    <div className={styles.nextPreviousContainer}>
        <Button
            variant="outlined"
            disabled={stats.index === 1}
            className={styles.nextPreviousButton}
            onClick={previousResult}
        >
            <FontAwesomeIcon icon={faChevronLeft} />
        </Button>
        <Typography
            variant="text-size-Base"
            color="$text-colors-color-text-gray.main"
            style={{ width: "30px", textAlign: "center" }}
        >
            {stats.index}/{stats.total}
        </Typography>
        <Button
            variant="outlined"
            disabled={stats.index === stats.total}
            className={styles.nextPreviousButton}
            onClick={nextResult}
        >
            <FontAwesomeIcon icon={faChevronRight} />
        </Button>
    </div>
);

export interface ResultModalProps {
    results: ResourceResultBase[];
    initialIndex: number;
}

const ResultModal = ({ results, initialIndex }: ResultModalProps) => {
    const isMobileScreen = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down("md"),
    );
    const { resetResultModal } = useResultModalContext();
    const [index, setIndex] = useState(initialIndex);
    const selectedResult = results[index];
    const sourceIsVideo = useMemo(() => {
        return isVideoSource(selectedResult);
    }, [selectedResult]);
    const isIframeBlocked = results.length !== 0 && !selectedResult.embeddable;
    const [isLoadingIframe, setIsLoadingIframe] = useState<string | null>(
        selectedResult.embedUrl,
    );

    useEffect(() => {
        setIsLoadingIframe(selectedResult?.embedUrl);
    }, [selectedResult]);

    const previousResult = () => {
        if (index <= 0) return;
        tracker("Openlink previous result");
        setIndex(index - 1);
        setIsLoadingIframe(null);
    };
    const nextResult = () => {
        if (index === results.length - 1) return;
        tracker("Openlink next result");
        setIndex(index + 1);
        setIsLoadingIframe(null);
    };

    const stats = {
        index: index + 1,
        total: results.length,
    };
    return (
        <div className={styles.shadedBackground} onClick={resetResultModal}>
            <div
                className={styles.iframeContainer}
                onClick={(e) => e.stopPropagation()}
            >
                <div className={styles.headerStyle} data-video={sourceIsVideo}>
                    {!isMobileScreen && (
                        <Typography
                            variant="text-size-Medium"
                            color="$text-colors-color-text-dark.main"
                            className={styles.headerTitle}
                        >
                            {selectedResult.title}
                        </Typography>
                    )}
                    <div
                        className={styles.headerButtons}
                        style={{
                            justifyContent:
                                stats.total !== 1
                                    ? "space-between"
                                    : "flex-end",
                        }}
                    >
                        {stats.total !== 1 && (
                            <NextPreviousButtonGroup
                                {...{ nextResult, previousResult, stats }}
                            />
                        )}
                        <div
                            className={styles.closeButton}
                            onClick={resetResultModal}
                        >
                            <FontAwesomeIcon icon={faXmark} fontSize={15} />
                        </div>
                    </div>
                </div>
                {isIframeBlocked === undefined ? (
                    <Loading />
                ) : isIframeBlocked ? (
                    <OpenInNewTab activeUrlData={selectedResult} />
                ) : (
                    isLoadingIframe && (
                        <iframe
                            data-testid="result-modal-iframe"
                            className={styles.iframe}
                            src={
                                isLoadingIframe &&
                                determineSrcUrl(selectedResult)
                            }
                            title="modal iframe"
                            allowFullScreen={true}
                            data-video={sourceIsVideo}
                        ></iframe>
                    )
                )}
            </div>
        </div>
    );
};

export default ResultModal;
