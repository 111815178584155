import { useContext, useState } from "react";
import { Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";

import * as styles from "./MyCollections.module.scss";
import { useAppContext } from "contexts/AppContext";
import { TranslatableText, translate } from "components/Translate/Translate";
import { useItemsInCollectionQuery } from "hooks/api/collections";
import {
    useAllFoldersEnhancedQuery,
    findItemsWithIds,
} from "hooks/api/folders";
import useScreenSizes from "hooks/useScreenSizes";
import { Collection, CollectionItem, Folder, Workspace } from "types";
import { tracker } from "utils/analytics";

import CollectionsExplorerPanel from "components/CollectionsExplorerPanel/CollectionsExplorerPanel";
import MobileCollectionsExplorer from "components/CollectionsExplorerPanel/MobileCollectionsExplorer";
import CollectionViewer from "components/CollectionViewer/CollectionViewer";
import FolderOrWorkspaceViewer from "components/FolderOrWorkspaceViewer/FolderOrWorkspaceViewer";
import CollectionNavBar from "components/NavBars/CollectionNavBar";
import { CollectionContext } from "features/CollectionEditor/state/CollectionContext";

const AddFromMyCollections = () => {
    const { isDesktopScreen, isMobileScreen } = useScreenSizes();
    const {
        config: { language },
    } = useAppContext();
    const { selectedItems, addItem, removeItem } =
        useContext(CollectionContext);
    const [navigationIds, setNavigationIds] = useState<Array<number[]>>([]);

    const { data: allRootFolders } = useAllFoldersEnhancedQuery();
    const selectedFoldersAndCollections = findItemsWithIds(
        allRootFolders,
        navigationIds.at(-1) ?? [],
    );
    const selectedFirstChild = selectedFoldersAndCollections[1];
    const selectedFolderOrCollection = selectedFoldersAndCollections.at(-1) as
        | Workspace
        | Folder
        | Collection
        | undefined;
    const pageTitle = selectedFolderOrCollection?.title
        ? selectedFolderOrCollection.type === "WORKSPACE"
            ? translate(
                  language,
                  selectedFolderOrCollection.title as TranslatableText,
              )
            : selectedFolderOrCollection.title
        : translate(language, "My collections");

    // Folder items
    const folderItems =
        selectedFolderOrCollection && "children" in selectedFolderOrCollection
            ? selectedFolderOrCollection.children
            : allRootFolders;
    const sortedFolderItems = selectedFirstChild
        ? selectedFolderOrCollection?.sortBy === "TITLE"
            ? folderItems.sort((a, b) =>
                  a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1,
              )
            : folderItems.sort((a, b) =>
                  a.lastModifiedDate < b.lastModifiedDate ? 1 : -1,
              )
        : folderItems;

    // Collection items
    const { data: collectionItems, isFetched: collectionItemsIsFetched } =
        useItemsInCollectionQuery(
            selectedFolderOrCollection &&
                selectedFolderOrCollection.type === "COLLECTION"
                ? selectedFolderOrCollection.id
                : undefined,
        );

    const folderOrWorkspaceClickHandler = (
        folderOrWorkspace: Folder | Workspace,
        pathString?: string,
    ) => {
        if (!pathString) return;
        tracker("Folder clicked", folderOrWorkspace);
        const newNavIds = pathString.split("/").map((el) => parseFloat(el));
        setNavigationIds([...navigationIds, newNavIds]);
    };
    const folderOrWorkspaceViewClickHandler = (
        folderOrWorkspace: Folder | Workspace,
    ) => {
        const newPathString = [
            ...(navigationIds?.at(-1) ?? []),
            folderOrWorkspace.id,
        ].join("/");
        folderOrWorkspaceClickHandler(folderOrWorkspace, newPathString);
    };
    const collectionClickHandler = (
        collection: Collection,
        pathString?: string,
    ) => {
        if (!pathString) return;
        const pathParts = pathString.split("/");
        pathParts.splice(0, 1);
        tracker("AddFromMyCollection - Collection clicked", collection);
        setNavigationIds([
            ...navigationIds,
            pathParts.map((el) => parseFloat(el)),
        ]);
    };
    const collectionViewClickHandler = (collection: Collection) => {
        const newNavIds = [
            "0", // function above trims off the first value, so here is a dummy one
            ...(navigationIds.at(-1) ?? []),
            collection.id,
        ].join("/");
        collectionClickHandler(collection, newNavIds);
    };
    const addItemToCollection = (result: CollectionItem) => {
        addItem && addItem(result);
    };
    const removeItemFromCollection = (result: CollectionItem) => {
        removeItem && removeItem(result);
    };
    const backHandler = () => {
        setNavigationIds(navigationIds.slice(0, -1));
    };
    const homeHandler = () => {
        setNavigationIds([]);
    };
    return (
        <>
            <CollectionNavBar
                customBackbuttonOnClick={backHandler}
                customHomebuttonOnClick={homeHandler}
            />
            {!isDesktopScreen && (
                <MobileCollectionsExplorer
                    items={allRootFolders}
                    folderOrWorkspaceClickHandler={
                        folderOrWorkspaceClickHandler
                    }
                    collectionClickHandler={collectionClickHandler}
                    pathRoot={""}
                    selectedIds={navigationIds.at(-1)}
                    useOwnSubheaderContainer
                    inDialogBox
                />
            )}
            <div
                style={{
                    padding: isMobileScreen ? "20px 10px" : "20px 40px",
                    marginBottom: 10,
                    width: "100%",
                }}
            >
                <Grid container spacing={"20px"}>
                    {isDesktopScreen && (
                        <Grid lg={3} xs={0}>
                            <CollectionsExplorerPanel
                                items={allRootFolders}
                                selectedIds={navigationIds.at(-1)}
                                folderOrWorkspaceClickHandler={
                                    folderOrWorkspaceClickHandler
                                }
                                collectionClickHandler={collectionClickHandler}
                            />
                        </Grid>
                    )}
                    <Grid lg={9} xs={12}>
                        <div className={styles.title}>
                            <Typography
                                variant="text-size-Medium"
                                color="$text-colors-color-text-dark.main"
                            >
                                {pageTitle}
                            </Typography>
                        </div>
                        {selectedFolderOrCollection &&
                        selectedFolderOrCollection.type === "COLLECTION" ? (
                            <CollectionViewer
                                items={collectionItems ?? []}
                                isFetched={collectionItemsIsFetched}
                                viewAs={"LIST"} // List view to add items to the collection
                                selectedItems={selectedItems}
                                addItemHandler={addItemToCollection}
                                removeItemHandler={removeItemFromCollection}
                                collectionResultsModalEnabled={false} // While adding resources ALWAYS open in new tab
                            />
                        ) : (
                            <FolderOrWorkspaceViewer
                                items={sortedFolderItems}
                                isFetched={
                                    selectedFirstChild &&
                                    "hasBeenFetched" in selectedFirstChild
                                        ? !!selectedFirstChild.hasBeenFetched
                                        : true
                                }
                                viewAs={
                                    selectedFolderOrCollection?.view ?? "GRID"
                                }
                                sortBy={
                                    selectedFolderOrCollection?.sortBy ??
                                    "LAST_MODIFIED_DATE"
                                }
                                folderOrWorkspaceClickHandler={
                                    folderOrWorkspaceViewClickHandler
                                }
                                collectionClickHandler={
                                    collectionViewClickHandler
                                }
                                disableContextMenu
                            />
                        )}
                    </Grid>
                </Grid>
            </div>
        </>
    );
};

export default AddFromMyCollections;
