import { useMutation } from "@tanstack/react-query";

import { axiosAPI } from "hooks/api/axiosAPI";
import { ChatData } from "../types";

type ChatFeedbackParams = {
    userId: string;
    botId: string;
    sessionId: string;
    accuracy: number;
    quality: number;
    comment: string;
    conversation: ChatData[];
};

export const useFeedbackMutation = () => {
    return useMutation(async (params: ChatFeedbackParams) => {
        return await axiosAPI
            .getInstance()
            ?.post<string>(`/content/tutor/feedback`, params);
    });
};
