@use "../../../../../../../styles/variables.module";

.selectReason {
    box-sizing: border-box;
    height: 40px;
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid variables.$border-colors-color-border-primary;
    border-radius: 5px;
    background: white;
    position: relative;
    top: 10px;
    cursor: pointer;
}

.mandatory {
    color: variables.$message-colors-color-message-danger;
}
