// ⚠⚠⚠ Dont edit directly, always generate from https://wizenozeglobal.sharepoint.com/:x:/g/EWOOK53PyApPo_OJS8a_e2gBxcSKrOCusYIZmXDLkN0kdg?rtime=AIUTCmbn20g and use the excel-to-json 3rd party addon to create json for all translations
export const translations = [
    { en: "Current password", nl: "Huidige wachtwoord" },
    { en: "My profile", nl: "Mijn profiel" },
    { en: "General", nl: "Algemeen" },
    { en: "Curation", nl: "Curatie" },
    { en: "Curricula", nl: "Curriculums" },
    {
        en: "Your changes have been saved.",
        nl: "Je wijzigingen zijn opgeslagen.",
    },
    {
        en: "Your changes have not been saved.",
        nl: "Je wijzigingen zijn niet opgeslagen.",
    },
    {
        en: "Your password has been changed.",
        nl: "Je wachtwoord is gewijzigd.",
    },
    { en: "Changes are not saved", nl: "Veranderingen worden niet opgeslagen" },
    {
        en: "The changes you made will be lost if you navigate away from this page. Are you sure you want to discard the changes?",
        nl: "De veranderingen die je hebt gemaakt worden niet opgeslagen als je deze pagina verlaat. Weet je zeker dat je de veranderingen wil verwijderen?",
    },
    { en: " resource", nl: " bron" },
    { en: " resources", nl: " bronnen" },
    { en: " min", nl: " min" },
    { en: "Cancel", nl: "Afbreken" },
    { en: "Keep editing", nl: "Terug naar bewerken" },
    {
        en: "Only resources available in the Wizenoze viewer",
        nl: "Alleen bronnen beschikbaar in de Wizenoze viewer",
    },
    { en: "Collection is not saved", nl: "Collectie is niet opgeslagen" },
    { en: "Collection has been saved.", nl: "Collectie is opgeslagen." },
    { en: "Collection has been deleted.", nl: "Collectie is verwijderd." },
    { en: "Date created", nl: "Creatiedatum" },
    { en: "Date modified", nl: "Datum laatste aanpassing" },
    { en: "Deselect the resource.", nl: "Deselecteer de bron." },
    { en: "Collection is empty", nl: "De collectie is leeg" },
    { en: "Filter texts by reading level", nl: "Filter teksten op leesniveau" },
    {
        en: "Use labels (tags) to categorise and organise your collections.",
        nl: "Gebruik labels om je collecties te organiseren en categorieën aan te brengen.",
    },
    {
        en: "Failed to create collection.",
        nl: "Het maken van de collectie is mislukt.",
    },
    { en: "Content cannot be loaded", nl: "Inhoud kan niet worden geladen" },
    { en: "Embeddable", nl: "Integreerbaar" },
    { en: "Yes, leave the page", nl: "Ja, verlaat de pagina" },
    { en: "Add your first collection", nl: "Je eerste collectie toevoegen" },
    {
        en: "You don't have any collections yet. Once you create a collection, it will appear here.",
        nl: "Je hebt nog geen collecties. Als je eenmaal een collectie hebt gemaakt zal deze hier verschijnen.",
    },
    {
        en: "You can't share an empty collection. Please add resources to the collection before sharing.",
        nl: "Je kan een lege collectie niet delen. Graag bronnen toevoegen aan de collectie voordat je deze deelt.",
    },
    {
        en: "You need to save the collection before sharing. Do you want to save the collection?",
        nl: "Je moet de collectie opslaan voordat je kunt delen. Wil je de collectie opslaan?",
    },
    {
        en: "Your work will be lost if you navigate away from this page. Are you sure you want to discard the changes?",
        nl: "Je werk zal verloren gaan als je van deze pagina weggaat. Weet je zeker dat je deze collectie wilt verwijderen?",
    },
    { en: "Labels/Tags", nl: "Labels/Etiketten" },
    { en: "Length ", nl: "Lengte " },
    { en: "Load more", nl: "Meer laden" },
    { en: "My collections", nl: "Mijn collecties" },
    {
        en: "At least one content type must be selected.",
        nl: "Minstens 1 contentsoort moet geselecteerd zijn.",
    },
    { en: "OK", nl: "OK" },
    { en: "Subtitles", nl: "Ondertiteling" },
    {
        en: "Open resources within the Wizenoze viewer",
        nl: "Open bronnen binnen de Wizenoze viewer",
    },
    { en: "Appearance", nl: "Opmaak" },
    { en: "Save", nl: "Opslaan" },
    { en: "Please try again later.", nl: "Probeer het later nog een keer." },
    { en: "Select resources to share.", nl: "Selecteer bronnen om te delen." },
    { en: "Add", nl: "Toevoegen" },
    { en: "Content type", nl: "Type inhoud" },
    { en: "Delete the collection", nl: "Verwijder de collectie" },
    { en: "Delete", nl: "Verwijderen" },
    { en: "Videos", nl: "Video's" },
    { en: "Insert collection title", nl: "Voer de titel van de collectie in" },
    { en: "We found ", nl: "We hebben " },
    {
        en: "Are you sure you want to delete the collection? You can't undo this action.",
        nl: "Weet je zeker dat je de collectie wil verwijderen? Dit kan niet ongedaan worden gemaakt.",
    },
    { en: "Wizenoze viewer", nl: "Wizenoze viewer" },
    { en: "Search Wizenoze", nl: "Zoek met Wizenoze" },
    { en: "New", nl: "Nieuw" },
    { en: "Folder", nl: "Map" },
    { en: "folder", nl: "map" },
    { en: "Folders", nl: "Mappen" },
    { en: "Collection", nl: "Collectie" },
    { en: "Collections", nl: "Collecties" },
    { en: "View as grid", nl: "Bekijk als raster" },
    { en: "Grid", nl: "Raster" },
    { en: "View as list", nl: "Bekijk als lijst" },
    { en: "List", nl: "Lijst" },
    { en: "Sort by type", nl: "Sorteer op type" },
    { en: "Type", nl: "Type" },
    { en: "Sort by date", nl: "Sorteer op datum" },
    { en: "Date", nl: "Datum" },
    { en: "Name", nl: "Naam" },
    { en: "New folder", nl: "Nieuwe map" },
    { en: "Move to", nl: "Verplaats naar" },
    { en: "Duplicate", nl: "Dupliceer" },
    { en: "Rename", nl: "Hernoem" },
    { en: "Delete the folder", nl: "Verwijder de map" },
    {
        en: "Are you sure you want to delete the folder? All collections and sub-folders will be deleted.",
        nl: "Weet je zeker dat je deze map wilt verwijderen? Alle collecties en onderliggende mappen worden verwijderd.",
    },
    {
        en: "You can’t undo this action.",
        nl: "Je kan deze actie niet ongedaan maken",
    },
    { en: "The folder is currently empty.", nl: "De folder is leeg" },
    { en: "The collection is currently empty.", nl: "De collectie is leeg" },
    {
        en: "Are you sure you want to delete the resource?\nYou can’t undo this action.",
        nl: "Ben je zeker dat je de bron wilt verwijderen?\nDeze actie kan niet ongedaan worden gemaakt.",
    },
    { en: "Resources have been added.", nl: "Bronnen zijn toegevoegd." },
    {
        en: "The analysis of the resource will be ready shortly.",
        nl: "De analyse van de bron zal binnenkort gereed zijn.",
    },
    { en: "The resource has been deleted.", nl: "De bron is verwijderd" },
    {
        en: "Disclaimer: User-uploaded content",
        nl: "Disclaimer: Door gebruiker toegevoegd materiaal",
    },
    { en: "Insert url", nl: "Invoegen URL" },
    { en: "Add more", nl: "Meer toevoegen" },
    { en: "My resources", nl: "Mijn bronnen" },
    { en: "Back to my resources", nl: "Terug naar mijn bronnen" },
    { en: "Delete the resource", nl: "Verwijder bron" },
    { en: "Add resources", nl: "Voeg bron toe" },
    {
        en: "Add your resources to the collection.",
        nl: "Voeg je bron toe aan je collectie.",
    },
    { en: "Add more resources", nl: "Voeg meer bronnen toe" },
    {
        en: "We can’t reach the resource.",
        nl: "We kunnen de opgegeven bron niet bereiken.",
    },
    {
        en: "We can't reach the provided URL. The URL is incorrect or it has restricted access.",
        nl: "We kunnen de opgegeven URL niet bereiken. De URL is onjuist of heeft beperkte toegang.",
    },
    {
        en: "Wizenoze does not endorse, verify, or assume responsibility for the accuracy, legality, or appropriateness of user-uploaded content.",
        nl: "Wizenoze keurt, verifieert, of aanvaardt geen verantwoordelijkheid voor de nauwkeurigheid, wettigheid, of geschiktheid van door gebruikers geüploade inhoud.",
    },
    { en: "View curriculum", nl: "Bekijk curriculum" },
    { en: "View all collections", nl: "Bekijk alle collecties" },
    {
        en: "Discover and share trusted educational resources from the internet with your learners",
        nl: "Ontdek en deel educatieve internetbronnen met je leerlingen",
    },
    {
        en: "Enable this filter to see only resources that can be opened within the Wizenoze viewer.",
        nl: "Activeer dit filter om alleen bronnen te zien die kunnen worden geopend binnen de Wizenoze viewer.",
    },
    { en: " or", nl: " of" },
    { en: " tabs", nl: " tabbladen" },
    { en: "your account contact us at", nl: "?  Neem dan contact op met" },
    { en: "0 results for", nl: "0 resultaten gevonden voor" },
    { en: "Save changes", nl: "Aanpassingen opslaan" },
    { en: "Number of resources (1-5)", nl: "Aantal bronnen (1-5)" },
    { en: "Total pages", nl: "Aantal pagina's" },
    { en: "If you wish to", nl: "Account" },
    { en: "Last name", nl: "Achternaam" },
    {
        en: "Image may be subject to copyright",
        nl: "Afbeelding is mogelijk auteursrechtelijk beschermd",
    },
    { en: "Images", nl: "Afbeeldingen" },
    { en: "Terms & Conditions", nl: "Algemene voorwaarden" },
    { en: "All categories", nl: "Alle onderwerpen" },
    { en: "Deselect all", nl: "Alles deselecteren" },
    { en: "Select All", nl: "Alles selecteren" },
    { en: "Select all", nl: "Alles selecteren" },
    {
        en: "When sharing only selected resources, the automatic substitution of unavailable resources will be turned off.",
        nl: "Als alleen geselecteerde bronnen worden gedeeld, wordt de automatische vervanging van niet-beschikbare bronnen uitgeschakeld.",
    },
    {
        en: "If you still encounter the same issue, our dedicated support team  is here to assist you.",
        nl: "Als je nog steeds dezelfde problemen ondervindt, staat ons support team voor je klaar.",
    },
    { en: "Other learning objectives", nl: "Andere leerdoelen" },
    { en: "View image", nl: "Bekijk afbeelding" },
    { en: "Check for results in", nl: "Bekijk de resultaten bij" },
    {
        en: "View this item on the page.",
        nl: "Bekijk dit item op de internetpagina.",
    },
    { en: "Confirm new password", nl: "Bevestig nieuw wachtwoord" },
    { en: "Visit page", nl: "Bezoek pagina" },
    { en: "Width", nl: "Breedte" },
    { en: "Open resources in the viewer", nl: "Bronnen in de viewer openen" },
    { en: "Support", nl: "Contact opnemen" },
    {
        en: "Check your search for typos",
        nl: "Controleer of alle woorden correct zijn gespeld",
    },
    { en: "Curriculum", nl: "Curriculum" },
    { en: "Curriculum selector", nl: "Curriculumkiezer" },
    { en: "Curriculum Selector", nl: "Curriculumkiezer" },
    { en: "Close curriculum selector", nl: "Curriculumkiezer sluiten" },
    {
        en: "Share a link or embed resources",
        nl: "Deel een link of sluit de internetbron in",
    },
    {
        en: "Share a link or embed the selected topic or learning outcome resources with your learners. Your learners will receive the resources in a carefully designed experience to allow them to focus on what is important.",
        nl: "Deel eenvoudig een link of kies ervoor om de internetbronnen rechtstreeks in te sluiten in je electronische leeromgeving. Je leerlingen openen de bron in de leerlingomgeving zonder dat ze hoeven in te loggen.",
    },
    { en: "Share", nl: "Delen" },
    { en: "Embed size", nl: "Embed grootte" },
    {
        en: "and return here after reading a text or watching a video.",
        nl: "en keer hier terug voor andere resultaten. ",
    },
    { en: "Feedback", nl: "Feedback" },
    { en: "Feedback provided", nl: "Feedback gegeven" },
    { en: "Filters", nl: "Filters" },
    { en: "Give feedback", nl: "Geef feedback" },
    { en: "Embed code copied to clipboard", nl: "Gekopieerd" },
    {
        en: "Oops, we couldn't open the page in this window",
        nl: "Helaas kunnen wij deze pagina niet in dit venster openen",
    },
    { en: "Use more generic term", nl: "Herformuleer je zoekopdracht" },
    {
        en: "We are sorry! It seems that no resources match your current filter combination.",
        nl: "Het lijkt erop dat er geen bronnen overeenkomen met je huidige filtercombinatie.",
    },
    {
        en: "Failed to retrieve resource",
        nl: "Het opgegeven resource id levert geen resultaten op",
    },
    { en: "Height", nl: "Hoogte" },
    { en: "Embed", nl: "Insluiten" },
    { en: "Settings", nl: "Instellingen" },
    {
        en: "Curated content by educators mapped to six national curricula.",
        nl: "Internetbronnen geselecteerd door onderwijsprofessionals.",
    },
    { en: "Choose", nl: "Kies" },
    { en: "Select your curriculum", nl: "Kies je curriculum" },
    { en: "Choose your country", nl: "Kies je land" },
    { en: "Choose your reading level", nl: "Kies je leesniveau" },
    { en: "Select your grade", nl: "Kies je niveau" },
    { en: "Choose page", nl: "Kies pagina" },
    { en: "Copy link", nl: "Kopiëren" },
    { en: "Copy to clipboard", nl: "Kopiëren" },
    { en: "Country", nl: "Land" },
    { en: "Learning objectives", nl: "Leerdoelen" },
    { en: "Subject", nl: "Leergebied" },
    { en: "Read more", nl: "Lees verder" },
    { en: "Reading Level", nl: "Leesniveau" },
    { en: "Share a link", nl: "Link delen" },
    { en: "Link copied to clipboard", nl: "Link gekopieerd" },
    { en: "More results from", nl: "Meer resultaten van" },
    { en: "My curriculum", nl: "Mijn curriculum" },
    { en: "My grade or level", nl: "Mijn niveau" },
    { en: "My school or Institution", nl: "Mijn school of Instelling" },
    {
        en: "Easily navigate through the familiar structure of your curriculum. Or, use the search option to find curated content for any learning objective.",
        nl: "Navigeer door de verschillende curricula of gebruik de zoekbalk.",
    },
    { en: "Browse or search", nl: "Navigeer of zoek" },
    { en: "Navigate Back", nl: "Navigeer terug" },
    { en: "New password", nl: "Nieuw wachtwoord" },
    { en: "Grade", nl: "Niveau" },
    { en: "Reading Levels", nl: "Niveaus lezen" },
    { en: "Discover, review and share", nl: "Ontdek, bekijk en deel" },
    {
        en: "open the page in a new tab ",
        nl: "Open deze pagina in een nieuw tabblad ",
    },
    { en: "Open in new tab", nl: "Open in een nieuw tabblad" },
    { en: "Save as defaults", nl: "Opslaan als standaardinstelling" },
    { en: "Options", nl: "Opties" },
    {
        en: "Consider adjusting your filters to expand your search.",
        nl: "Overweeg om je filters aan te passen om je zoekopdracht uit te breiden.",
    },
    { en: "Content cannot be loaded", nl: "Pagina kan niet geladen worden" },
    { en: "Close popup window", nl: "Popup venster sluiten" },
    { en: "Privacy notice", nl: "Privacy" },
    { en: "Please try again later.", nl: "Probeer het later nog eens." },
    { en: "Try again", nl: "Probeer opnieuw" },
    { en: "results for", nl: "resultaten voor" },
    { en: "Select", nl: "Selecteer" },
    {
        en: "Select resources you want to share.",
        nl: "Selecteer bronnen die je wilt delen.",
    },
    { en: "Select Subjects", nl: "Selecteer Leergebied" },
    { en: "Select category", nl: "Selecteer onderwerp" },
    { en: "Select category in", nl: "Selecteer onderwerp in" },
    {
        en: "Social science and English language for selected curricula.",
        nl: "Sociale wetenschappen en Engelse taal voor geselecteerde curricula.",
    },
    {
        en: "Sorry we couldn’t find any learning objective matches for ",
        nl: "Sorry we konden geen leerdoelen vinden voor jouw zoekopdracht ",
    },
    { en: "Default settings", nl: "Standaard instellingen" },
    { en: "Search Help", nl: "Suggesties" },
    { en: "Language", nl: "Taal" },
    { en: "Pages", nl: "Tekst" },
    { en: "Back to search", nl: "Terug naar zoekscherm" },
    { en: "Apply", nl: "Toepassen" },
    { en: "Show More", nl: "Toon Meer" },
    { en: "Show Less", nl: "Toon Minder" },
    { en: "Show drafts", nl: "Toon proefversies" },
    { en: "Search", nl: "Typ jouw zoekterm" },
    { en: "Appearance", nl: "Uiterlijk" },
    { en: "Log out", nl: "Uitloggen" },
    {
        en: "Your password and confirmation password do not match.",
        nl: "Jouw wachtwoord en bevestigingswachtwoord komen niet overeen.",
    },
    { en: "Change password", nl: "Verander wachtwoord" },
    { en: "Clear all filters", nl: "Verwijder alle filters" },
    { en: "delete ", nl: "verwijderen" },
    { en: "Next learning objective", nl: "Volgende leerdoelen" },
    { en: "Next result", nl: "Volgende resultaat" },
    { en: "Preview in a new tab", nl: "Voorbeeld in een nieuw tabblad" },
    { en: "First name", nl: "Voornaam" },
    { en: "Previous result", nl: "Vorig resultaat" },
    { en: "Password", nl: "Wachtwoord" },
    { en: "Password update failed.", nl: "Wachtwoordupdate mislukt." },
    { en: "Web Pages", nl: "Webpagina's" },
    { en: "Display Options", nl: "Weergave Instellingen" },
    { en: "Clear", nl: "Wissen" },
    { en: "Clear all", nl: "Wissen" },
    {
        en: "STEM subjects for all curricula.",
        nl: "Zaakvakken voor verschillende curricula.",
    },
    { en: "Search icon", nl: "Zoekpictogram" },
    { en: "You can ", nl: "" },
    {
        en: "Create Collection from Selection",
        nl: "Maak een Collectie van deze Selectie",
    },
    { en: "FAQ", nl: "FAQ (in Engels)" },
    {
        en: "If enabled, all resources will be opened within the Wizenoze viewer.",
        nl: "Als deze instelling geactiveerd is worden alle bronnen geopend binnen de Wizenoze viewer.",
    },
    {
        en: "One or more resources are missing from the collection. Please review the collection.",
        nl: "Eén of meerdere bronnen ontbreken in de collectie. Bekijk alsjeblieft de collectie.",
    },
    { en: "Missing resource", nl: "Ontbrekende bron" },
    {
        en: "Currently, we can't reach the resource on this URL. The resource has been removed from your shared collection.",
        nl: "Op dit moment kunnen we de bron achter deze URL niet bereiken. De bron is verwijderd uit je gedeelde collectie.",
    },
    { en: "Get started", nl: "Aan de slag" },
    {
        en: "Available curricula for free",
        nl: "Aan de slag met deze curricula",
    },
    { en: "Terms & Conditions", nl: "Algemene voorwaarden" },
    {
        en: "If you have any questions please contact us at",
        nl: "Als je vragen hebt, neem dan contact met ons op via",
    },
    {
        en: "if your curriculum is not available on this list.",
        nl: "als jouw curriculum ontbreekt in de lijst.",
    },
    { en: "Review and customise", nl: "Bekijk en kies" },
    {
        en: "Trustworthy, personalised and engaging",
        nl: "Betrouwbaar, gepersonaliseerd en inspirerend",
    },
    {
        en: "Inspiring content carefully selected by educators and tailored to learners curriculum, language skills, age and ambitions. Engage your students with relevant educational resources from the internet and create barrier-free new learning opportunities.",
        nl: "Betrouwbare internetbronnen voor leerlingen van alle leeftijden. Afgestemd op je curriculum, niveau en leeftijd. Inspireer je leerlingen om op onderzoek uit te gaan.",
    },
    { en: "Processing", nl: "Bezig" },
    {
        en: "Password reset instructions have been mailed to you.",
        nl: "Check je email om je wachtwood te resetten. ",
    },
    { en: "Contact us", nl: "Contact met ons op" },
    {
        en: "Please check your inbox and follow the link in the email to confirm your email address. Don't forget to check your spam folder if you don't see the email in your inbox.",
        nl: "Controleer je inbox en volg de link in de e-mail om je e-mailadres te bevestigen. Vergeet niet om je spamfolder te controleren als je de e-mail niet in je inbox ziet.",
    },
    {
        en: "Sorry, we don't recognise this email and password. Please ensure the email address you entered is correct or verified by following the instructions in the confirmation email.",
        nl: "Sorry, we herkennen deze combinatie van het e-mailadres en wachtwoord niet. Zorg dat het e-mailadres correct is. Mogelijk moet je je e-mailadres nog verifieren door de instructies in de bevestigingsmail te volgen.",
    },
    {
        en: "That email address does not exist on our system. Please register.",
        nl: "De combinatie van e-mailadres en wachtwoord is niet geldig.",
    },
    {
        en: "The largest library worldwide of curated educational resources matched to your curriculum",
        nl: "De grootste bibliotheek van educatieve internetbronnen ontwikkeld door onderwijsprofessionals en passend bij het curriculum",
    },
    {
        en: "You completed the registration! You can now log in to Wizenoze.",
        nl: "De registratie is voltooid! Je kan nu inloggen op Wizenoze.",
    },
    {
        en: "The Wizenoze collection includes videos collected from Youtube. By creating an account you are agreeing to be bound by the",
        nl: "De Wizenoze-collectie bevat video's die zijn verzameld van YouTube. Door een account aan te maken, ga je ermee akkoord gebonden te zijn aan de",
    },
    {
        en: "Share resources instantly with your learners",
        nl: "Deel de bronnen direct met je leerlingen",
    },
    { en: "Share with your learners", nl: "Deel de bronnen met je leerlingen" },
    {
        en: "Share a link or embed content directly to your learning management system.",
        nl: "Deel eenvoudig een link of kies ervoor om de internetbronnen rechtstreeks in te sluiten in je electronische leeromgeving.",
    },
    { en: "Get started today", nl: "Direct aan de slag" },
    {
        en: "By creating an account you agree to the Wizenoze",
        nl: "Door een account aan te maken ga je akkoord met het Wizenoze",
    },
    {
        en: "Browse or search curated content matched to the learning outcomes of your curriculum.",
        nl: "Doorzoek en vind betrouwbare internetbronnen die aansluiten bij de leerdoelen van het curriculum.",
    },
    {
        en: "Unlock the tailored learning experience for your learners",
        nl: "Een gepersonaliseerde leerervaring voor jouw leerlingen",
    },
    {
        en: "All resources have a standard set of supporting information to help you and your learners to evaluate and select the most relevant resources.",
        nl: "Een korte titel, een omschrijving en een leesniveau helpen je om relevante bronnen te kiezen en te delen met je leerlingen.",
    },
    { en: "Email", nl: "Email" },
    {
        en: "Email address is not verified!",
        nl: "E-mail adres kan niet worden geverifieerd!",
    },
    {
        en: "That email address is already verified.",
        nl: "Dat e-mailadres is al geverifieerd.",
    },
    { en: "Email has been sent!", nl: "E-mail is verzonden!" },
    { en: "Missing email", nl: "Emailadres ontbreekt" },
    {
        en: "That email address is in use. If you've registered for another Wizenoze product, then please try logging in with that password.",
        nl: "Er bestaat al een account met het e-mailadres dat je hebt ingevuld. Je kunt met dit e-mailadres inloggen als bestaande klant.",
    },
    { en: "Get started for free", nl: "Gratis aan de slag" },
    { en: "No costs! Get started for free", nl: "Gratis aan de slag" },
    { en: "Already have an account?", nl: "Heb je al een account?" },
    { en: "Don’t have an account?", nl: "Heb je nog geen account?" },
    {
        en: "We could not verify your email address.",
        nl: "Helaas kunnen we je e-mail adres niet verifiëren.",
    },
    {
        en: "Highly engaging teaching and learning experience",
        nl: "Hoge toegankelijkheid",
    },
    { en: "Missing login credentials", nl: "Inloggegevens ontbreken" },
    { en: "Login", nl: "Inloggen" },
    {
        en: "Signup failed. Please retry or contact support@wizenoze.com",
        nl: "Inloggen niet gelukt. Probeer het nogmaals of neem contact op met support@wizenoze.com",
    },
    { en: "Personalised and engaging content", nl: "Inspirerende content" },
    {
        en: "Reclaiming the internet for education",
        nl: "Internet voor het onderwijs",
    },
    {
        en: "Tools and resources to support your teaching",
        nl: "Internetbibliotheek voor in de klas",
    },
    {
        en: "Curated resources by educators for learners",
        nl: "Internetbronnen geselecteerd door onderwijsprofessionals",
    },
    { en: "Enrich your teaching practice", nl: "Je les verrijken" },
    { en: "Your registration is complete!", nl: "Je registratie is voltooid!" },
    {
        en: "Could not load subscription for account ID",
        nl: "Kan geen abonnement vinden bij dit account ID",
    },
    { en: "Choose your password", nl: "Kies je wachtwoord" },
    {
        en: "Reading levels to support personalised learning",
        nl: "Leesniveaus voor een persoonlijke aanpak",
    },
    { en: "Create account", nl: "Maak een account aan" },
    { en: "Create free account", nl: "Maak gratis account aan" },
    { en: "Create a free account", nl: "Maak een gratis account aan" },
    { en: "Go to Login", nl: "Naar inloggen" },
    {
        en: "Contact us for an offer that meets your requirements. Our team of educational experts is on hand to answer your questions.",
        nl: "Neem contact met ons op voor een aanbod op maat. Ons team van educatieve experts denken graag met je mee.",
    },
    { en: "Please contact us at ", nl: "Neem contact op met " },
    {
        en: "Discover curated resources",
        nl: "Ontdek alle geselecteerde internetbronnen",
    },
    { en: "Join us today for free", nl: "Ontdek de bibliotheek" },
    { en: "Learn more about Wizenoze", nl: "Over Wizenoze" },
    {
        en: "Matched to the learning outcomes of your curriculum",
        nl: "Passend bij de leerdoelen van jouw curriculum",
    },
    { en: "Privacy notice", nl: "Privacy" },
    { en: "Privacy Policy", nl: "Privacy-beleid" },
    { en: "Easy to evaluate resources", nl: "Ruime keuze" },
    { en: "School name", nl: "School" },
    {
        en: "Personalise and improve the engagement of your educational content to the needs and abilities of individual learners.",
        nl: "Sluit aan op de belevingswereld van je leerlingen met digitale teksten en video's die passen bij verschillende leesniveaus en leerbehoeftes",
    },
    { en: "60+ standard and custom curricula", nl: "Standaard curricula" },
    { en: "Back to login page", nl: "Terug naar login pagina" },
    { en: "Please enter your email address", nl: "Voer hier je emailadres in" },
    {
        en: "Please specify an email address and password",
        nl: "Voer je emailadres en wachtwoord in",
    },
    { en: "Enter your email", nl: "Voer je e-mailadres in" },
    { en: "Enter your password", nl: "Voer je wachtwoord in" },
    { en: " for further assistance.", nl: "voor hulp." },
    {
        en: "Advanced tools and features for enhanced teaching and learning journeys",
        nl: "Vooruitstrevende technologie om jouw onderwijs te versterken.",
    },
    {
        en: "Enter your educational institution or school",
        nl: "Vul je onderwijsinstelling of school in",
    },
    { en: "Password Reset", nl: "Wachtwoord vergeten" },
    { en: "Forgot password?", nl: "Wachtwoord vergeten?" },
    { en: "Reset", nl: "Wijzigen" },
    {
        en: "I want to receive the Wizenoze newsletter",
        nl: "Ik wil de Wizenoze nieuwsbrief ontvangen",
    },
    {
        en: "Using state-of-the-art machine learning technology, Wizenoze developed a new readability standard: the Wizenoze Readability Index©. Based on this index, the reading level of a text is presented on a scale of five reading levels.",
        nl: "Wizenoze heeft aan de hand van de nieuwste technologie een eigen leesbaarheidsniveau ontwikkeld:  de Wizenoze Leesbaarheids Index. Voor iedere pagina in onze collectie zie je het leesniveau. Zo kun je als leerkracht rekening houden met de verschillende leerbehoeftes en niveaus van je leerlingen.",
    },
    {
        en: "Wizenoze maintains a collection of 60+ standard and custom curriculums and vocational courses. We can curate content and deliver custom curriculums to meet your requirements and desired learning outcomes.",
        nl: "Wizenoze onderhoudt een mooie collectie van standaard curricula. Naast deze standaarden, bouwen we ook aan curricula op maat. We horen graag wat jouw wensen zijn en bekijken samen de mogelijkheden.",
    },
    { en: "YouTube Terms of Service", nl: "YouTube-servicevoorwaarden" },
    {
        en: "You completed the registration! You can now log in to Wizenoze Learning Resources.",
        nl: "Je hebt de registratie afgerond! Je kunt nu inloggen bij Wizenoze Learning Resources.",
    },
    {
        en: "Review recommended resources for every learning outcome and customise how many resources to share with your learners.",
        nl: "Zoek internetbronnen uit die jouw lessen verrijken en bepaal zelf welke bronnen je met je leerlingen deelt.",
    },
    { en: "OR", nl: "OF" },
    { en: "Key stage 1", nl: "0F" },
    { en: "Key stage 2 low", nl: "1F" },
    { en: "Key stage 2 high", nl: "2F" },
    { en: "Key stage 3", nl: "naar 3F" },
    { en: "Key stage 4/GCSE", nl: "naar 4F" },
    { en: "Grade 2 - 3", nl: "Groep 5-6" },
    { en: "Kindergarten - Grade 1", nl: "Groep 3-4" },
    { en: "CEFR", nl: "CEFR niveaus" },
    { en: "Grades (US)", nl: "Doelgroep" },
    {
        en: "Filter search results by a reading level",
        nl: "Wizenoze deelt teksten in op 5 niveaus. Met onderstaande tabel bepaal je het geschikte leesniveau. Let op: deze indeling is niet gebaseerd op het AVI-niveau",
    },
    {
        en: "Grade 9 - 12",
        nl: "HAVO jaar 4-5; VWO jaar 3-6; MBO niveau 4 en hoger",
    },
    { en: "Grade 4 - 5", nl: "Groep 7-8; VMBO jaar 1; MBO niveau 1" },
    {
        en: "Grade 6 - 8",
        nl: "VMBO jaar 2-3; HAVO jaar 1-3; VWO jaar 1-2; MBO niveau 2-3",
    },
    { en: "Key stages (UK)", nl: "Referentieniveaus taal" },
    { en: "Let’s start!", nl: "Aan de slag" },
    {
        en: "Create and share your collections of educational resources with your learners.",
        nl: "Creëer en deel je collecties van educatieve bronnen met je leerlingen.",
    },
    {
        en: "Share a link or embed resources",
        nl: "Deel een link of sluit de internetbron in",
    },
    {
        en: "Share a link or embed the selected topic or learning outcome resources with your learners. Your learners will receive the resources in a carefully designed experience to allow them to focus on what is important.",
        nl: "Deel eenvoudig een link of kies ervoor om de internetbronnen rechtstreeks in te sluiten in je electronische leeromgeving. Je leerlingen openen de bron in de leerlingomgeving zonder dat ze hoeven in te loggen.",
    },
    { en: "Help", nl: "Help" },
    {
        en: "Curated content by educators mapped to six national curricula.",
        nl: "Internetbronnen geselecteerd door onderwijsprofessionals.",
    },
    {
        en: "Choose your curriculum and grade to start.",
        nl: "Kies een curriculum en een niveau om te starten.",
    },
    {
        en: "Easily navigate through the familiar structure of your curriculum. Or, use the search option to find curated content for any learning objective.",
        nl: "Navigeer door de verschillende curricula of gebruik de zoekbalk.",
    },
    { en: "Browse or search", nl: "Navigeer of zoek" },
    { en: "Discover the Collections", nl: "Ontdek de Collecties" },
    { en: "Discover, review and share", nl: "Ontdek, bekijk en deel" },
    { en: "Skip", nl: "Overslaan" },
    { en: "Next", nl: "Volgende" },
    { en: "Previous", nl: "Vorige" },
    {
        en: "STEM subjects for all curricula.",
        nl: "Zaakvakken voor verschillende curricula.",
    },
    {
        en: "Create and share your collections of educational resources with your learners.",
        nl: "Creëer en deel je collecties van educatieve bronnen met je leerlingen.",
    },
    { en: "Discover the Collections", nl: "Ontdek de Collecties" },
    {
        en: "Let’s discover and share curated resources today:",
        nl: "Ontdek en deel educatieve bronnen vandaag:",
    },
    { en: "Folder created successfully.", nl: "Map met succes aangemaakt." },
    { en: "Error", nl: "Fout" },
    { en: "Folder has been deleted.", nl: "Map is verwijderd." },
    { en: "Folder has been saved.", nl: "Map is opgeslagen." },
    { en: "Rename ", nl: "Map " },
    { en: " folder", nl: " hernoemen" },
    { en: "Folder moved successfully.", nl: "Map met succes verplaatst." },
    { en: "Folder renamed successfully.", nl: "Map met succes hernoemd." },
    {
        en: "Collection moved successfully.",
        nl: "Collectie met succes verplaatst.",
    },
    { en: "Insert new name", nl: "Geef een nieuwe naam" },
    { en: "Confirm your email", nl: "Bevestig je e-mail" },
    { en: "Go to login", nl: "Ga naar login" },
    {
        en: "We could not verify your email address. Please try again or contact our support for further assistance.",
        nl: "We konden je e-mailadres niet verifiëren. Probeer het opnieuw of neem contact op voor verdere hulp.",
    },
    { en: "Send confirmation again", nl: "Stuur bevestiging opnieuw" },
    { en: "Contact support", nl: "Contact onze support afdeling" },
    {
        en: "You can now log in to the Wizenoze app.",
        nl: "Je kunt nu inloggen bij Wizenoze app.",
    },
    { en: "Forgot password", nl: "Wachtwoord vergeten" },
    { en: "Send password recovery", nl: "Herstel wachtwoord" },
    { en: "Back to login", nl: "Terug naar login" },
    {
        en: "Password recovery sent!",
        nl: "E-mail om je wachtwoord te herstellen verzonden!",
    },
    {
        en: "Please check your inbox and follow the link in the email to reset your password. Did not receive the email?",
        nl: "Controleer je inbox en volg de link in de e-mail om je wachtwoord te resetten. De e-mail niet ontvangen?",
    },
    { en: "Resend email", nl: "E-mail opnieuw versturen" },
    {
        en: "The link has been resent to you. If you not find it in your inbox, please check your spam folder or reach out to our ",
        nl: "De link is opnieuw naar je gestuurd. Zit deze niet in je inbox? Controleer dan je spamfolder of neem contact op met ",
    },
    { en: " support ", nl: " support" },
    { en: "for further assistance.", nl: "voor meer hulp." },
    { en: "Create new password", nl: "Maak een nieuw wachtwoord aan" },
    {
        en: "Your new password must be at least 8 characters long.",
        nl: "Je nieuwe wachtwoord moet minimaal 8 tekens lang zijn.",
    },
    { en: "Enter password", nl: "Voer wachtwoord in" },
    { en: "Re-enter password", nl: "Voer wachtwoord opnieuw in" },
    { en: "Confirm password", nl: "Bevestig wachtwoord" },
    { en: "Reset password", nl: "Wachtwoord resetten" },
    { en: "Password set", nl: "Wachtwoord ingesteld" },
    {
        en: "You can now login with your new password.",
        nl: "Je kunt nu inloggen met je nieuwe wachtwoord.",
    },
    { en: "Private collections", nl: "Persoonlijke collecties" },
    { en: "Wizenoze collections", nl: "Wizenoze collecties" },
    { en: "Edit", nl: "Bewerken" },
    { en: "Exit Editor", nl: "Stop met bewerken" },
    { en: "Info", nl: "Info" },
    { en: "Add Resources", nl: "Bronnen toevoegen" },
    { en: "Add my resources", nl: "Mijn bronnen toevoegen" },
    { en: "Add from collections", nl: "Toevoegen uit collecties" },
    {
        en: "Find resource by searching the Wizenoze library",
        nl: "Bron vinden door de Wizenoze bibliotheek te doorzoeken",
    },
    {
        en: "Add resources from other collections",
        nl: "Bronnen uit andere collecties toevoegen",
    },
    { en: "Done", nl: "Klaar" },
    { en: "Premium", nl: "Premium" },
    { en: "Educator content", nl: "Inhoud voor opvoeders" },
    { en: "Fundamentals", nl: "Grondbeginselen" },
    { en: "Handpicked by educators", nl: "Aanbevolen" },
    {
        en: "Resource has been added to the collection.",
        nl: "Bron toegevoegd aan de collectie",
    },
    { en: "Private", nl: "Persoonlijke" },
    { en: " subfolder", nl: " submap" },
    { en: " subfolders", nl: " submappen" },
    { en: " collection", nl: " collectie" },
    { en: " collections", nl: " collecties" },
    { en: " results for ", nl: " resultaten voor " },
    { en: "Collections and folders", nl: "Collecties en mappen" },
    { en: "Workspaces", nl: "Omgevingen" },
    {
        en: "Filter search results by content type",
        nl: "Filter zoekresultaten op type inhoud",
    },
    {
        en: "Filter search results by format in which the original resource is created.",
        nl: "Filter zoekresultaten op formaat van de bron",
    },
    {
        en: "Filter search results by workspace",
        nl: "Filter zoekresultaten op omgeving",
    },
    {
        en: "Filter search results by workspace in which the collections and folders are located.",
        nl: "Filter zoekresultaten binnen de omgeving van de collecties en de mappen",
    },
    {
        en: "Enter your education institution or school name",
        nl: "Voer de naam van je school of onderwijsinstelling in",
    },
    {
        en: "Please tell us how you heard about Wizenoze",
        nl: "Kun je ons vertellen hoe je over Wizenoze te weten bent gekomen?",
    },
    {
        en: "How did you hear about us?",
        nl: "Hoe ben je over ons te weten gekomen?",
    },
    { en: "Enrich your teaching practice", nl: "Verrijk je onderwijs" },
    {
        en: "Enter your mobile phone number",
        nl: "Voer je mobiele telefoonnummer in",
    },
    {
        en: "Unlock the future of education",
        nl: "Ontdek de toekomst van educatie",
    },
    {
        en: "Advanced tools and curated educational resources for enhanced teaching and enriched learning experiences",
        nl: "Geavanceerde hulpmiddelen en zorgvuldig geselecteerde educatieve bronnen voor verbeterd onderwijs en verrijkte leerervaringen",
    },
    { en: "Start the quiz", nl: "Begin de quiz" },
    {
        en: "Take the quiz and see how much you know!",
        nl: "Doe de quiz en kijk hoeveel je weet!",
    },
    { en: "This quiz consists of ", nl: "Deze quiz bestaat uit " },
    { en: " questions.", nl: "vragen." },
    {
        en: "You can take the quiz as many times as you want.",
        nl: "Je kan de quiz zo vaak doen als je wil.",
    },
    {
        en: "Use the quiz options to set up the quiz.",
        nl: "Gebruik de opties om de quiz samen te stellen.",
    },
    {
        en: "Create a quiz in two easy steps:",
        nl: "Maak een quiz in twee eenvoudige stappen:",
    },
    {
        en: "1. Select the type of questions",
        nl: "1. Selecteer het soort vragen",
    },
    {
        en: "2. Choose the number of questions",
        nl: "2. Kies het aantal vragen",
    },
    {
        en: "1. Choose the number of questions",
        nl: "1. Kies het aantal vragen",
    },
    {
        en: "2. Click the Generate Questions button, and we will prepare the quiz.",
        nl: "2. Klik op de Genereer Vragen knop en wij maken de quiz.",
    },
    {
        en: "You can reorder, delete and generate more quiz questions.",
        nl: "Je kan de quiz vragen herschikken of verwijderen en extra vragen genereren.",
    },
    { en: "Save and share", nl: "Sla op en deel" },
    {
        en: "Once you save the quiz, it is added to the collection. The quiz is shared together with the collection.",
        nl: "Als je de quiz hebt opgeslagen, dan is hij toegevoegd aan de collectie. De quiz wordt samen met de collectie gedeeld.",
    },
    { en: "Questions in the quiz", nl: "Vragen in de quiz" },
    { en: "Quiz name", nl: "Naam van de quiz" },
    { en: "Quiz options", nl: "Opties voor de quiz" },
    { en: "Question types", nl: "Type vraag" },
    { en: "Multiple choice", nl: "Meerkeuze" },
    { en: "True or false", nl: "Ja of Nee" },
    {
        en: "At least one question type must be selected.",
        nl: "Tenminste één type vraag moet geselecteerd zijn.",
    },
    { en: "Number of questions", nl: "Aantal vragen" },
    { en: "Generate questions", nl: "Genereer vragen" },
    { en: "Generating questions", nl: "Bezig met vragen genereren" },
    {
        en: "You can't generate a quiz for an empty collection. Please add resources to the collection before generating a quiz.",
        nl: "Je kan geen quiz genereren in een lege collectie. Voeg resources toe aan de collectie voordat je een quiz genereert.",
    },
    {
        en: "You need to save the collection before generating a quiz. Do you want to save the collection?",
        nl: "Je moet de collectie opslaan voordat je een quiz genereert. Wil je de collectie opslaan?",
    },
    { en: "Editor", nl: "Editor" },
    { en: "Preview the quiz", nl: "Bekijk een voorbeeld van de quiz" },
    { en: "Generate Quiz (Beta)", nl: "Genereer Quiz (Beta)" },
    { en: "Quiz (Beta)", nl: "Quiz (Beta)" },
    { en: "Generate Quiz", nl: "Genereer Quiz" },
    { en: "Quiz", nl: "Quiz" },
    { en: "Back", nl: "Terug" },
    { en: "Quiz is not saved", nl: "Quiz is niet opgeslagen" },
    {
        en: "Please be aware that this is an early beta version and as such the quality of results may vary.",
        nl: "Wees je er van bewust dat dit een vroege beta versie is en daarom kan de kwaliteit van de resultaten sterk verschillen.",
    },
    {
        en: "Like (or dislike) your experience with our new tool? Please reach out to us at support@wizenoze.com!",
        nl: "Blij (of niet blij) met onze nieuwe tool? Stuur ons een berichtje op support@wizenoze.com!",
    },
    { en: "Edit the quiz", nl: "Bewerk de quiz" },
    { en: "Delete the quiz", nl: "Verwijder de quiz" },
    {
        en: "Are you sure you want to delete the quiz? You can’t undo this action.",
        nl: "Weet je zeker dat je de quiz wil verwijderen? Dit kan niet ongedaan worden gemaakt.",
    },
    {
        en: "Enter your name to start the quiz",
        nl: "Vul je naam in en start de quiz",
    },
    { en: "Welcome to the ", nl: "Welkom bij de " },
    { en: " quiz.", nl: " quiz." },
    {
        en: "A new collection with the selected resources and a quiz will be created in your Private Workspace.",
        nl: "Een nieuwe collectie met de geselecteerde bronnen en een quiz wordt aangemaakt in jouw omgeving.",
    },
    { en: "Generate quiz (Beta)", nl: "Genereer quiz (Beta)" },
    { en: "Questions are not saved", nl: "Vragen zijn niet opgeslagen" },
    {
        en: "Save all individual questions first.",
        nl: "Sla eerst alle individuele vragen op.",
    },
    { en: "Title cannot be empty", nl: "Titel mag niet leeg zijn" },
    {
        en: "You can't save a question with an empty title. Please add text to your title.",
        nl: "Je kunt geen vraag opslaan met een lege titel. Voeg tekst toe aan je titel.",
    },
    { en: "Option cannot be empty", nl: "Optie mag niet leeg zijn" },
    {
        en: "You can't save a question with an empty option. Please add text to all your options or delete empty options before saving a question.",
        nl: "Je kunt geen opties opslaan met een leeg antwoordveld. Voeg tekst toe aan alle opties of verwijder opties met een leeg antwoordveld.",
    },
    { en: "Add option", nl: "Voeg optie toe" },
    { en: "Mark as correct", nl: "Dit antwoord is correct" },
    { en: "Curriculum filters", nl: "Curriculum filters" },
    { en: "Select subject(s)", nl: "Selecteer vak(ken)" },
    {
        en: "You don’t have permission to edit this collection.",
        nl: "Je hebt geen toestemming om deze collectie te bewerken.",
    },
    { en: "Manually add a question", nl: "Voeg handmatig een vraag toe" },
    { en: "True / False", nl: "Waar / Onwaar" },
    { en: "Fill in the blank", nl: "Vul de ontbrekende tekst in" },
    {
        en: "At least one workspace must be selected.",
        nl: "Tenminste één omgeving moet geselecteerd zijn.",
    },
    { en: "Take the quiz again", nl: "Doe de quiz nogmaals" },
    { en: "Close", nl: "Sluiten" },
    { en: "Confirm answer", nl: "Bevestig het antwoord" },
    { en: "Searching...", nl: "Zoeken..." },
    { en: "Quizzes", nl: "Quizzen" },
    { en: " question", nl: " vraag" },
    { en: " questions", nl: " vragen" },
    { en: "Title", nl: "Titel" },
    { en: "Biology", nl: "Biologie" },
    { en: "Chemistry", nl: "Scheikunde" },
    { en: "English", nl: "Engels" },
    { en: "Mathematics", nl: "Wiskunde" },
    { en: "Physics", nl: "Natuurkunde" },
    { en: "Science", nl: "Wetenschap" },
    { en: "Insert folder name", nl: "Voeg naam van de map toe" },
    { en: "Folder name", nl: "Naam van de map" },
] as const;

// Dont edit directly, always generate from https://wizenozeglobal.sharepoint.com/:x:/g/EWOOK53PyApPo_OJS8a_e2gBxcSKrOCusYIZmXDLkN0kdg?rtime=AIUTCmbn20g and use the excel-to-json 3rd party addon to regenerate all translations
