import {
    QueryClient,
    useMutation,
    useQueryClient,
} from "@tanstack/react-query";

import { axiosAPI } from "./axiosAPI";
import { Workspace } from "types";
import { updateArrayItem } from "utils/newtools";

export const invalidateWorkspacesCache = (queryClient: QueryClient) => {
    queryClient.invalidateQueries({
        queryKey: ["workspaces"],
    });
};

export const useEditWorkspaceMutation = () => {
    const queryClient = useQueryClient();
    return useMutation(
        async (workspace: Workspace) => {
            return await axiosAPI
                .getInstance()
                .put(`/content/workspace/${workspace.id}`, workspace);
        },
        {
            onMutate: (workspace) => {
                updateWorkspacesCache(queryClient, workspace);
            },
            onSettled: () => invalidateWorkspacesCache(queryClient),
        },
    );
};

const updateWorkspacesCache = (
    queryClient: QueryClient,
    workspace: Workspace,
) => {
    const workspaces: Workspace[] =
        queryClient.getQueryData(["workspaces"]) ?? [];
    const workspaceIndex = workspaces.findIndex((el) => el.id === workspace.id);
    if (workspaceIndex !== -1) {
        const newWorkspaces = updateArrayItem(
            workspaces,
            workspace,
            workspaceIndex,
        );
        queryClient.setQueryData(["workspaces"], newWorkspaces);
    }
};
