@use "../../../../../styles/variables.module";

.container {
    display: flex;
    padding: 10px;
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
    border-radius: 15px;
    border: 1px solid variables.$border-colors-color-border-light;
    background: variables.$background-colors-color-background-white;
    box-shadow: 0px 5px 20px 0px rgba(108, 117, 125, 0.2);
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
}

.body {
    display: flex;
    align-items: center;
    gap: 10px;
}

.bodyImage {
    width: 118px;
    height: 118px;
    border-radius: 6px;
    border: 1px solid variables.$border-colors-color-border-light;
}

.bodyTexts {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    gap: 15px;
    align-self: stretch;
}

.bodyTitle {
    display: flex;
    align-items: center;
    gap: 10px;
    align-self: stretch;
}

.title {
    display: -webkit-box;
    line-clamp: 1;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    word-break: break-all;
    overflow: hidden;
    cursor: pointer;
}

.footer {
    display: flex;
    padding-top: 10px;
    align-items: center;
    align-content: center;
    gap: 10px;
    align-self: stretch;
    flex-wrap: wrap;
}

.divider {
    color: variables.$icon-colors-green-60;
    font-size: 0.25rem;
}
