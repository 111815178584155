import { CSSProperties, ReactNode, useEffect, useRef, useState } from "react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
    faAnglesUp,
    faAngleDoubleDown,
    faArrowRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    Button,
    ClickAwayListener,
    Collapse,
    Tooltip,
    Typography,
} from "@mui/material";

import * as styles from "./Panel.module.scss";
import useScreenSizes from "hooks/useScreenSizes";
import variables from "styles/_variables.module.scss";

import Translate, { TranslatableText } from "components/Translate/Translate";

export const PanelSection = ({
    title,
    icon,
    tooltip,
    isCollapsible,
    colorOverride,
    triggerCollapse,
    children,
}: {
    title: TranslatableText;
    icon?: IconProp;
    tooltip?: {
        title?: TranslatableText;
        description?: TranslatableText;
        component?: ReactNode;
    };
    isCollapsible?: boolean;
    colorOverride?: string;
    triggerCollapse?: boolean;
    children: ReactNode | ReactNode[];
}) => {
    const { isMobileScreen } = useScreenSizes();
    const sectionRef = useRef<HTMLDivElement>(null);
    const [sectionHeight, setSectionHeight] = useState<number | null>(null);
    useEffect(() => {
        if (!sectionHeight && sectionRef.current?.clientHeight)
            setSectionHeight(sectionRef.current?.clientHeight);
    }, [sectionHeight]);

    const [isTooltipOpen, setIsTooltipOpen] = useState(false);
    const [isCollapsed, setIsCollapsed] = useState(false);
    const [collapseTriggered, setCollapseTriggered] = useState(false);
    useEffect(() => {
        if (triggerCollapse === undefined) return;
        if (triggerCollapse && !collapseTriggered) {
            setCollapseTriggered(true);
            setIsCollapsed(true);
        } else if (!triggerCollapse) {
            setCollapseTriggered(false);
            setIsCollapsed(false);
        }
    }, [collapseTriggered, isCollapsed, triggerCollapse]);
    return (
        <div
            style={{
                flexDirection: "column",
                width: "100%",
                flexBasis: "fit-content",
                flexShrink: 0,
                overflowY: "hidden",
            }}
        >
            <div
                className={styles.sectionTitle}
                style={{
                    marginBottom: "10px",
                }}
            >
                <div className={styles.iconTitleTooltip}>
                    {icon && (
                        <FontAwesomeIcon
                            icon={icon}
                            color={variables["text-colors-color-text-gray"]}
                        />
                    )}
                    <Typography
                        variant="text-size-Base-bold"
                        color={
                            colorOverride ?? "$text-colors-color-text-dark.main"
                        }
                    >
                        <Translate text={title} />
                    </Typography>
                    {tooltip && (
                        <Tooltip
                            title={
                                <ClickAwayListener
                                    onClickAway={() => setIsTooltipOpen(false)}
                                >
                                    {tooltip.component ? (
                                        <div>{tooltip.component}</div>
                                    ) : (
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                            }}
                                        >
                                            {tooltip.title && (
                                                <Typography variant="text-size-xxSmall-bold">
                                                    <Translate
                                                        text={tooltip.title}
                                                    />
                                                </Typography>
                                            )}
                                            {tooltip.description && (
                                                <Typography variant="text-size-xxSmall-regular">
                                                    <Translate
                                                        text={
                                                            tooltip.description
                                                        }
                                                    />
                                                </Typography>
                                            )}
                                        </div>
                                    )}
                                </ClickAwayListener>
                            }
                            placement={isMobileScreen ? "bottom" : "right"}
                            arrow
                            open={isTooltipOpen}
                            slotProps={{
                                tooltip: { style: { maxWidth: "350px" } },
                            }}
                        >
                            <Button
                                variant="outlined"
                                color="$border-colors-color-border-primary"
                                style={{
                                    width: "25px",
                                    height: "25px",
                                    backgroundColor:
                                        variables[
                                            "background-colors-color-background-light-off"
                                        ],
                                    borderRadius: "25px",
                                }}
                                onClick={() => setIsTooltipOpen(true)}
                            >
                                <Typography
                                    variant="text-size-xSmall-bold"
                                    color="$text-colors-color-text-gray.main"
                                >
                                    ?
                                </Typography>
                            </Button>
                        </Tooltip>
                    )}
                </div>

                {isCollapsible && (
                    <FontAwesomeIcon
                        icon={!isCollapsed ? faAnglesUp : faAngleDoubleDown}
                        onClick={() => setIsCollapsed(!isCollapsed)}
                        style={{
                            // color: variables["text-colors-color-text-gray"],
                            textAlign: "center",
                            fontSize: "12px",
                            fontStyle: "normal",
                            fontWeight: 900,
                            lineHeight: "20px",
                        }}
                        color={colorOverride}
                    />
                )}
            </div>
            <Collapse in={!isCollapsed}>{children}</Collapse>
        </div>
    );
};

export const PanelV2 = ({
    children,
    styleOverrides,
}: {
    children: ReactNode | ReactNode[];
    styleOverrides?: CSSProperties;
}) => (
    <div className={styles.containerV2} style={styleOverrides}>
        {children}
    </div>
);

interface Props {
    children: React.ReactElement | React.ReactElement[];
    title: TranslatableText;
    footer?: {
        text: TranslatableText;
        clickHandler: () => void;
    };
    styleOverrides?: CSSProperties;
}

const Panel = ({ children, title, footer, styleOverrides }: Props) => {
    return (
        <div className={styles.container} style={styleOverrides}>
            <div className={styles.title}>
                <Typography
                    variant="text-size-Base-bold"
                    color="$text-colors-color-text-dark.main"
                >
                    <Translate text={title} />
                </Typography>
            </div>
            {children}
            {footer && (
                <div className={styles.footerContainer}>
                    <div
                        className={styles.footer}
                        onClick={footer.clickHandler}
                    >
                        <FontAwesomeIcon
                            icon={faArrowRight}
                            fontSize={12}
                            style={{ padding: "4px 0px" }}
                        />
                        <Typography variant="text-size-xSmall-bold">
                            <Translate text={footer.text} />
                        </Typography>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Panel;
