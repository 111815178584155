import { Fragment } from "react";
import { floor } from "lodash";
import { Typography } from "@mui/material";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import * as styles from "./SearchResults.module.scss";
import { useSearchInfiniteQuery } from "hooks/api/useSearchQuery";
import useIntersectionObserver from "hooks/useIntersectionObserver";
import useScreenSizes from "hooks/useScreenSizes";

import SearchResourceResultCard from "./components/SearchResultCard";
import WizenozeContentCard from "./components/WizenozeContentCard";
import MobileSearchFilters from "../SearchFilters/MobileSearchFilters";
import Loading from "components/Loading/Loading";
import Translate from "components/Translate/Translate";

const SearchResults = () => {
    const {
        data: searchResultsData,
        fetchNextPage,
        hasNextPage,
        isFetching,
        status: searchResultsStatus,
    } = useSearchInfiniteQuery();

    const { isDesktopScreen } = useScreenSizes();
    const handleIntersection: IntersectionObserverCallback = (entries) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting && !isFetching) {
                fetchNextPage();
            }
        });
    };
    const footerRef = useIntersectionObserver(handleIntersection, {
        root: null,
        rootMargin: "0px",
        threshold: 0.5,
    });

    return (
        <div className={styles.container}>
            <div className={styles.mobileFilterAndMessage}>
                {!isDesktopScreen && <MobileSearchFilters />}
                <Typography variant="text-size-xSmall-regular">
                    {searchResultsStatus === "success" ? (
                        <>
                            <Translate text="We found " />
                            {!searchResultsData ? (
                                <></>
                            ) : searchResultsData.pages[0].pagination
                                  .totalResults < 1000 ? (
                                searchResultsData.pages[0].pagination
                                    .totalResults
                            ) : (
                                floor(
                                    searchResultsData.pages[0].pagination
                                        .totalResults,
                                    -3,
                                ) + "+"
                            )}
                            <Translate text={" results for "} />
                            <Typography variant="text-size-xSmall-bold">
                                {searchResultsData?.pages[0].query.query}
                            </Typography>
                        </>
                    ) : (
                        <Translate text="Searching..." />
                    )}
                </Typography>
            </div>
            {searchResultsStatus === "success" ? (
                <div className={styles.resultsList}>
                    {searchResultsData?.pages.map((group) =>
                        group?.results.map((result) => (
                            <Fragment key={result.id}>
                                {result.resultType === "TEXT" ||
                                result.resultType === "VIDEO" ? (
                                    <SearchResourceResultCard result={result} />
                                ) : (
                                    <WizenozeContentCard result={result} />
                                )}
                            </Fragment>
                        )),
                    )}
                    {searchResultsData?.pages[0].pagination.totalResults != 0 &&
                        hasNextPage && (
                            <div
                                ref={footerRef}
                                style={{
                                    height: "20px",
                                    alignSelf: "center",
                                    position: "relative",
                                }}
                            >
                                {isFetching && (
                                    <div
                                        style={{
                                            position: "absolute",
                                            left: "-30px",
                                        }}
                                    >
                                        <FontAwesomeIcon
                                            icon={faSpinner}
                                            spin
                                            fontSize={15}
                                        />
                                    </div>
                                )}
                                <Typography
                                    variant="text-size-Small-regular"
                                    color="$text-colors-color-text-blue.main"
                                >
                                    <Translate text="Load more" />
                                </Typography>
                            </div>
                        )}
                </div>
            ) : (
                <Loading />
            )}
        </div>
    );
};

export default SearchResults;
