import { Theme, useMediaQuery } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";

import { CollectionCardSkeleton } from "components/CollectionCard/CollectionCard";

const FolderOrWorkspaceGridViewSkeleton = () => {
    const isMobileScreen = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down("md"),
    );
    return (
        <Grid container spacing={"20px"}>
            <>
                {new Array(isMobileScreen ? 1 : 3)
                    .fill(null)
                    .map((_, index) => (
                        <Grid key={"g" + index} md={4} xs={12}>
                            <CollectionCardSkeleton />
                        </Grid>
                    ))}
            </>
        </Grid>
    );
};

export default FolderOrWorkspaceGridViewSkeleton;
