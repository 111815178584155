import { useNavigate, useSearchParams } from "react-router-dom";
import { Typography } from "@mui/material";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import * as styles from "../SuggestionsList.module.scss";
import variables from "styles/_variables.module.scss";
import { useSearchContext } from "contexts/SearchContext";

interface Props {
    title: string;
    closeSearch: () => void;
}
const SuggestionsListTermItem = ({ title, closeSearch }: Props) => {
    const { inDialogBox } = useSearchContext();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const clickHandler = () => {
        if (inDialogBox) {
            searchParams.set("searchTerm", title);
            setSearchParams(searchParams, { replace: true });
            closeSearch();
        } else {
            closeSearch();
            navigate("/search?searchTerm=" + title);
        }
    };
    return (
        <div
            className={styles.listItem}
            onMouseDown={(e) => e.preventDefault()}
            onClick={clickHandler}
        >
            <div className={styles.listItemLogoAndTitle}>
                <FontAwesomeIcon
                    icon={faMagnifyingGlass}
                    color={variables["text-colors-color-text-light-gray"]}
                    fontSize={16}
                />
                <Typography
                    variant="text-size-Base"
                    color="$text-colors-color-text-dark.main"
                    className={styles.title}
                >
                    {title}
                </Typography>
            </div>
        </div>
    );
};

export default SuggestionsListTermItem;
