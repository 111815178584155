import { useState } from "react";
import { faFile } from "@fortawesome/free-solid-svg-icons";

import { settings } from "./SearchFilters.module.scss";
import { useSearchContext } from "contexts/SearchContext";
import { ResultType } from "types";

import LabeledSwitch from "components/InputFields/LabeledSwitch/LabeledSwitch";
import { PanelSection } from "components/Panel/Panel";

const ResultTypesSection = () => {
    const { resultTypes, setResultTypes, inDialogBox } = useSearchContext();
    const [showTextsTooltip, setShowTextsTooltip] = useState(false);
    const [showVideosTooltip, setShowVideosTooltip] = useState(false);
    const [
        showCollectionsAndFoldersTooltip,
        setShowCollectionsAndFoldersTooltip,
    ] = useState(false);

    const updateResultType = (value: ResultType) => {
        const newResultType = [...resultTypes];
        if (newResultType.includes(value)) {
            newResultType.splice(newResultType.indexOf(value), 1);
            if (value === "COLLECTION") {
                newResultType.splice(newResultType.indexOf("FOLDER"), 1);
            }
        } else {
            newResultType.push(value);
            if (value === "COLLECTION") {
                newResultType.push("FOLDER");
            }
        }
        setResultTypes(new Set(newResultType));
    };

    return (
        <PanelSection
            title="Content type"
            icon={faFile}
            tooltip={{
                title: "Filter search results by content type",
                description:
                    "Filter search results by format in which the original resource is created.",
            }}
            isCollapsible
        >
            <div className={settings}>
                <LabeledSwitch
                    label={"Pages"}
                    keyname={"pages"}
                    value={resultTypes.has("TEXT")}
                    updateHandler={() => {
                        if (resultTypes.has("TEXT") && resultTypes.size === 1) {
                            setShowTextsTooltip(true);
                            if (inDialogBox)
                                setTimeout(
                                    () => setShowTextsTooltip(false),
                                    2000,
                                );
                        } else {
                            updateResultType("TEXT");
                        }
                    }}
                    switchTooltip={
                        "At least one content type must be selected."
                    }
                    showSwitchTooltip={showTextsTooltip}
                    hideSwitchTooltipHandler={() => setShowTextsTooltip(false)}
                />
                <LabeledSwitch
                    label={"Videos"}
                    keyname={"videos"}
                    value={resultTypes.has("VIDEO")}
                    updateHandler={() => {
                        if (
                            resultTypes.has("VIDEO") &&
                            resultTypes.size === 1
                        ) {
                            setShowVideosTooltip(true);
                            if (inDialogBox)
                                setTimeout(
                                    () => setShowVideosTooltip(false),
                                    2000,
                                );
                        } else {
                            updateResultType("VIDEO");
                        }
                    }}
                    switchTooltip={
                        "At least one content type must be selected."
                    }
                    showSwitchTooltip={showVideosTooltip}
                    hideSwitchTooltipHandler={() => setShowVideosTooltip(false)}
                />
                {!inDialogBox && (
                    <LabeledSwitch
                        label={"Collections and folders"}
                        keyname={"collectionsandfolders"}
                        value={resultTypes.has("COLLECTION")}
                        updateHandler={() => {
                            if (
                                resultTypes.has("COLLECTION") &&
                                resultTypes.has("FOLDER") &&
                                resultTypes.size === 2
                            ) {
                                setShowCollectionsAndFoldersTooltip(true);
                                if (inDialogBox)
                                    setTimeout(
                                        () =>
                                            setShowCollectionsAndFoldersTooltip(
                                                false,
                                            ),
                                        2000,
                                    );
                            } else {
                                updateResultType("COLLECTION");
                            }
                        }}
                        switchTooltip={
                            "At least one content type must be selected."
                        }
                        showSwitchTooltip={showCollectionsAndFoldersTooltip}
                        hideSwitchTooltipHandler={() =>
                            setShowCollectionsAndFoldersTooltip(false)
                        }
                    />
                )}
            </div>
        </PanelSection>
    );
};

export default ResultTypesSection;
