// A simple wrapper for elements which need to be 'disabled' like buttons, but still activate things like tooltips

const DisabledClickhandler = ({
    isDisabled,
    clickHandler,
    children,
}: {
    isDisabled: boolean;
    clickHandler: () => void;
    children: JSX.Element | JSX.Element[];
}) => {
    if (!isDisabled || !clickHandler) return <>{children}</>;
    return <div onClick={clickHandler}>{children}</div>;
};

export default DisabledClickhandler;
