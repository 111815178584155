import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
    faArrowRightToBracket,
    faHelicopter,
    faPenToSquare,
    faTrash,
} from "@fortawesome/free-solid-svg-icons";

import DeleteCollectionDialog from "./dialogs/DeleteCollectionDialog";
import MoveCollectionDialog from "./dialogs/MoveCollectionDialog";
import useCollectionsParams from "hooks/useCollectionsParams";
import { Collection } from "types";
import { tracker } from "utils/analytics";

import ThreeDotContextMenu from "./ThreeDotContextMenu";
import UserRoleDialog from "./dialogs/UserRoleEditDialog";

const CollectionThreeDotContextMenu = ({
    collection,
    isHovered,
}: {
    collection: Collection;
    isHovered: boolean;
}) => {
    const navigate = useNavigate();
    const collectionsParams = useCollectionsParams();
    const [isDialogVisible, setIsDialogVisible] = useState(false);
    const [openDialogType, setOpenDialogType] = useState<
        | undefined
        | "edit"
        | "move"
        // | "duplicate"
        | "delete"
        | "share"
        | "permissions"
    >();

    const editHandler = () => {
        navigate(`/edit-collection/${collection.id}`, {
            state: {
                collectionsParams: collectionsParams,
            },
        });
    };
    const moveToHandler = () => {
        tracker("Move Collection Dialog Opened");
        setOpenDialogType("move");
        setIsDialogVisible(true);
    };
    // const duplicateHandler = () => {
    //     tracker("Duplicate Collection Dialog Opened");
    //     setOpenDialogType("duplicate");
    //     setIsDialogVisible(true);
    // };
    // const shareHandler = () => {
    //     tracker("Share Collection Dialog Opened");
    //     setOpenDialogType("share");
    //     setIsDialogVisible(true);
    // };
    const deleteHandler = () => {
        tracker("Delete Collection Dialog Opened");
        setOpenDialogType("delete");
        setIsDialogVisible(true);
    };
    const permissionsHandler = () => {
        setOpenDialogType("permissions");
        setIsDialogVisible(true);
    };
    return (
        <>
            <ThreeDotContextMenu
                options={[
                    {
                        label: "Edit",
                        icon: faPenToSquare,
                        clickHandler: editHandler,
                        hidden: !collection.permissions?.includes("UPDATE"),
                    },
                    {
                        label: "Move to",
                        icon: faArrowRightToBracket,
                        clickHandler: moveToHandler,
                        hidden: !collection.permissions?.includes("MOVE"),
                    },
                    // {
                    //     label: "Duplicate",
                    //     icon: faClone,
                    //     clickHandler: duplicateHandler,
                    // },
                    // {
                    //     label: "Share",
                    //     icon: faShareFromSquare,
                    //     clickHandler: shareHandler,
                    // },
                    {
                        label: "Delete",
                        icon: faTrash,
                        clickHandler: deleteHandler,
                        hidden: !collection.permissions?.includes("DELETE"),
                    },
                    {
                        label: "Permissions",
                        icon: faHelicopter,
                        clickHandler: permissionsHandler,
                        hidden: process.env.NODE_ENV === "production",
                    },
                ]}
                isHovered={isHovered}
            />
            <MoveCollectionDialog
                collection={collection}
                isVisible={isDialogVisible && openDialogType === "move"}
                setIsVisible={setIsDialogVisible}
            />
            <DeleteCollectionDialog
                collection={collection}
                isVisible={isDialogVisible && openDialogType === "delete"}
                setIsVisible={setIsDialogVisible}
            />
            {process.env.NODE_ENV !== "production" && (
                <UserRoleDialog
                    element={collection}
                    isVisible={
                        isDialogVisible && openDialogType === "permissions"
                    }
                    setIsVisible={setIsDialogVisible}
                />
            )}
        </>
    );
};

export default CollectionThreeDotContextMenu;
