import { faPlayCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
    card,
    container,
    visualContainer,
    imageContainer,
    videoIconContainer,
    videoIcon,
    textContainer,
} from "./ResultCard.module.scss";
import { OldResult } from "types";

import PremiumIcon from "./PremiumIcon/PremiumIcon";
import ResultCardBody from "./ResultCardBody/ResultCardBody";
import ResultCardFooter from "./ResultCardFooter/ResultCardFooter";
import TeacherApproved from "./TeacherApproved/TeacherApproved";
import ExternalImageWithFallback from "components/ExternalImageWithFallback/ExternalImageWithFallback";
import ResultLink from "features/ResultLink/ResultLink";

export interface ResultThumbnails {
    articleImageThumbnail?: string;
    openGraphImageThumbnail?: string;
    thumbnail?: string;
    // Add other properties here if needed
}

export const getImgUrl = ({
    articleImageThumbnail,
    openGraphImageThumbnail,
    thumbnail,
}: ResultThumbnails) =>
    articleImageThumbnail ?? openGraphImageThumbnail ?? thumbnail;

interface Props {
    results: OldResult[];
    resultIndex: number;
}

const ResultCard = ({ results, resultIndex, ...props }: Props) => {
    const result = results[resultIndex];
    return (
        <div className={card}>
            <div className={container}>
                <div className={visualContainer}>
                    <ResultLink
                        results={results}
                        initialIndex={resultIndex}
                        styles={imageContainer}
                    >
                        <ExternalImageWithFallback
                            src={getImgUrl(result)}
                            alt={result.title}
                        />
                        {result?.documentTypes?.includes("video") && (
                            <div className={videoIconContainer}>
                                <FontAwesomeIcon
                                    icon={faPlayCircle}
                                    className={videoIcon}
                                    title="video-icon"
                                />
                            </div>
                        )}
                    </ResultLink>
                </div>
                <div className={textContainer}>
                    <ResultCardBody
                        results={results}
                        resultIndex={resultIndex}
                        {...props}
                    />
                    <ResultCardFooter
                        results={results}
                        resultIndex={resultIndex}
                    />
                </div>
                <PremiumIcon
                    collectionAccessType={result?.collectionAccessType}
                />
                <TeacherApproved
                    collectionAccessType={result?.collectionAccessType}
                    isTeacherApproved={result.teacherApproved}
                />
            </div>
        </div>
    );
};

export default ResultCard;
