import { useState } from "react";
import { faFolder } from "@fortawesome/free-solid-svg-icons";

import { settings } from "./SearchFilters.module.scss";
import { useSearchContext } from "contexts/SearchContext";
import { useAllFoldersEnhancedQuery } from "hooks/api/folders";

import LabeledSwitch from "components/InputFields/LabeledSwitch/LabeledSwitch";
import { PanelSection } from "components/Panel/Panel";

const dummyWizenozeWorkspace = {
    id: -1,
    title: "Wizenoze",
};
const WorkspacesSection = () => {
    const { resultTypes, workspaces, setWorkspaces, inDialogBox } =
        useSearchContext();
    const [showTooltip, setShowTooltip] = useState<number | null>(null);
    const { data: allWorkspaces } = useAllFoldersEnhancedQuery();
    const updateWorkspaces = (value: number) => {
        if (workspaces.has(value)) {
            setWorkspaces((prev) => {
                prev.delete(value);
                return new Set(prev);
            });
        } else {
            setWorkspaces((prev) => {
                prev.add(value);
                return new Set(prev);
            });
        }
    };

    if (
        inDialogBox ||
        (!resultTypes.has("COLLECTION") && !resultTypes.has("FOLDER"))
    )
        return <></>;

    const enhancedAllWorkspaces = [...allWorkspaces, dummyWizenozeWorkspace];
    return (
        <PanelSection
            title="Workspaces"
            icon={faFolder}
            tooltip={{
                title: "Filter search results by workspace",
                description:
                    "Filter search results by workspace in which the collections and folders are located.",
            }}
            isCollapsible
        >
            <div className={settings}>
                {enhancedAllWorkspaces.map((workspace, index) => (
                    <LabeledSwitch
                        key={workspace.id}
                        label={workspace.title}
                        keyname={workspace.id.toString()}
                        value={workspaces.has(workspace.id)}
                        updateHandler={() => {
                            if (
                                workspaces.has(workspace.id) &&
                                workspaces.size === 1
                            ) {
                                setShowTooltip(index);
                                if (inDialogBox)
                                    setTimeout(
                                        () => setShowTooltip(null),
                                        2000,
                                    );
                            } else {
                                updateWorkspaces(workspace.id);
                            }
                        }}
                        switchTooltip="At least one workspace must be selected."
                        showSwitchTooltip={showTooltip === index}
                        hideSwitchTooltipHandler={() => setShowTooltip(null)}
                    />
                ))}
            </div>
        </PanelSection>
    );
};

export default WorkspacesSection;
