import {
    createBrowserRouter,
    createMemoryRouter,
    Navigate,
    RouteObject,
    RouterProvider,
    useLocation,
} from "react-router-dom";
import { ProductFruits } from "react-product-fruits";
import useHotjar from "react-use-hotjar";

import useUserQuery from "./hooks/api/useUserQuery";
import { useUserSettingsQuery } from "hooks/api/useUserSettingsQuery";
import { tracker, trackPageView } from "./utils/analytics";
import { getAppMode } from "./utils/newtools";

import App from "./App";
import AppLayout from "components/AppLayout/AppLayout";
import CollectionEditor from "features/CollectionEditor/CollectionEditor";
import CollectionShareViewer from "features/CollectionShareViewer/CollectionShareViewer";
import CurriculumNavigator from "features/CurriculumNavigator/CurriculumNavigator";
import CurriculumShareViewer from "features/CurriculumShareViewer/CurriculumShareViewer";
import DashboardPage from "features/DashboardPage/DashboardPage";
import FolderShareViewer from "features/FolderShareViewer/FolderShareViewer";
import ForgotPassword from "features/PublicPages/ForgotPassword/ForgotPassword";
import PasswordReset from "features/PublicPages/PasswordReset/PasswordReset";
import VerifyEmail from "features/PublicPages/VerifyEmail/VerifyEmail";
import Login from "features/PublicPages/Login/Login";
import Signup from "features/PublicPages/Signup/Signup";
import MyCollections from "features/MyCollections/MyCollections";
import SearchPage from "features/SearchPage/SearchPage";
import Settings from "features/Settings/Settings";
import TutorChat from "features/TutorChat/TutorChat";

const AnonymousRoute = ({ children }: { children: JSX.Element }) => {
    const { data: user } = useUserQuery();
    const location = useLocation();
    if (!user) return children;
    if (location.state?.from)
        return <Navigate to={location.state?.from} replace />;
    return <Navigate to="/" replace />;
};

const ProtectedRoute = ({ children }: { children: JSX.Element }) => {
    const { data: user } = useUserQuery();
    const { data: userSettings } = useUserSettingsQuery();
    const location = useLocation();
    const { identifyHotjar } = useHotjar();
    if (!user)
        return <Navigate to="/login" replace state={{ from: location }} />;
    const userInfo = {
        username: user.me.id.toString(), // REQUIRED - any unique user identifier
        email: user.me.email,
        ...(user.me.firstName ? { firstname: user.me.firstName } : null),
        ...(user.me.lastName ? { lastname: user.me.lastName } : null),
        // signUpAt: "==REPLACE==",
        // role: "==REPLACE==",
        // props: { customProp1: "==REPLACE==" },
    };
    !!process.env.REACT_APP_HOTJAR_KEY &&
        identifyHotjar(user.me.id.toString(), userInfo);
    return (
        <>
            <ProductFruits
                workspaceCode="FQ9voMneNfeALMoC"
                language={userSettings?.language ?? "en"}
                user={userInfo}
            />
            {children}
        </>
    );
};

const UnknownRoute = () => {
    const { data: user } = useUserQuery();
    const location = useLocation();
    if (!user && getAppMode() === "app") {
        return <Navigate to="/home" replace state={{ from: location }} />;
    } else {
        return <Navigate to="/" replace state={{ from: location }} />;
    }
};

// Does the initialisation of axiosAPI, but within the React Query provider, to make sure cached user value is provided
const AppRouter = ({
    inTestMode,
    testInitialEntries,
}: {
    inTestMode?: boolean;
    testInitialEntries?: string[];
}) => {
    const createRouter = () => {
        const appRouterConfig: RouteObject[] = [
            {
                path: "/home",
                element: (
                    <AnonymousRoute>
                        <Navigate to="/login" replace />
                    </AnonymousRoute>
                ),
                loader: () => {
                    trackPageView("Home");
                    tracker("Accessed Home");
                    return null;
                },
            },
            {
                path: "/login",
                element: (
                    <AnonymousRoute>
                        <Login />
                    </AnonymousRoute>
                ),
                loader: () => {
                    trackPageView("Login");
                    tracker("Accessed Login");
                    return null;
                },
            },
            {
                path: "/signup",
                element: (
                    <AnonymousRoute>
                        <Signup />
                    </AnonymousRoute>
                ),
                loader: () => {
                    trackPageView("Signup");
                    tracker("Accessed Signup");
                    return null;
                },
            },
            {
                path: "/verifyemail",
                element: (
                    <AnonymousRoute>
                        <VerifyEmail />
                    </AnonymousRoute>
                ),
                loader: () => {
                    trackPageView("Verify Email");
                    tracker("Accessed Verify Email");
                    return null;
                },
            },
            {
                path: "/forgot-password",
                element: (
                    <AnonymousRoute>
                        <ForgotPassword />
                    </AnonymousRoute>
                ),
                loader: () => {
                    trackPageView("Forgot Password");
                    tracker("Accessed Forgot Password");
                    return null;
                },
            },
            {
                path: "/password-reset",
                element: (
                    <AnonymousRoute>
                        <PasswordReset />
                    </AnonymousRoute>
                ),
                loader: () => {
                    trackPageView("Password Reset");
                    tracker("Accessed Password Reset");
                    return null;
                },
            },
            {
                path: "/",
                element: (
                    <ProtectedRoute>
                        <App />
                    </ProtectedRoute>
                ),
                children: [
                    {
                        index: true,
                        element: <DashboardPage />,
                        loader: () => {
                            trackPageView("Dashboard Page");
                            tracker("Accessed Dashboard Page");
                            return null;
                        },
                    },
                    {
                        path: "curriculum/:curriculumId/*",
                        element: <CurriculumNavigator />,
                        loader: () => {
                            trackPageView("Curriculum Navigator");
                            tracker("Accessed Curriculum Navigator");
                            return null;
                        },
                    },
                    {
                        path: "my-collections/*",
                        element: <MyCollections />,
                        loader: () => {
                            trackPageView("My Collections");
                            tracker("Accessed My Collections");
                            return null;
                        },
                    },
                    {
                        path: "create-collection",
                        element: <CollectionEditor />,
                        loader: () => {
                            trackPageView("Create Collection");
                            tracker("Accessed Create Collection");
                            return null;
                        },
                    },
                    {
                        path: "edit-collection/:collectionId",
                        element: <CollectionEditor />,
                        loader: () => {
                            trackPageView("Edit Collection");
                            tracker("Accessed Edit Collection");
                            return null;
                        },
                    },
                    {
                        path: "settings",
                        element: <Settings />,
                        loader: () => {
                            trackPageView("User Profile");
                            tracker("Accessed User Profile");
                            return null;
                        },
                    },
                    {
                        path: "search",
                        element: <SearchPage />,
                        loader: () => {
                            trackPageView("Search");
                            tracker("Accessed Search Page");
                            return null;
                        },
                    },
                ],
            },
            {
                path: "tutorchat/:collectionId",
                element: <TutorChat />,
                // loader: () => {
                //     trackPageView("Search");
                //     tracker("Accessed Search Page");
                //     return null;
                // },
            },
            {
                path: "tutorchat/:collectionId/:userId/:botId?",
                element: <TutorChat />,
                // loader: () => {
                //     trackPageView("Search");
                //     tracker("Accessed Search Page");
                //     return null;
                // },
            },
            {
                path: "*",
                element: <UnknownRoute />,
                loader: () => {
                    trackPageView("Unknown");
                    tracker("Accessed Unknown Route");
                    return null;
                },
            },
        ];

        const shareRouterConfig: RouteObject[] = [
            {
                path: "/curriculum-share-viewer/:shareType/*",
                element: (
                    <AppLayout>
                        <CurriculumShareViewer />
                    </AppLayout>
                ),
                loader: () => {
                    trackPageView("Curriculum Share Viewer");
                    tracker("Accessed Curriculum Share Viewer");
                    return null;
                },
            },
            {
                path: "/library/:shareType/*",
                element: (
                    <AppLayout>
                        <CurriculumNavigator />
                    </AppLayout>
                ),
                loader: () => {
                    trackPageView("Curriculum Library");
                    tracker("Accessed Curriculum Library");
                    return null;
                },
            },
            {
                path: "/",
                element: <App />,
                children: [
                    {
                        index: true,
                        element: <CurriculumNavigator />,
                        loader: () => {
                            trackPageView("Dashboard Page");
                            tracker("Accessed Dashboard Page");
                            return null;
                        },
                    },
                    {
                        path: "curriculum/:curriculumId/*",
                        element: <CurriculumNavigator />,
                        loader: () => {
                            trackPageView("Curriculum Navigator");
                            tracker("Accessed Curriculum Navigator");
                            return null;
                        },
                    },
                    {
                        path: "view",
                        children: [
                            {
                                path: ":collectionId/:hash",
                                element: <CollectionShareViewer key="view" />,
                                loader: () => {
                                    trackPageView("Share Collection Viewer");
                                    tracker("Accessed Share Collection Viewer");
                                    return null;
                                },
                            },
                            {
                                path: "folder/*",
                                element: <FolderShareViewer key="view" />,
                                loader: () => {
                                    trackPageView("Share Folder Viewer");
                                    tracker("Accessed Share Folder Viewer");
                                    return null;
                                },
                            },
                        ],
                    },
                    {
                        path: "embed",
                        children: [
                            {
                                path: ":collectionId/:hash",
                                element: <CollectionShareViewer key="embed" />,
                                loader: () => {
                                    trackPageView("Embed Folder Viewer");
                                    tracker("Accessed Embed Folder Viewer");
                                    return null;
                                },
                            },
                            {
                                path: "folder/*",
                                element: <FolderShareViewer key="embed" />,
                                loader: () => {
                                    trackPageView("Embed Folder Viewer");
                                    tracker("Accessed Embed Folder Viewer");
                                    return null;
                                },
                            },
                        ],
                    },
                    {
                        path: "search",
                        element: <SearchPage />,
                        loader: () => {
                            trackPageView("Search");
                            tracker("Accessed Search Page");
                            return null;
                        },
                    },
                ],
            },
            {
                path: "tutorchat/:collectionId/:hashId",
                element: <TutorChat />,
                // loader: () => {
                //     trackPageView("Search");
                //     tracker("Accessed Search Page");
                //     return null;
                // },
            },
            {
                path: "tutorchat/:collectionId/:hashId/:userId/:botId?",
                element: <TutorChat />,
                // loader: () => {
                //     trackPageView("Search");
                //     tracker("Accessed Search Page");
                //     return null;
                // },
            },
            {
                path: "*",
                element: <UnknownRoute />,
                loader: () => {
                    trackPageView("Unknown");
                    tracker("Accessed Unknown Route");
                    return null;
                },
            },
        ];

        const ltiRouterConfig: RouteObject[] = [
            {
                path: "*",
                element: <CurriculumNavigator />,
                loader: () => {
                    trackPageView("Curriculum Navigator (LTI)");
                    tracker("Accessed Curriculum Navigator (LTI)");
                    return null;
                },
            },
        ];

        if (getAppMode() === "share") {
            if (inTestMode) {
                return createMemoryRouter(shareRouterConfig, {
                    future: {
                        v7_relativeSplatPath: true,
                    },
                    initialEntries: testInitialEntries,
                });
            } else if (window.location.pathname.includes("/embed/")) {
                return createMemoryRouter(shareRouterConfig, {
                    initialEntries: [window.location.pathname],
                    future: {
                        v7_relativeSplatPath: true,
                    },
                });
            } else {
                return createBrowserRouter(shareRouterConfig, {
                    future: {
                        v7_relativeSplatPath: true,
                    },
                });
            }
        }
        if (getAppMode() === "lti") {
            return createMemoryRouter(ltiRouterConfig, {
                initialEntries: [window.location.pathname],

                future: {
                    v7_relativeSplatPath: true,
                },
            });
        }
        if (inTestMode) {
            return createMemoryRouter(appRouterConfig, {
                initialEntries: testInitialEntries,
                future: {
                    v7_relativeSplatPath: true,
                },
            });
        }
        return createBrowserRouter(appRouterConfig, {
            future: {
                v7_relativeSplatPath: true,
            },
        });
    };
    return <RouterProvider router={createRouter()} />;
};

export default AppRouter;
