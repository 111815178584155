import { useParams } from "react-router-dom";

import { useAppContext } from "contexts/AppContext";
import useCurriculumParams from "hooks/useCurriculumParams";

import QueryResults from "./QueryResults/QueryResults";
import SingleTopicCard from "./SingleTopicCard/SingleTopicCard";
import ShareLoadFailed from "components/ShareLoadFailed/ShareLoadFailed";

const CurriculumShareViewer = () => {
    const { embedDetails } = useAppContext();
    const { shareType } = useParams();
    const { queryId, topicId } = useCurriculumParams();

    if (!shareType) return <ShareLoadFailed />;
    if (queryId || embedDetails?.queryId || embedDetails?.ids)
        return <QueryResults />;
    if (topicId || embedDetails?.topicId) return <SingleTopicCard />;
    if (queryId) return <QueryResults />;
    return <></>;
};

export default CurriculumShareViewer;
