import useScreenSizes from "hooks/useScreenSizes";

import { SearchContextProvider } from "contexts/SearchContext";

import Search from "components/Search/Search";

const SearchPage = () => {
    const { isDesktopScreen, isTabletScreen } = useScreenSizes();
    return (
        <div
            style={{
                padding: isDesktopScreen
                    ? "30px 40px"
                    : isTabletScreen
                      ? "20px"
                      : "10px",
                width: "100%",
            }}
        >
            <SearchContextProvider>
                <Search />
            </SearchContextProvider>
        </div>
    );
};

export default SearchPage;
