import { useState } from "react";
import dayjs from "dayjs";
import { TableCell, TableRow, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFolder, faLayerGroup } from "@fortawesome/free-solid-svg-icons";

import * as styles from "./ListItem.module.scss";
import variables from "styles/_variables.module.scss";
import { Collection, Folder, Workspace } from "types";
import { capitaliseFirstLetter } from "utils/oldhelpers";
import { isAppMode } from "utils/newtools";

import CollectionThreeDotContextMenu from "components/ContextMenu/CollectionThreeDotContextMenu";
import FolderThreeDotContextMenu from "components/ContextMenu/FolderThreeDotContextMenu";
import Translate from "components/Translate/Translate";

interface Props {
    element: Collection | Folder | Workspace;
    folderOrWorkspaceClickHandler: (
        folderOrWorkspace: Folder | Workspace,
    ) => void;
    collectionClickHandler: (collection: Collection) => void;
    disableContextMenu?: boolean;
}

const ListItem = ({
    element,
    folderOrWorkspaceClickHandler,
    collectionClickHandler,
    disableContextMenu,
}: Props) => {
    const [isHovered, setIsHovered] = useState(false);
    const clickHandler = () => {
        if (element.type === "WORKSPACE" || element.type === "FOLDER") {
            folderOrWorkspaceClickHandler(element as Folder | Workspace);
        } else {
            collectionClickHandler(element as Collection);
        }
    };
    const mouseOverHandler = () => {
        setIsHovered(true);
    };
    const mouseLeaveHandler = () => {
        setIsHovered(false);
    };
    return (
        <TableRow
            sx={{
                backgroundColor:
                    variables["background-colors-color-background-white"],
                "&:hover": {
                    backgroundColor:
                        variables[
                            "background-colors-color-background-light-off"
                        ],
                },
                cursor: "pointer",
            }}
            onClick={clickHandler}
            onMouseOver={
                isAppMode && !disableContextMenu ? mouseOverHandler : undefined
            }
            onMouseLeave={
                isAppMode && !disableContextMenu ? mouseLeaveHandler : undefined
            }
        >
            <TableCell align="center">
                <FontAwesomeIcon
                    icon={
                        element.type === "WORKSPACE" ||
                        element.type === "FOLDER"
                            ? faFolder
                            : faLayerGroup
                    }
                    color={
                        element.type === "WORKSPACE" ||
                        element.type === "FOLDER"
                            ? element.color ||
                              variables["text-colors-color-text-light-gray"]
                            : variables["wizenoze-brand-color-wizenoze-green"]
                    }
                    fontSize={12}
                />
            </TableCell>
            <TableCell
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                }}
            >
                <Typography
                    variant="text-size-Small"
                    color="$text-colors-color-text-gray.main"
                    className={styles.title}
                >
                    {element.type === "WORKSPACE" ? (
                        <Translate text={element.title} />
                    ) : (
                        element.title
                    )}
                </Typography>
                {isAppMode &&
                    !disableContextMenu &&
                    element.type !== "WORKSPACE" && (
                        <div onClick={(e) => e.stopPropagation()}>
                            {element.type === "FOLDER" ? (
                                <FolderThreeDotContextMenu
                                    folder={element as Folder}
                                    isHovered={isHovered}
                                />
                            ) : (
                                <CollectionThreeDotContextMenu
                                    collection={element as Collection}
                                    isHovered={isHovered}
                                />
                            )}
                        </div>
                    )}
            </TableCell>
            <TableCell>
                <Typography
                    variant="text-size-Small"
                    color="$text-colors-color-text-gray.main"
                >
                    <Translate
                        text={capitaliseFirstLetter(element.type.toLowerCase())}
                    />
                </Typography>
            </TableCell>
            <TableCell>
                <Typography
                    variant="text-size-Small"
                    color="$text-colors-color-text-gray.main"
                >
                    {dayjs(element.lastModifiedDate).format("DD/MM/YYYY")}
                </Typography>
            </TableCell>
        </TableRow>
    );
};

export default ListItem;
