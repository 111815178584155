import _ from "lodash";

import { Collection, Folder, Workspace } from "types";

import FolderOrWorkspaceGridView from "components/FolderOrWorkspaceViewer/components/FolderOrWorkspaceGridView/FolderOrWorkspaceGridView";
import FolderOrWorkspaceListView from "components/FolderOrWorkspaceViewer/components/FolderOrWorkspaceListView/FolderOrWorkspaceListView";

const FolderOrWorkspaceViewer = ({
    viewAs,
    ...props
}: Omit<
    React.ComponentProps<typeof FolderOrWorkspaceListView>,
    "foldersOrWorkspaces" | "collections"
> & {
    viewAs: Folder["view"];
}) => {
    const itemsPartition = _.partition(
        props.items,
        (element) => element.type === "WORKSPACE" || element.type === "FOLDER",
    );
    const foldersOrWorkspaces = itemsPartition[0] as (Folder | Workspace)[];
    const collections = itemsPartition[1] as Collection[];
    return viewAs === "LIST" ? (
        <FolderOrWorkspaceListView
            {...props}
            foldersOrWorkspaces={foldersOrWorkspaces}
            collections={collections}
        />
    ) : (
        <FolderOrWorkspaceGridView
            {...props}
            foldersOrWorkspaces={foldersOrWorkspaces}
            collections={collections}
        />
    );
};

export default FolderOrWorkspaceViewer;
