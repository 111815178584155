import useNodeNextQuery from "hooks/api/useNodeNextQuery";
import useCurriculumParams from "hooks/useCurriculumParams";
import { Topic } from "types";

import Loading from "components/Loading/Loading";
import TopicCard from "components/TopicCard/TopicCard";

const SingleTopicCard = () => {
    const { topicId } = useCurriculumParams();
    const { data: topicData, status: topicStatus } =
        useNodeNextQuery<Topic>(topicId);
    if (topicStatus !== "success") return <Loading />;
    return (
        <div
            style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                paddingTop: 30,
                paddingBottom: 30,
            }}
        >
            <TopicCard
                cardHeading={topicData.name}
                cardImage={topicData.logoUrl}
                listItems={topicData.queries}
                topicData={topicData}
            />
        </div>
    );
};

export default SingleTopicCard;
