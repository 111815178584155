import * as styles from "./ReadingLevelFilter.module.scss";
import { ReadingLevel } from "types";

import ReadingLevelButton from "./components/ReadingLevelButton/ReadingLevelButton";

export const allReadingLevels: ReadingLevel[] = [1, 2, 3, 4, 5];

export const allReadingLevelsSelected = (readingLevels: ReadingLevel[]) => {
    return allReadingLevels.every((level) => readingLevels.includes(level));
};
interface Props {
    readingLevels: Set<ReadingLevel>;
    updateReadingLevels: (level: ReadingLevel) => void;
}

const ReadingLevelFilter = ({ readingLevels, updateReadingLevels }: Props) => {
    return (
        <div className={styles.container}>
            {allReadingLevels.map((level) => (
                <ReadingLevelButton
                    key={level}
                    level={level}
                    isSelected={readingLevels.has(level)}
                    updateReadingLevel={updateReadingLevels}
                    allReadingLevelsSelected={
                        readingLevels.size === allReadingLevels.length
                    }
                />
            ))}
        </div>
    );
};

export default ReadingLevelFilter;
