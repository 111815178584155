import { useParams } from "react-router-dom";
import Grid from "@mui/material/Unstable_Grid2";

import { OldResult } from "types";

import NoResults from "./NoResults/NoResults";
import ResultCard from "./ResultCard/ResultCard";
import PoweredByWizenoze from "components/PoweredBy/PoweredByWizenoze";

interface Props {
    queryResult: Array<OldResult>;
}

const ResultsGrid = ({ queryResult: queryResults, ...props }: Props) => {
    const { shareType } = useParams();

    if (queryResults.length === 0) {
        return <NoResults />;
    }

    return (
        <>
            <Grid container spacing={2}>
                {queryResults.map((result, index) => (
                    <Grid xl={4} md={6} xs={12} key={result.id}>
                        <ResultCard
                            results={queryResults}
                            resultIndex={index}
                            {...props}
                        />
                    </Grid>
                ))}
            </Grid>
            {shareType === "embed" && (
                <div
                    style={{
                        display: "grid",
                        gridTemplateColumns: "1fr",
                        gridTemplateRows: "auto auto",
                    }}
                >
                    <PoweredByWizenoze />
                </div>
            )}
        </>
    );
};
export default ResultsGrid;
