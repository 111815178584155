import { AxiosError } from "axios";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { axiosAPI } from "./axiosAPI";

export const useFeedBackOptionsQuery = () => {
    return useQuery<FeedBackOptions, AxiosError>(
        ["feedbackOptions"],
        async () => {
            const { data } = await axiosAPI
                .getInstance()
                .get(`/feedback/options`, {});
            return data;
        },
    );
};

export const useExistingFeedbackQuery = (
    queryId: string | undefined,
    resultId: string,
    feedbackProvided: boolean,
) => {
    return useQuery<ExistingFeedback, AxiosError>(
        ["existingFeedback", queryId, resultId],
        async () => {
            const { data } = await axiosAPI
                .getInstance()
                .get(
                    `/feedback/node/query/${queryId}/results/${resultId}/feedback`,
                    {},
                );
            return data;
        },
        {
            enabled: !!queryId && !!resultId && !!feedbackProvided,
            retry: false,
        },
    );
};

export const useUpdateFeedBackMutation = (
    queryId: string | undefined,
    resultId: string,
    existingFeedbackId: string | undefined,
) => {
    const queryClient = useQueryClient();
    return useMutation(
        async (feedback: FeedBack) => {
            if (!queryId)
                return new Error("Can't update feedback without QueryID");
            // put vs post
            if (existingFeedbackId) {
                return axiosAPI
                    .getInstance()
                    .put(
                        `/feedback/node/query/${queryId}/results/${resultId}/feedback/${existingFeedbackId}`,
                        feedback,
                    );
            } else {
                return axiosAPI
                    .getInstance()
                    .post(
                        `/feedback/node/query/${queryId}/results/${resultId}/feedback`,
                        feedback,
                    );
            }
        },
        {
            onSettled: () => {
                queryClient.invalidateQueries({
                    queryKey: ["query-results", queryId],
                });
                queryClient.invalidateQueries({
                    queryKey: ["existingFeedback", queryId, resultId],
                });
            },
        },
    );
};

export type ExistingFeedback = {
    id: number;
    timestamp: string;
    userId: number;
    queryId: number;
    resultId: string;
    action: number;
    reasons: Array<Reason>;
    comment: string;
};

type Reason = { id: number; name: string };

export type FeedBack = {
    action: number;
    comment: string;
    reasons: Array<Reason>;
};

export type FeedBackOptions = Array<{
    id: 100 | 101 | 102 | number;
    name: "Approve" | "Remove" | "Other" | string;
    reasonRequired: boolean;
    commentsRequired: boolean;
    reasons: Array<Reason>;
}>;
