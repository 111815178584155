import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import {
    faChevronDown,
    faChevronRight,
    faCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Theme, Typography, useMediaQuery } from "@mui/material";
import { TreeItem, TreeView } from "@mui/x-tree-view";

import * as styles from "./TreeExplorer.module.scss";
import useScreenSizes from "hooks/useScreenSizes";
import variables from "styles/_variables.module.scss";
import { Collection, Folder, Workspace } from "types";

import Translate from "components/Translate/Translate";

export type CollectionWithPathStep = Collection & { pathStep: string };
export type FolderWithPathStep = Folder & { pathStep: string };
export type WorkspaceWithPathStep = Workspace & { pathStep: string };

const sortFolders = (
    folders: Array<Collection | Folder | Workspace>,
    firstRecursion = true,
): Array<Collection | Folder | Workspace> => {
    return folders
        .sort((a, b) => {
            if (firstRecursion) return 0;
            if (a.title.toLowerCase() < b.title.toLowerCase()) {
                return -1;
            } else if (a.title.toLowerCase() > b.title.toLowerCase()) {
                return 1;
            } else {
                return a.id - b.id;
            }
        })
        .map((element) => {
            // Only sort children on the first recursion
            if (element.type === "WORKSPACE" || element.type === "FOLDER") {
                element.children = sortFoldersChildren(element.children, false);
            }
            return element;
        });
};

const sortFoldersChildren = (
    folders: Array<Collection | Folder>,
    firstRecursion = true,
): Array<Collection | Folder> => {
    return folders
        .sort((a, b) => {
            if (firstRecursion) return 0;
            if (a.title.toLowerCase() < b.title.toLowerCase()) {
                return -1;
            } else if (a.title.toLowerCase() > b.title.toLowerCase()) {
                return 1;
            } else {
                return a.id - b.id;
            }
        })
        .map((element) => {
            // Only sort children on the first recursion
            if (element.type === "FOLDER") {
                element.children = sortFoldersChildren(element.children, false);
            }
            return element;
        });
};

const TreeNode = ({
    data,
    path,
    folderOrWorkspaceClickHandler,
    collectionClickHandler,
    invalidationIds,
    withPermissions,
}: {
    data: Collection | Folder | Workspace;
    path: string;
    folderOrWorkspaceClickHandler: (
        folderOrWorkspace: Folder | Workspace,
        pathString?: string,
        invalidationIds?: [number] | [number, number],
    ) => void;
    collectionClickHandler?: (
        collection: Collection,
        pathString?: string,
        invalidationIds?: [number] | [number, number],
    ) => void;
    invalidationIds?: [number] | [number, number];
    withPermissions?: boolean;
}) => {
    const { isTabletScreen, isMobileScreen } = useScreenSizes();
    const navigate = useNavigate();
    return (
        <TreeItem
            nodeId={data.id.toString()}
            label={
                <Typography
                    variant="text-size-Small"
                    color={
                        withPermissions && !data.permissions.includes("CREATE")
                            ? "$text-colors-color-text-gray.main"
                            : "$text-colors-color-text-dark.main"
                    }
                    className={styles.treetitle}
                >
                    {data.type === "WORKSPACE" ? (
                        <Translate text={data.title} />
                    ) : (
                        data.title
                    )}
                </Typography>
            }
            style={{
                paddingTop: isMobileScreen
                    ? "15px"
                    : isTabletScreen
                      ? "10px"
                      : "0px",
            }}
            onClick={() => {
                switch (data.type) {
                    case "WORKSPACE":
                        folderOrWorkspaceClickHandler(
                            data,
                            path,
                            invalidationIds,
                        );
                        break;
                    case "FOLDER":
                        folderOrWorkspaceClickHandler(
                            data,
                            path,
                            invalidationIds,
                        );
                        break;
                    case "COLLECTION":
                        collectionClickHandler &&
                            invalidationIds &&
                            collectionClickHandler(data, path, invalidationIds);
                        break;
                    default:
                        navigate(path);
                }
            }}
            endIcon={
                data.type === "WORKSPACE" || data.type === "FOLDER" ? (
                    <FontAwesomeIcon
                        icon={faChevronRight}
                        color={variables["text-colors-color-text-gray"]}
                        style={{ fontSize: "12px" }}
                    />
                ) : (
                    <FontAwesomeIcon
                        icon={faCircle}
                        color={variables["text-colors-color-text-gray"]}
                        style={{ fontSize: "4px", marginTop: "4px" }}
                    />
                )
            }
        >
            {(data.type === "WORKSPACE" || data.type === "FOLDER") &&
                data.children?.map((el) => (
                    <TreeNode
                        key={el.id}
                        data={el}
                        path={[path, el.pathStep ?? el.id].join("/")}
                        folderOrWorkspaceClickHandler={
                            folderOrWorkspaceClickHandler
                        }
                        collectionClickHandler={collectionClickHandler}
                        invalidationIds={
                            invalidationIds && invalidationIds.length === 1
                                ? [...invalidationIds, el.id]
                                : invalidationIds
                        }
                        withPermissions={withPermissions}
                    />
                ))}
        </TreeItem>
    );
};

const TreeExplorer = ({
    data,
    selectedNodes,
    pathRoot: navigationRoot,
    folderOrWorkspaceClickHandler,
    collectionClickHandler,
    onNodeClick,
    withPermissions,
}: {
    data: Array<
        | Collection
        | CollectionWithPathStep
        | Folder
        | FolderWithPathStep
        | Workspace
        | WorkspaceWithPathStep
    >;
    selectedNodes: number[] | string[];
    pathRoot: string;
    folderOrWorkspaceClickHandler: React.ComponentProps<
        typeof TreeNode
    >["folderOrWorkspaceClickHandler"];
    collectionClickHandler?: React.ComponentProps<
        typeof TreeNode
    >["collectionClickHandler"];
    onNodeClick?: React.ComponentProps<typeof TreeView>["onNodeSelect"];
    withPermissions?: boolean;
}) => {
    const sortedData = useMemo(() => sortFolders(data, true), [data]);
    const isTabletScreen = useMediaQuery((theme: Theme) =>
        theme.breakpoints.between("md", "lg"),
    );
    const isMobileScreen = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down("md"),
    );
    return (
        <div
            style={{
                marginTop: isMobileScreen
                    ? "-20px"
                    : isTabletScreen
                      ? "-15px"
                      : "-5px",
            }}
        >
            {sortedData?.map((tree) => (
                <TreeView
                    key={"tree" + tree.id}
                    aria-label="curriculum tree explorer"
                    style={{
                        marginTop: isMobileScreen
                            ? "20px"
                            : isTabletScreen
                              ? "15px"
                              : "5px",
                    }}
                    defaultCollapseIcon={
                        <FontAwesomeIcon
                            icon={faChevronDown}
                            color={variables["text-colors-color-text-gray"]}
                            style={{ fontSize: "12px" }}
                        />
                    }
                    defaultExpandIcon={
                        <FontAwesomeIcon
                            icon={faChevronRight}
                            color={variables["text-colors-color-text-gray"]}
                            style={{ fontSize: "12px" }}
                        />
                    }
                    defaultEndIcon={
                        <FontAwesomeIcon
                            icon={faCircle}
                            color={variables["text-colors-color-text-gray"]}
                            style={{ fontSize: "4px", marginTop: "4px" }}
                        />
                    }
                    sx={{
                        flexGrow: 1,
                        maxWidth: 400,
                        overflowY: "auto",
                        ".MuiTreeItem-iconContainer": {
                            alignSelf: "flex-start",
                            marginTop: "7px",
                        },
                    }}
                    expanded={selectedNodes.map((value) => value.toString())}
                    onNodeSelect={onNodeClick}
                >
                    <TreeNode
                        key={"tree" + tree.id}
                        data={tree}
                        path={[navigationRoot, tree.pathStep ?? tree.id].join(
                            "/",
                        )}
                        folderOrWorkspaceClickHandler={
                            folderOrWorkspaceClickHandler
                        }
                        collectionClickHandler={collectionClickHandler}
                        invalidationIds={[tree.id]}
                        withPermissions={withPermissions}
                    />
                </TreeView>
            ))}
        </div>
    );
};

export default TreeExplorer;
