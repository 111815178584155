import { CSSProperties } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import Grid from "@mui/material/Unstable_Grid2";

import useScreenSizes from "hooks/useScreenSizes";

import SearchFilters from "./components/SearchFilters/SearchFilters";
import SearchInput from "./components/SearchInput/SearchInput";
import SearchResults from "./components/SearchResults/SearchResults";

interface Props {
    styleOverrides?: CSSProperties;
}

const Search = ({ styleOverrides }: Props) => {
    const { isDesktopScreen } = useScreenSizes();
    const location = useLocation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const setSearchTerm = (searchTerm: string) => {
        if (!searchTerm) return;
        searchParams.set("searchTerm", searchTerm);
        navigate(
            {
                pathname: location.pathname,
                search: searchParams.toString(),
            },
            { replace: true, state: location.state },
        );
    };
    return (
        <Grid container spacing={"20px"}>
            <>
                {isDesktopScreen && (
                    <Grid lg={3} style={styleOverrides}>
                        <SearchFilters />
                    </Grid>
                )}
                <Grid lg={6} xs={12}>
                    {/* This div with height 40 exists to prevent the autocomplete menu displace the filters and results */}
                    <div style={{ marginBottom: "20px", height: "40px" }}>
                        <SearchInput setSearchTerm={setSearchTerm} />
                    </div>
                    <SearchResults />
                </Grid>
            </>
        </Grid>
    );
};

export default Search;
