@use "../../../../../../styles/variables.module";

.badgeIconContainer {
    position: absolute;
    top: 0;
    right: 0;
}

.badgeIconContainer img {
    width: 128px;
    padding: 0px;
    margin: 0px;
}

.premiumIconFooterContainer {
    margin-right: 8px;
    height: calc(32px - 4px);
    display: flex;
    place-items: center;
}

.premiumIconFooterContainer img {
    height: 100%;
    padding: 0px;
    margin: 0px;
}
