import { useState } from "react";
import {
    createSearchParams,
    useNavigate,
    useSearchParams,
} from "react-router-dom";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";

import {
    baseContainer,
    openContainer,
    openList,
} from "./SearchInput.module.scss";
import variables from "styles/_variables.module.scss";
import { tracker } from "utils/analytics";

import SearchInput from "./SearchInput";
import MenuButton from "components/MenuButton/MenuButton";

const HeaderSearchInput = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [isOpen, setIsOpen] = useState(false);

    const closeHeaderSearch = () => {
        tracker("Search bar closed");
        setIsOpen(false);
    };

    const navigateToSearchWithTerm = (term: string) => {
        if (term) {
            tracker("Performed Search", { searchTerm: term });
            const allParams = Object.fromEntries([...searchParams]);
            if (searchParams.get("queryResult")) delete allParams.queryResult;

            navigate(
                {
                    pathname: "/search",
                    search: createSearchParams({
                        ...allParams,
                        searchTerm: term,
                        subjectFilters: [],
                    }).toString(),
                },
                {
                    state: { searchTerm: term },
                },
            );
            setIsOpen(false);
        }
    };
    const toggleSearchInput = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div data-testid="baseContainer" className={baseContainer}>
            <MenuButton
                dataTestId={"searchMenuButton"}
                faIcon={faMagnifyingGlass}
                iconColor={variables["text-colors-color-text-gray"]}
                primaryColor={
                    variables["background-colors-color-background-white"]
                }
                secondaryColor={variables["border-colors-color-border-primary"]}
                isOpen={isOpen}
                onClick={toggleSearchInput}
            />
            {isOpen && (
                <div className={openContainer} onClick={closeHeaderSearch}>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <div
                            className={openList}
                            onClick={(e) => e.stopPropagation()}
                        >
                            <SearchInput
                                setSearchTerm={navigateToSearchWithTerm}
                                closeSearch={closeHeaderSearch}
                            />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default HeaderSearchInput;
