import { useState } from "react";
import dayjs from "dayjs";
import { faFolder } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Typography } from "@mui/material";

import * as styles from "./FolderOrWorkspaceCard.module.scss";
import variables from "styles/_variables.module.scss";
import { Folder, Workspace } from "types";
import { isAppMode } from "utils/newtools";

import FolderThreeDotContextMenu from "components/ContextMenu/FolderThreeDotContextMenu";
import Translate, { TranslatableText } from "components/Translate/Translate";

interface Props {
    folderOrWorkspace: Folder | Workspace;
    clickHandler: (folderOrWorkspace: Folder | Workspace) => void;
    disableContextMenu?: boolean;
}

const FolderOrWorkspaceCard = ({
    folderOrWorkspace,
    clickHandler,
    disableContextMenu,
}: Props) => {
    const [hasImageFetchFailed, setHasImageFetchFailed] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    const mouseOverHandler = () => {
        setIsHovered(true);
    };
    const mouseLeaveHandler = () => {
        setIsHovered(false);
    };
    return (
        <div
            className={styles.container}
            onClick={() => clickHandler(folderOrWorkspace)}
            onMouseOver={
                isAppMode &&
                !disableContextMenu &&
                folderOrWorkspace.type !== "WORKSPACE"
                    ? mouseOverHandler
                    : undefined
            }
            onMouseLeave={
                isAppMode &&
                !disableContextMenu &&
                folderOrWorkspace.type !== "WORKSPACE"
                    ? mouseLeaveHandler
                    : undefined
            }
        >
            {folderOrWorkspace.type !== "WORKSPACE" && (
                <div className={styles.header}>
                    <div className={styles.date}>
                        <Typography
                            variant="text-size-xSmall-regular"
                            color="$text-colors-color-text-light-gray.main"
                        >
                            <Translate text="Date modified" />
                            {": "}
                            {dayjs(folderOrWorkspace.lastModifiedDate).format(
                                "DD/MM/YY",
                            )}
                        </Typography>
                    </div>
                    {isAppMode && !disableContextMenu && (
                        <div
                            style={{
                                position: "absolute",
                                right: 10,
                                top: 5,
                            }}
                        >
                            <FolderThreeDotContextMenu
                                folder={folderOrWorkspace as Folder}
                                isHovered={isHovered}
                            />
                        </div>
                    )}
                </div>
            )}
            <div className={styles.image}>
                {!hasImageFetchFailed && folderOrWorkspace.imageUrl ? (
                    <img
                        src={folderOrWorkspace.imageUrl}
                        alt={folderOrWorkspace.title}
                        aria-hidden="true"
                        style={{
                            objectFit: "contain",
                            width: "100%",
                            height: "100%",
                        }}
                        onError={() => setHasImageFetchFailed(true)}
                    />
                ) : (
                    <FontAwesomeIcon
                        icon={faFolder}
                        color={
                            folderOrWorkspace.color ||
                            variables["text-colors-color-text-light-gray"]
                        }
                        fontSize={32}
                    />
                )}
            </div>
            <Typography
                variant="text-size-Base-bold"
                color="$text-colors-color-text-dark.main"
                className={styles.title}
            >
                {folderOrWorkspace.type === "WORKSPACE" ? (
                    <Translate
                        text={folderOrWorkspace.title as TranslatableText}
                    />
                ) : (
                    folderOrWorkspace.title
                )}
            </Typography>
        </div>
    );
};

export default FolderOrWorkspaceCard;
