import * as styles from "./CollectionListView.module.scss";
import { CollectionItem, CollectionResourceResult } from "types";

import ResultCard from "components/ResultCard/ResultCard";

interface Props {
    results: CollectionResourceResult[];
    selectedItems?: CollectionItem[];
    addItemHandler?: (resultId: string) => void;
    removeItemHandler?: (resultId: string) => void;
}
const CollectionListView = ({
    results,
    selectedItems,
    addItemHandler,
    removeItemHandler,
}: Props) => {
    return (
        <div className={styles.listContainer}>
            {results.map((result, index) => (
                <ResultCard
                    key={result.clickUrl}
                    result={result}
                    results={results}
                    resultIndex={index}
                    isSelectedResult={selectedItems?.some(
                        (el) => el.indexId === result.id,
                    )}
                    addResourceHandler={
                        addItemHandler
                            ? () => addItemHandler(result.id)
                            : undefined
                    }
                    removeResourceHandler={
                        removeItemHandler
                            ? () => removeItemHandler(result.id)
                            : undefined
                    }
                />
            ))}
        </div>
    );
};

export default CollectionListView;
