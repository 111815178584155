import { useState } from "react";

import useUserQuery from "hooks/api/useUserQuery";
import { QueryResourceResult } from "types";

import FeedBackButton from "./FeedBackButton/FeedBackButton";
import FeedBackForm from "./FeedBackForm/FeedbackForm";

interface Props {
    result: QueryResourceResult;
}

const Feedback = ({ result }: Props) => {
    const { data: user } = useUserQuery();
    const userIsFeedbacker = user?.me.roles.includes("VERIFIER");
    const [isFormShowing, setIsFormShowing] = useState(false);

    if (!userIsFeedbacker) return <></>;

    return isFormShowing ? (
        <FeedBackForm result={result} setIsFormShowing={setIsFormShowing} />
    ) : (
        <FeedBackButton
            feedbackProvided={result.feedbackProvided}
            setIsFormShowing={setIsFormShowing}
        />
    );
};

export default Feedback;
