import { useState } from "react";
import { Tooltip } from "@mui/material";

import { container, tooltipOn } from "./InputBase.module.scss";
import variables from "styles/_variables.module.scss";
import { tracker } from "utils/analytics";

import TooltipContent from "components/TooltipContent/TooltipContent";
import Translate, { TranslatableText } from "components/Translate/Translate";

interface Props {
    children: React.ReactElement | React.ReactElement[];
    label: TranslatableText | string;
    keyname: string;
    disabled?: boolean;
    isHidden?: boolean;
    tooltip?: {
        text: TranslatableText;
        url: URL;
        placement: React.ComponentProps<typeof Tooltip>["placement"];
    };
}

const InputBase = ({
    children,
    label,
    keyname,
    disabled,
    isHidden,
    tooltip,
}: Props) => {
    const [isTooltipOpen, setIsTooltipOpen] = useState(false);
    const placement = tooltip?.placement || "bottom";
    if (isHidden) return null;
    const openTooltip = () => {
        setIsTooltipOpen(true);
        tracker("Open tooltip", { keyname });
    };
    const closeTooltip = () => {
        setIsTooltipOpen(false);
        tracker("Close tooltip", { keyname });
    };
    return (
        <div className={container}>
            <Tooltip
                title={
                    tooltip && (
                        <TooltipContent
                            tooltip={tooltip}
                            closeHandler={closeTooltip}
                        />
                    )
                }
                placement={placement}
                arrow
                open={tooltip && isTooltipOpen}
                onClick={(e) => e.preventDefault()}
            >
                <span
                    className={tooltip ? tooltipOn : ""}
                    style={{
                        color: disabled
                            ? variables["text-colors-color-text-light-gray"]
                            : variables["text-colors-color-text-dark"],
                    }}
                    onClick={tooltip && openTooltip}
                >
                    <Translate text={label} />
                </span>
            </Tooltip>
            {children}
        </div>
    );
};

export default InputBase;
