import { Button, Typography } from "@mui/material";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import variables from "styles/_variables.module.scss";
import { ReadingLevel } from "types";

interface Props {
    level: ReadingLevel;
    isSelected: boolean;
    updateReadingLevel: (level: ReadingLevel) => void;
    allReadingLevelsSelected: boolean;
}

const ReadingLevelButton = ({
    level,
    isSelected,
    updateReadingLevel,
    allReadingLevelsSelected,
}: Props) => {
    return (
        <Button
            variant="contained"
            color={
                isSelected
                    ? `$reading-levels-color-level-${level}`
                    : "$reading-levels-color-level-unknown"
            }
            style={{
                width: "25px",
                height: "25px",
                borderRadius: "25px",
            }}
            onClick={() => updateReadingLevel(level)}
        >
            {!allReadingLevelsSelected && isSelected ? (
                <FontAwesomeIcon icon={faCheck} />
            ) : (
                <Typography
                    variant="text-size-xSmall-bold"
                    style={{
                        color: variables["text-colors-color-text-white"],
                    }}
                >
                    {level}
                </Typography>
            )}
        </Button>
    );
};

export default ReadingLevelButton;
